import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import '../../Welcome.css';
import './DatosAval.css';
import 'react-datepicker/dist/react-datepicker.css';

import {
  Modal,
  Typography,
  Button,
  Select,
  Input,
  Form,
  Row,
  Col,
  Divider,
  Skeleton,
  InputNumber,
  Slider,
  Radio,
  Tooltip,
  Segmented,
  ConfigProvider,
} from 'antd';
import {
  PlusOutlined,
  UserOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  MessageOutlined,
  MailOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import {
  MenuItem,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  TextField,
  Autocomplete,
} from '@mui/material';

import imagen1 from '../../../asset/images/INEVERTICAL.png';

import { FaCircleQuestion, FaBuilding, FaUserTie } from 'react-icons/fa6';

import Paper from '@mui/material/Paper';

import DatePickerCustom from '../../../componentes/DatePickerCustom';
import DatePicker from 'react-datepicker';
import es from 'date-fns/locale/es';

import RfcTextField from '../../../componentes/TextFieldRfc';
import TextFieldCustom from '../../../componentes/TextFieldCustom';

import InputCustom from '../../../componentes/InputCustom';
import SearchCustom from '../../../componentes/SearchCustom';
import RfcInput from '../../../componentes/InputRfc';
import { ComponentesContext } from '../../../contextos/componentesContext';

import FormulariosService from '../../../services/formularios.Service';
import SepomexService from '../../../services/sepomex.Service';
import AuthService from '../../../services/auth.service';

const DatosAval = () => {
  const {
    UpdateFooterText,
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    loading,
    UpdatecurrentStep,
    InputTypes,
    UpdateFlagtimeLineForm,
    UpdatemovilSteps,
    ShowLoading,
    ModalMessage,
    CloseSession,
    RfcTypes,
    UpdateFlagtimeLineFormMovil,
    UpdateFlagtimeLineFormProductos,
    UpdateFlagtimeLineFormAval
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();

  const [solicitudId, SetsolicitudId] = useState(
    typeof location.state.record.solicitudId != 'undefined'
      ? location.state.record.solicitudId != null
        ? location.state.record.solicitudId
        : '0'
      : '0'
  );

  const [formFechaNacimientoTimeZone, setformFechaNacimientoTimeZone] =
    useState(null);

  const [estadoAval, setestadoAval] = useState('');

  const [formSexo, setformSexo] = useState('');

  const [OptionsSexo, setOptionsSexo] = useState([
    {
      value: 1,
      label: <div className="lblOptionCombo">Femenino </div>,
    },
    {
      value: 2,
      label: <div className="lblOptionCombo">Masculino </div>,
    },
    {
      value: 3,
      label: <div className="lblOptionCombo">Indefinido </div>,
    },
  ]);

  const [formEstadoCivil, setformEstadoCivil] = useState('');

  const [OptionsEstadosCivil, setOptionsEstadosCivil] = useState([
    {
      value: 1,
      label: <div className="lblOptionCombo">Soltero </div>,
    },
    {
      value: 2,
      label: <div className="lblOptionCombo">Casado </div>,
    },
    {
      value: 3,
      label: <div className="lblOptionCombo">Sin especificar </div>,
    },
  ]);

  const [formFolioIne, setformFolioIne] = useState('');
  const handleInfo = (e) => {};

  const [formRepresentantePrimerNombre, setformRepresentantePrimerNombre] =
    useState('');
  const [formRepresentanteSegundoNombre, setformRepresentanteSegundoNombre] =
    useState('');
  const [formRepresentantePrimerApellido, setformRepresentantePrimerApellido] =
    useState('');
  const [
    formRepresentanteSegundoApellido,
    setformRepresentanteSegundoApellido,
  ] = useState('');

  const [formEstadoUbicacion, setformEstadoUbicacion] = useState('');

  const [DisableForm, setDisableForm] = useState(false);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 190,
    heightImage: window.innerHeight - 185,
    heightScroll: (window.innerHeight * 54) / 100,
  });

  const [isModalOpen, setisModalOpen] = useState(false);
  const [ModalContent, setModalContent] = useState(null);

  const [PfTooltip, setPfTooltip] = useState('');
  const [PmTooltip, setPmTooltip] = useState('');

  const [PfDisabled, setPfDisabled] = useState(false);
  const [PmDisabled, setPmDisabled] = useState(false);

  const [PfaeisVisible, setPfaeisVisible] = useState(false);
  const [PmisVisible, setPmisVisible] = useState(true);

  const [NombreCompletoAval, setNombreCompletoAval] = useState('');
  const [listaPaises, setlistaPaises] = useState('');

  const [OptionsNacionalidad, setOptionsNacionalidad] = useState([
    {
      value: 1,
      label: <div className="lblOptionCombo">Mexicana </div>,
    },
  ]);

  const [OptionsEstados, setOptionsEstados] = useState([
    {
      value: 'Sinaloa',
      label: <div className="lblOptionCombo">Sinaloa </div>,
    },
    {
      value: 'Sonora',
      label: <div className="lblOptionCombo">Sonora </div>,
    },
  ]);

  const [OptionsEstadosNacimiento, setOptionsEstadosNacimiento] = useState([
    {
      value: 'Sinaloa',
      label: <div className="lblOptionCombo">Sinaloa </div>,
    },
    {
      value: 'Sonora',
      label: <div className="lblOptionCombo">Sonora </div>,
    },
  ]);

  const [OptionsOcupacion, setOptionsOcupacion] = useState([
    {
      value: 'Sinaloa',
      label: <div className="lblOptionCombo">Sinaloa </div>,
    },
    {
      value: 'Sonora',
      label: <div className="lblOptionCombo">Sonora </div>,
    },
  ]);

  const [OptionsMunicipio, setOptionsMunicipio] = useState([
    {
      value: 'culiacan',
      label: <div className="lblOptionCombo">Culiacan </div>,
    },
    {
      value: 'navolato',
      label: <div className="lblOptionCombo">Navolato </div>,
    },
  ]);

  const [OptionsColonia, setOptionsColonia] = useState([
    {
      value: 'centro',
      label: <div className="lblOptionCombo">Centro </div>,
    },
    {
      value: 'miguel aleman',
      label: <div className="lblOptionCombo">Miguel Aleman </div>,
    },
  ]);

  const [formclienteTipoRegimenFiscalId, setformclienteTipoRegimenFiscalId] =
    useState(1);

  const [formPaisNacimiento, setformPaisNacimiento] = useState('');
  const [formEstadoNacimiento, setformEstadoNacimiento] = useState('');

  const [formOcupacion, setformOcupacion] = useState('');

  const [valueRFC, setvalueRFC] = useState('');
  const [formRazonSocial, setformRazonSocial] = useState('');
  const [formNacionalidad, setformNacionalidad] = useState('');
  const [formCorreo, setformCorreo] = useState('');
  const [formTelefonoOficina, setformTelefonoOficina] = useState('');
  const [formExtOficina, setformExtOficina] = useState('');
  const [formCelular, setformCelular] = useState('');

  const [formPrimerNombre, setformPrimerNombre] = useState('');
  const [formSegundoNombre, setformSegundoNombre] = useState('');
  const [formPrimerApellido, setformPrimerApellido] = useState('');
  const [formSegundoApellido, setformSegundoApellido] = useState('');

  const [formCp, setformCp] = useState('');
  const [formEstado, setformEstado] = useState('');
  const [formMunicipio, setformMunicipio] = useState('');
  const [formColonia, setformColonia] = useState('');
  const [formCalle, setformCalle] = useState('');
  const [formExt, setformExt] = useState('');
  const [formInt, setformInt] = useState('');
  const [formCurp, setformCurp] = useState('');

  const [disableSelectColonia, setenableSelectColonia] = useState(true);

  const [formRegimenFiscal, setformRegimenFiscal] = useState(3);

  const [avalCapturado, setavalCapturado] = useState(false);

  useEffect(() => {
    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      //console.log('entro');
      AuthService.logout();

      navigate('/login', {
        state: { record: { correo: '' } },
      });
    } else {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 200,
          heightImage: window.innerHeight - 185,
          heightScroll: (window.innerHeight * 54) / 100,
        });
      };

      UpdateFlagtimeLineFormMovil(true);
      UpdateFlagtimeLineForm(true);
      UpdateFlagtimeLineFormProductos(true);
      UpdateFlagtimeLineFormAval(false);
      UpdatebuttonIngresar(
        'dashboard',
        usr.primerNombre + ' ' + usr.primerApellido
      );
      UpdateuserAvatar('dashboard');
      UpdatecurrentStep(5);
      UpdatemovilSteps(5);
      UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');

      setTimeout(function () {
        form.setFieldsValue({
          inversionista: {
            codRegFiscal: 1,
          },
        });
      }, 0);

      InicializaFormulario();

      window.addEventListener('resize', handleResize);
      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener('resize', handleResize);
      };

      
    }
  }, []);

  const InicializaFormulario = async () => {
    ShowLoading(true);

    var respuesta = {};
    var respuestaEstados = {};
    if (solicitudId == 0) {
      ShowLoading(false);
      ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      return false;
    } else {
      respuesta = await FormulariosService.getDatosAval(solicitudId);
      respuestaEstados = await SepomexService.getEstadosSepomex();
    }


    //console.log(respuesta);

    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      }
      return false;
    }

    if (respuesta.data.length == 0) {
      ShowLoading(false);
      return false;
    }

    setestadoAval(respuesta.data.estado);
    setDisableForm(!respuesta.data.habilitado);

    //console.log('estado', estadoAval);

    const optionocupacionList = respuesta.data.ocupacionList.map((item) => ({
      value: item.id,
      label: (
        <Tooltip
          overlayInnerStyle={{ marginLeft: -100, width: 500, marginTop: -50 }}
          placement="up"
          title={item.nombre}
        >
          <div className="lblOptionCombOcupacion">{item.nombre}</div>
        </Tooltip>
      ),
      nombre: item.nombre,
    }));
    setOptionsOcupacion(optionocupacionList);

    const optionnacionalidadList = respuesta.data.nacionalidadList.map(
      (item) => ({
        value: item.id,
        label: <div className="lblOptionCombo">{item.nombre}</div>,
        nombre: item.nombre,
      })
    );
    setOptionsNacionalidad(optionnacionalidadList);
    setlistaPaises(optionnacionalidadList);

    const optionestadoNacimientoList = respuestaEstados.data.map((item) => ({
      value: item.id, // Convierte el ID a una cadena
      label: <div className="lblOptionCombo">{item.nombre}</div>,
      nombre: item.nombre,
    }));
    setOptionsEstadosNacimiento(optionestadoNacimientoList);

    if (
      respuesta.data.avalPaisNacimientoId == 0 ||
      respuesta.data.avalPaisNacimientoId == null ||
      respuesta.data.avalPaisNacimientoId == 142
    ) {
      setOptionsEstadosNacimiento(optionestadoNacimientoList);
    } else {
      const filteredData = optionnacionalidadList.filter(
        (item) => item.value === respuesta.data.avalPaisNacimientoId
      );

      setOptionsEstadosNacimiento([
        {
          value: 999, // Convierte el ID a una cadena
          label: <div className="lblOptionCombo">{filteredData[0].nombre}</div>,
          nombre: filteredData[0].nombre,
        },
      ]);
    }

    setformEstadoNacimiento(null);

    if (respuesta.data.avalEstado != null) {
      const optionEstadosSepomex = [
        {
          value: parseInt(respuesta.data.avalEstado.id),
          label: (
            <div className="lblOptionCombo">
              {respuesta.data.avalEstado.nombre}
            </div>
          ),
        },
      ];
      setOptionsEstados(optionEstadosSepomex);
      setformEstado(parseInt(respuesta.data.avalEstado.id));

      setTimeout(function () {
        form.setFieldsValue({
          datosAval: {
            Estado: parseInt(respuesta.data.avalEstado.id),
          },
        });
      }, 0);
    }

    if (respuesta.data.avalMunicipio != null) {
      const optionMunicipiosSepomex = [
        {
          value: parseInt(respuesta.data.avalMunicipio.id),
          label: (
            <div className="lblOptionCombo">
              {respuesta.data.avalMunicipio.nombre}
            </div>
          ),
        },
      ];
      setOptionsMunicipio(optionMunicipiosSepomex);
      setformMunicipio(parseInt(respuesta.data.avalMunicipio.id));

      setTimeout(function () {
        form.setFieldsValue({
          datosAval: {
            Municipio: parseInt(respuesta.data.avalMunicipio.id),
          },
        });
      }, 0);
    }

    if (respuesta.data.avalEstado != null) {
      const optionColoniasSepomex = respuesta.data.coloniaList.map((item) => ({
        value: parseInt(item.id), // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.nombre}</div>,
        nombre: item.nombre,
      }));

      setOptionsColonia(optionColoniasSepomex);
      setenableSelectColonia(false);

      setTimeout(function () {
        form.setFieldsValue({
          datosAval: {
            Colonia: parseInt(respuesta.data.avalColoniaId),
          },
        });
      }, 0);

      setformColonia(parseInt(respuesta.data.avalColoniaId));
    }

    setvalueRFC(respuesta.data.avalRfc);
    setformRazonSocial(respuesta.data.avalRazonSocial);
    setformCorreo(respuesta.data.avalEmail);
    setformTelefonoOficina(respuesta.data.avalTelefonoOficina);
    setformExtOficina(respuesta.data.avalExtensionOficina);
    setformCelular(respuesta.data.avalCelular);

    setformPrimerNombre(respuesta.data.avalPrimerNombre);
    setformSegundoNombre(respuesta.data.avalSegundoNombre);
    setformPrimerApellido(respuesta.data.avalPrimerApellido);
    setformSegundoApellido(respuesta.data.avalSegundoApellido);

    setformCp(respuesta.data.avalCodigoPostal);

    // setformEstado(respuesta.data.email);
    // setformMunicipio(respuesta.data.email);
    // setformColonia(respuesta.data.email);
    setformCalle(respuesta.data.avalCalle);
    setformExt(respuesta.data.avalNumeroExterior);
    setformInt(respuesta.data.avalNumeroInerior);
    setformCurp(respuesta.data.avalCurp);

    if (respuesta.data.avalRfc != '') {
      setavalCapturado(true);
    }

    // setformNacionalidad(
    //   respuesta.data.avalNacionalidadId == 0
    //     ? 142
    //     : respuesta.data.avalNacionalidadId
    // );

    //NACIONALIDAD----------------------------------------------------------------------------------------
    var arrNacionalidad = {};
    if (respuesta.data.avalNacionalidadId == 0) {
      arrNacionalidad = {
        value: 142, // Convierte el ID a una cadena
        label: <div style={{ textAlign: 'left' }}>Mexico</div>,
        nombre: 'México',
      };
    } else {
      const filteredData = optionnacionalidadList.filter(
        (item) => item.value === respuesta.data.avalNacionalidadId
      );
      arrNacionalidad = {
        value: respuesta.data.avalNacionalidadId, // Convierte el ID a una cadena
        label: (
          <div style={{ textAlign: 'left' }}>{filteredData[0].nombre}</div>
        ),
        nombre: filteredData[0].nombre,
      };
    }

    setformNacionalidad(arrNacionalidad);
    //---------------------------------------------------------------------------------------------------

    //PAIS NACIMIENTO------------------------------------------------------------------------------------
    var arrPaisNacimiento = {};
    if (respuesta.data.avalPaisNacimientoId != 0) {
      const filteredData = optionnacionalidadList.filter(
        (item) =>
          parseInt(item.value) === parseInt(respuesta.data.avalPaisNacimientoId)
      );
      arrPaisNacimiento = {
        value: respuesta.data.avalPaisNacimientoId, // Convierte el ID a una cadena
        label: (
          <div style={{ textAlign: 'left' }}>{filteredData[0].nombre}</div>
        ),
        nombre: filteredData[0].nombre,
      };
    }

    setformPaisNacimiento(
      respuesta.data.avalPaisNacimientoId == 0
        ? {
            value: 142, // Convierte el ID a una cadena
            label: <div style={{ textAlign: 'left' }}>Mexico</div>,
            nombre: 'México',
          }
        : arrPaisNacimiento
    );
    //---------------------------------------------------------------------------------------------------

    // console.log(
    //   'avalTipoRegimenFiscalId',
    //   respuesta.data.avalTipoRegimenFiscalId
    // );

    

    //console.log('formRegimenFiscal', formRegimenFiscal);

    if (respuesta.data.coloniaList.length > 0) {
      const optionColoniasSepomex = respuesta.data.coloniaList.map((item) => ({
        value: parseInt(item.id), // Convierte el ID a una cadena
        label: item.nombre,
      }));
      setOptionsColonia(optionColoniasSepomex);

      var arrColonias = {};
      if (respuesta.data.avalColoniaId != 0) {
        const filteredData = optionColoniasSepomex.filter(
          (item) =>
            parseInt(item.value) === parseInt(respuesta.data.avalColoniaId)
        );
        arrColonias = {
          value: respuesta.data.avalColoniaId, // Convierte el ID a una cadena
          label: filteredData[0].label,
        };
      }

      setformColonia(respuesta.data.avalColoniaId == 0 ? null : arrColonias);
      setenableSelectColonia(false);
    } else {
      setformColonia(null);
    }

    //PAIS NACIMIENTO
    // setformPaisNacimiento(
    //   respuesta.data.avalPaisNacimientoId == 0 ||
    //     respuesta.data.avalPaisNacimientoId == null
    //     ? 142
    //     : respuesta.data.avalPaisNacimientoId
    // );

    // setformEstadoNacimiento(
    //   respuesta.data.avalPaisNacimientoId == null
    //     ? null
    //     : respuesta.data.avalEstadoNacimientoId == 0
    //     ? null
    //     : respuesta.data.avalEstadoNacimientoId == null
    //     ? 999
    //     : respuesta.data.avalEstadoNacimientoId.toString()
    // );

    // setformOcupacion(
    //   respuesta.data.avalOcupacionId == 0 ? 609 : respuesta.data.avalOcupacionId
    // );

    //OCUPACION----------------------------------------------------------------------------------------
    var arrOcupacion = {};
    if (respuesta.data.avalOcupacionId == 0) {
      arrOcupacion = {
        value: 609, // Convierte el ID a una cadena
        label: <div style={{ textAlign: 'left' }}>EMPLEADO PRIVADO</div>,
        nombre: 'EMPLEADO PRIVADO',
      };
    } else {
      //console.log('OptionsOcupacion', OptionsOcupacion);
      const filteredData = respuesta.data.ocupacionList.filter(
        (item) => parseInt(item.id) === parseInt(respuesta.data.avalOcupacionId)
      );
      arrOcupacion = {
        value: respuesta.data.avalOcupacionId, // Convierte el ID a una cadena
        label: (
          <div style={{ textAlign: 'left' }}>{filteredData[0].nombre}</div>
        ),
        nombre: filteredData[0].nombre,
      };
    }

    setformOcupacion(arrOcupacion);
    //---------------------------------------------------------------------------------------------------

    //ESTADO NACIMIENTO------------------------------------------------------------------------------------

    var arrEstadoNacimiento = {};
    if (
      respuesta.data.avalEstadoNacimientoId != 0 &&
      respuesta.data.avalEstadoNacimientoId != null
    ) {
      const filteredData = optionestadoNacimientoList.filter(
        (item) =>
          parseInt(item.value) ===
          parseInt(respuesta.data.avalEstadoNacimientoId)
      );
      arrEstadoNacimiento = {
        value: respuesta.data.avalEstadoNacimientoId, // Convierte el ID a una cadena
        label: (
          <div style={{ textAlign: 'left' }}>{filteredData[0].nombre}</div>
        ),
        nombre: filteredData[0].nombre,
      };
    }

    setformEstadoNacimiento(
      respuesta.data.avalEstadoNacimientoId == 0
        ? null
        : respuesta.data.avalEstadoNacimientoId == null
        ? null
        : arrEstadoNacimiento
    );
    //---------------------------------------------------------------------------------------------------

    setTimeout(function () {
      form.setFieldsValue({
        datosAval: {
          RFC: respuesta.data.avalRfc,
          PrimerNombre: respuesta.data.avalPrimerNombre,
          SegundoNombre: respuesta.data.avalSegundoNombre,
          ApellidoPaterno: respuesta.data.avalPrimerApellido,
          ApellidoMaterno: respuesta.data.avalSegundoApellido,
          Nacionalidad:
            respuesta.data.avalNacionalidadId == 0
              ? 142
              : respuesta.data.avalNacionalidadId,
          Correo: respuesta.data.avalEmail,
          codRegFiscal:
            respuesta.data.avalTipoRegimenFiscalId == 0
              ? 1
              : respuesta.data.avalTipoRegimenFiscalId,
          TelefonoOficina: respuesta.data.avalTelefonoOficina,
          ExtensionOficina: respuesta.data.avalExtensionOficina,
          Celular: respuesta.data.avalCelular,
          Curp: respuesta.data.avalCurp,
          CodigoPostal: respuesta.data.avalCodigoPostal,
          Calle: respuesta.data.avalCalle,
          Ext: respuesta.data.avalNumeroExterior,
          Int: respuesta.data.avalNumeroInerior,
          RazonSocial: respuesta.data.avalRazonSocial,
          PaisNacimiento: 142,
          PaisNacimiento:
            respuesta.data.avalPaisNacimientoId == 0 ||
            respuesta.data.avalPaisNacimientoId == null
              ? 142
              : respuesta.data.avalPaisNacimientoId,
          EstadoNacimiento:
            respuesta.data.avalPaisNacimientoId == null
              ? null
              : respuesta.data.avalEstadoNacimientoId == 0
              ? null
              : respuesta.data.avalEstadoNacimientoId == null
              ? 999
              : respuesta.data.avalEstadoNacimientoId.toString(),
          Ocupacion:
            respuesta.data.avalOcupacionId == 0
              ? 609
              : respuesta.data.avalOcupacionId,
        },
      });
    }, 0);

    if (!respuesta.data.habilitado == true) {
      setPmDisabled(true);
      setPfDisabled(true);
      setenableSelectColonia(true);
    }

    if (respuesta.data.avalTipoRegimenFiscalId != 0) {
      if (respuesta.data.avalTipoRegimenFiscalId == 3) {
        setPfaeisVisible(true);
        setPmisVisible(false);
        setformRegimenFiscal(3);
        setformclienteTipoRegimenFiscalId(1);
        //console.log("entro fisica");
      } else {
        
        setPfaeisVisible(false);
        setPmisVisible(true);
        setformRegimenFiscal(2);
        //console.log("entro moral");
        setformclienteTipoRegimenFiscalId(2);
      }
    } else {
      setPfaeisVisible(true);
        setPmisVisible(false);
        setformRegimenFiscal(3);
        setformclienteTipoRegimenFiscalId(1);
    }

    ShowLoading(false);
  };

  const handleNext = async (option) => {
    var regEXRFC =
      /^[A-Za-zñÑ&]{1,2}([A-Za-zñÑ&]([A-Za-zñÑ&](\d(\d(\d(\d(\d(\d(\w(\w(\w)?)?)?)?)?)?)?)?)?)?)?$/;

    var regEXCURP =
      /^[A-Za-zñÑ&]{1,2}([A-Za-zñÑ&]([A-Za-zñÑ&](\d(\d(\d(\d(\d(\d(\w(\w(\w(\w(\w(\w(\w(\w)?)?)?)?)?)?)?)?)?)?)?)?)?)?)?)?$/;

    var regEXCorreo = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;

    if (valueRFC == '') {
      ModalMessage('Mensaje', 'El RFC no puede estar en blanco.');
      return false;
    }

    if (!regEXRFC.test(valueRFC)) {
      ShowLoading(false);
      ModalMessage(
        'Mensaje',
        'El RFC no tiene un formato valido, por favor verifique.'
      );
      return false;
    }

    //console.log(formRegimenFiscal);

    if (formclienteTipoRegimenFiscalId == 1) {
      if (formPrimerNombre == '') {
        ModalMessage('Mensaje', 'El Primer Nombre no puede estar en blanco.');
        return false;
      }

      if (formPrimerApellido == '') {
        ModalMessage(
          'Mensaje',
          'El Apellido Paterno no puede estar en blanco.'
        );
        return false;
      }

      if (formNacionalidad == '') {
        ModalMessage('Mensaje', 'Debes seleccionar una nacionalidad.');
        return false;
      }

      if (formCorreo == '') {
        ModalMessage('Mensaje', 'El Correo del aval no puede estar en blanco.');
        return false;
      }

      if (!regEXCorreo.test(formCorreo)) {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'El Correo del aval no tiene un formato valido, por favor verifique.'
        );
        return false;
      }

      if (formTelefonoOficina != '') {
        if (formTelefonoOficina.length < 10) {
          ShowLoading(false);
          ModalMessage(
            'Mensaje',
            'El teléfono de oficina no tiene un formato valido.'
          );
          return false;
        }
      }

      if (formCelular == '') {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'El celular del aval no puede estar en blanco.'
        );
        return false;
      }

      if (formCelular.length < 10) {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'El celular del aval no tiene un formato valido.'
        );
        return false;
      }

      if (formCurp == '') {
        ModalMessage('Mensaje', 'La CURP no puede estar en blanco.');
        return false;
      }

      if (formCurp.length < 18) {
        ModalMessage(
          'Mensaje',
          'La CURP no tiene un formato valido, por favor verifique.'
        );
        return false;
      }

      if (formPaisNacimiento == null) {
        ModalMessage(
          'Mensaje',
          'El Pais de Nacimiento no puede estar en blanco.'
        );
        return false;
      }

      //console.log('estado nacimiento', formEstadoNacimiento);

      if (formEstadoNacimiento == null) {
        ModalMessage(
          'Mensaje',
          'El Estado de Nacimiento no puede estar en blanco.'
        );
        return false;
      }

      if (formOcupacion == null) {
        ModalMessage('Mensaje', 'La Ocupación no puede estar en blanco.');
        return false;
      }

      /*
      if (!regEXCURP.test(formCurp)) {
        ShowLoading(false);
        ModalMessage("Mensaje", "La CURP no tiene un formato valido, por favor verifique.");
        return false;
      }
      */
    } else {
      if (formRazonSocial == '') {
        ModalMessage('Mensaje', 'La Razón Social no puede estar en blanco.');
        return false;
      }

      if (formNacionalidad.value == '') {
        ModalMessage('Mensaje', 'Debes seleccionar una Nacionalidad.');
        return false;
      }

      if (formCorreo == '') {
        ModalMessage('Mensaje', 'El Correo no puede estar en blanco.');
        return false;
      }

      if (!regEXCorreo.test(formCorreo)) {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'El correo no tiene un formato valido, por favor verifique.'
        );
        return false;
      }

      if (formTelefonoOficina != '') {
        if (formTelefonoOficina.length < 10) {
          ShowLoading(false);
          ModalMessage(
            'Mensaje',
            'El Teléfono de Oficina no tiene un formato valido.'
          );
          return false;
        }
      }

      if (formPaisNacimiento == null) {
        ModalMessage(
          'Mensaje',
          'El Pais de Nacimiento no puede estar en blanco.'
        );
        return false;
      }

      if (formEstadoNacimiento == null) {
        ModalMessage(
          'Mensaje',
          'El Estado de Nacimiento no puede estar en blanco.'
        );
        return false;
      }

      if (formCelular == '') {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'El celular del aval no puede estar en blanco.'
        );
        return false;
      }

      if (formCelular.length < 10) {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'El celular del aval no tiene un formato valido.'
        );
        return false;
      }

      if (formPrimerNombre == '') {
        ModalMessage('Mensaje', 'El Primer Nombre no puede estar en blanco.');
        return false;
      }

      if (formPrimerApellido == '') {
        ModalMessage(
          'Mensaje',
          'El Apellido Paterno no puede estar en blanco.'
        );
        return false;
      }
    }

    if (formCp == '') {
      ModalMessage('Mensaje', 'El Código Postal no puede estar en blanco.');
      return false;
    }

    if (formCp.length < 5) {
      ShowLoading(false);
      ModalMessage('Mensaje', 'El Código Postal no tiene un formato valido.');
      return false;
    }

    if (formEstado == '') {
      ModalMessage('Mensaje', 'El Estado no puede estar en blanco.');
      return false;
    }

    if (formMunicipio == '') {
      ModalMessage('Mensaje', 'El Municipio no puede estar en blanco.');
      return false;
    }

    //console.log(formColonia);

    if (formColonia == '' || formColonia == null) {
      ModalMessage('Mensaje', 'La Colonia no puede estar en blanco.');
      return false;
    }

    if (formCalle == '') {
      ModalMessage('Mensaje', 'La Calle no puede estar en blanco.');
      return false;
    }

    if (formExt == '') {
      ModalMessage('Mensaje', 'El Número Exterior no puede estar en blanco.');
      return false;
    }

    //console.log('tipo regimen', formRegimenFiscal);

    const json = {
      solicitudId: solicitudId,
      tipoRegimenFiscalId: formclienteTipoRegimenFiscalId == 1 ? 3 : 2,
      rfc: valueRFC,
      razonSocial: formRazonSocial,
      primerNombre: formPrimerNombre,
      segundoNombre: formSegundoNombre,
      primerApellido: formPrimerApellido,
      segundoApellido: formSegundoApellido,
      curp: formCurp,
      nacionalidadId: formNacionalidad.value,
      email: formCorreo,
      telefonoOficina: formTelefonoOficina,
      extensionOficina: formExtOficina,
      celular: formCelular,
      codigoPostal: formCp,
      coloniaId: formColonia.value,
      calle: formCalle,
      numeroExterior: formExt,
      numeroInterior: formInt,

      paisNacimientoId: formPaisNacimiento.value,
      estadoNacimientoId:
        parseInt(formEstadoNacimiento.value) == 999
          ? null
          : parseInt(formEstadoNacimiento.value),
      ocupacionId: formOcupacion.value,
    };

    setNombreCompletoAval(
      formPrimerNombre +
        ' ' +
        formSegundoNombre +
        ' ' +
        formPrimerApellido +
        ' ' +
        formSegundoApellido
    );

    //console.log(json);

    ShowLoading(true);

    if (DisableForm == false) {
      let respuesta = await FormulariosService.postGuardaDatosAval(json);

      if (respuesta.result != true) {
        ShowLoading(false);
        let respuestaSesion = await AuthService.getValidaSesion();

        if (respuestaSesion.result == false) {
          CloseSession();
        } else {
          ModalMessage('Error', respuesta.msg);
        }
        return false;
      }
    }

    ShowLoading(false);
    //console.log(json);

    if (estadoAval == -1 || estadoAval == -0) {
      setisModalOpen(true);
    } else {
      // navigate(option, {
      //   state: { record: { solicitudId: solicitudId } },
      // });
      handleSkipView();
    }

    // if (avalCapturado == false) {
    //   setisModalOpen(true);
    // } else {
    //   navigate(option, {
    //     state: { record: { solicitudId: solicitudId } },
    //   });
    // }
  };

  const onFinish = () => {
    handleNext('/datos_empresa');
  };

  const onChange = () => {};

  const handleBack = () => {
    navigate('/datos_contacto', {
      state: { record: { solicitudId: solicitudId } },
    });
  };

  const optionsDestino = [
    {
      value: 'Sinaloa',
      label: 'Sinaloa',
    },
    {
      value: 'Sonora',
      label: 'Sonora',
    },
  ];

  const showConfirm = (e) => {
    if (e.target.value == 1) {
      setPfaeisVisible(false);
      setPmisVisible(true);
      setformRegimenFiscal(3);
    } else if (e.target.value == 3) {
      setPfaeisVisible(false);
      setPmisVisible(true);
      setformRegimenFiscal(1);
    } else {
      setPfaeisVisible(true);
      setPmisVisible(false);
      setformRegimenFiscal(2);
    }
  };

  const getCpData = async (strCp) => {
    ShowLoading(true);

    if (strCp == '') {
      ModalMessage('Error', 'El código postal no puede estar en blanco.');
      ShowLoading(false);
      return false;
    }

    let cpData = await SepomexService.getCatalogosCodigoPostal(strCp);

    //console.log('datos codigo postal', cpData);

    if (cpData.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar la colonia.');
      }
      return false;
    }

    const optionEstadosSepomex = [
      {
        value: parseInt(cpData.data.estado.id),
        label: cpData.data.estado.nombre,
      },
    ];
    setOptionsEstados(optionEstadosSepomex);
    setformEstado(parseInt(cpData.data.estado.id));

    const optionMunicipiosSepomex = [
      {
        value: parseInt(cpData.data.municipio.id),
        label: cpData.data.municipio.nombre,
      },
    ];
    setOptionsMunicipio(optionMunicipiosSepomex);
    setformMunicipio(parseInt(cpData.data.municipio.id));

    const optionColoniasSepomex = cpData.data.coloniaList.map((item) => ({
      value: parseInt(item.id), // Convierte el ID a una cadena
      label: item.nombre,
    }));

    setOptionsColonia(optionColoniasSepomex);
    setformColonia(null);
    setenableSelectColonia(false);

    ShowLoading(false);

    // if (cpData.estado == 500 || cpData.estado == 99) {
    //   form.setFieldsValue({
    //     renapo: {
    //       estado: "",
    //       municipio: "",
    //       ciudad: "",
    //     },
    //   });
    //   setOptionsColonia([]);
    //   showModal("Codigo postal no localizado.");
    // } else {
    //   form.setFieldsValue({
    //     renapo: {
    //       estado: cpData.data[0].estado,
    //       municipio: cpData.data[0].municipio,
    //       ciudad: cpData.data[0].ciudad,
    //     },
    //   });
    //   setOptionsColonia(cpData.data);
    // }
  };

  const onSearch = (value, event) => {
    event.preventDefault();
    getCpData(value);
  };

  const removeAccents = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  const filterOption = (input, option) => {
    const normalizedInput = removeAccents(input).toLowerCase();
    const normalizedOption = removeAccents(option?.nombre).toLowerCase();

    return normalizedOption.includes(normalizedInput);
  };

  const handleModalClose = async () => {
    setisModalOpen(false);

    if (estadoAval == 0) {
      ShowLoading(true);
      let respuesta =
        await FormulariosService.getReenviarCorreoAval(solicitudId);

      if (respuesta.result != true) {
        ShowLoading(false);
        let respuestaSesion = await AuthService.getValidaSesion();

        if (respuestaSesion.result == false) {
          CloseSession();
        } else {
          ModalMessage('Error', 'Se produjo un error al reenviar el correo.');
        }
        return false;
      }
      ShowLoading(false);
    }

    handleSkipView();
    // navigate('/autorizacion_sat', {
    //   state: { record: { solicitudId: solicitudId } },
    // });
  };

  const handleChangePaisNacimiento = async (value) => {
    //console.log(value);
    setformPaisNacimiento(value);

    let respuestaEstados = await SepomexService.getEstadosSepomex();

    const filteredData = listaPaises.filter((item) => item.value === value);

    if (value == 142) {
      //console.log(respuestaEstados);

      const optionestadoNacimientoList = respuestaEstados.data.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.nombre}</div>,
        nombre: item.nombre,
      }));
      setOptionsEstadosNacimiento(optionestadoNacimientoList);
      setformEstadoNacimiento(null);

      setTimeout(function () {
        form.setFieldsValue({
          datosAval: {
            EstadoNacimiento: null,
          },
        });
      }, 0);
    } else {
      setOptionsEstadosNacimiento([
        {
          value: 999, // Convierte el ID a una cadena
          label: <div className="lblOptionCombo">{filteredData[0].nombre}</div>,
          nombre: filteredData[0].nombre,
        },
      ]);

      setTimeout(function () {
        form.setFieldsValue({
          datosAval: {
            EstadoNacimiento: 999,
          },
        });
      }, 0);

      setformEstadoNacimiento(999);
    }
  };

  const handleTipoClienteChange = (value) => {
    setvalueRFC('');

    setformclienteTipoRegimenFiscalId(value);

    // setTimeout(function () {
    //   form.setFieldsValue({
    //     datosClientes: {
    //       RFC: '',
    //     },
    //   });
    // }, 0);

    if (value == 1) {
      setPfaeisVisible(true);
      setPmisVisible(false);

      setformRegimenFiscal(3);
    } else {
      setPfaeisVisible(false);
      setPmisVisible(true);
      setformRegimenFiscal(2);
    }

    //console.log('setformRegimenFiscal', formRegimenFiscal);
  };

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      getCpData(formCp);
      // Ejecutar la acción aquí
      //console.log('Enter presionado');
    }
  };

  const handleSkipView = async () => {
    let respuesta = await FormulariosService.getValidaCapturaFirma(
      solicitudId,
      'cliente'
    );

    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      }
      return false;
    }

    //console.log("estado firma sat", respuesta.data.estado);

    if (respuesta.data.estado == 1 || respuesta.data.estado == 3) {


      let respuestaBuro = await FormulariosService.getValidaCapturaFirmaBuro(
        solicitudId,
        'cliente'
      );

      if (respuestaBuro.result != true) {
        ShowLoading(false);
        let respuestaSesion = await AuthService.getValidaSesion();
  
        if (respuestaSesion.result == false) {
          CloseSession();
        } else {
          ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
        }
        return false;
      }

      if (respuestaBuro.data.estado != 0) {
        navigate('/revision', {
          state: { record: { solicitudId: solicitudId } },
        });
      }
      else {
        navigate('/firma_buro', {
          state: { record: { solicitudId: solicitudId } },
        });  
      }

      

      ShowLoading(false);
      return false;
    } else {
      navigate('/autorizacion_sat', {
        state: { record: { solicitudId: solicitudId } },
      });
    }
  };

  
  return (
    <div
      style={{
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        height: windowSize.height,
        minHeight: 450,
      }}
    >
      <div
        className="div-img-arrendamiento"
        style={{
          borderRadius: 25,
          padding: 0,
          boxShadow: '0px 0px 22px -10px rgba(0,0,0,0.75)',
          marginBottom: 0,
          background: '#fff',
          marginTop: 30,
          width: windowSize.width - 200,
          minHeight: windowSize.height + 20,
          minWidth: 370,
        }}
      >
        <center>
          <Divider>
            <div
              className="title-steps"
              style={{ margin: 0, color: '#4c4cbc', marginTop: 0 }}
            >
              Información del Aval
            </div>
          </Divider>
        </center>

        <ConfigProvider
          theme={{
            token: {
              colorBgElevated: '#4c4cbc',
            },
          }}
        >
          <center>
            <Segmented
              options={[
                {
                  label: (
                    <div className="div-segmented">
                      <FaBuilding style={{ fontSize: 15 }} />
                      PERSONA MORAL
                    </div>
                  ),
                  value: 2,
                },
                {
                  label: (
                    <div className="div-segmented">
                      <FaUserTie style={{ fontSize: 15 }} />
                      PERSONA FÍSICA
                    </div>
                  ),
                  value: 1,
                },
              ]}
              onChange={(value) => {
                //console.log(value); // string
                handleTipoClienteChange(value);
              }}
              value={formclienteTipoRegimenFiscalId}
              disabled={DisableForm}
            />
          </center>
        </ConfigProvider>

        <div
          style={{
            overflow: 'auto',
            maxHeight: windowSize.height - 120,
            width: '99%',
            overflowX: 'hidden',
          }}
        >
          {/* ------------------------------------PFAE------------------------------------------------------------------------------------------  */}

          <Skeleton loading={loading} active={true}>
            <div
              style={{
                display: PfaeisVisible ? 'block' : 'none',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row style={{ width: '90%', marginTop: 8 }}>
                  <Col span={24}>
                    <RfcTextField
                      style={{ width: '100%', marginBottom: 10 }}
                      label={
                        <>
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          <label>RFC(Con Homoclave)</label>
                        </>
                      }
                      value={valueRFC}
                      onChange={setvalueRFC}
                      tipo={RfcTypes.Fisica}
                      onInput={(e) =>
                        (e.target.value = e.target.value.toUpperCase())
                      }
                      disabled={DisableForm}
                    />
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row gutter={6} style={{ width: '90.5%' }}>
                  <Col flex="1 1 400px">
                    <TextFieldCustom
                      style={{ width: '100%', marginBottom: 10 }}
                      label={
                        <>
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          <label>Primer Nombre</label>
                        </>
                      }
                      value={formPrimerNombre}
                      onChange={setformPrimerNombre}
                      inputtype={InputTypes.Text}
                      disabled={DisableForm}
                    />
                  </Col>

                  <Col flex="1 1 400px" style={{ marginLeft: 0 }}>
                    <TextFieldCustom
                      style={{ width: '100%', marginBottom: 10 }}
                      label="Segundo Nombre"
                      value={formSegundoNombre}
                      onChange={setformSegundoNombre}
                      inputtype={InputTypes.Text}
                      disabled={DisableForm}
                    />
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row gutter={6} style={{ width: '90.5%' }}>
                  <Col flex="1 1 400px">
                    <TextFieldCustom
                      style={{ width: '100%', marginBottom: 10 }}
                      label={
                        <>
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          <label>Apellido Paterno</label>
                        </>
                      }
                      value={formPrimerApellido}
                      onChange={setformPrimerApellido}
                      inputtype={InputTypes.Text}
                      disabled={DisableForm}
                    />
                  </Col>

                  <Col flex="1 1 400px" style={{ marginLeft: 0 }}>
                    <TextFieldCustom
                      style={{ width: '100%', marginBottom: 10 }}
                      label="Apellido Materno"
                      value={formSegundoApellido}
                      onChange={setformSegundoApellido}
                      inputtype={InputTypes.Text}
                      disabled={DisableForm}
                    />
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row gutter={6} style={{ width: '90.5%' }}>
                  <Col flex="1 1 400px">
                    <Autocomplete
                      size="small"
                      style={{ marginBottom: 10 }}
                      value={formNacionalidad}
                      onChange={(event, newValue) =>
                        setformNacionalidad(newValue)
                      }
                      disabled={DisableForm}
                      options={OptionsNacionalidad}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) =>
                        option.nombre === value.nombre
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <>
                              <label style={{ color: 'red', fontSize: 12 }}>
                                *
                              </label>{' '}
                              <label>Nacionalidad</label>
                            </>
                          }
                          placeholder="Nacionalidad"
                        />
                      )}
                      filterOption={(inputValue, option) =>
                        filterOption(inputValue, option)
                      }
                    />
                  </Col>

                  <Col flex="1 1 400px">
                    <TextField
                      style={{ width: '100%', marginBottom: 10 }}
                      label={
                        <>
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          <label>Correo</label>
                        </>
                      }
                      value={formCorreo}
                      onChange={(e) => setformCorreo(e.target.value)}
                      size="small"
                      variant="outlined"
                      disabled={DisableForm}
                    />
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row gutter={6} style={{ width: '90.5%' }}>
                  <Col flex="1 1 400px">
                    <TextFieldCustom
                      inputProps={{ maxLength: 10 }}
                      style={{ width: '100%', marginBottom: 10 }}
                      label={
                        <>
                          <label>Teléfono de Oficina</label>
                        </>
                      }
                      value={formTelefonoOficina}
                      onChange={setformTelefonoOficina}
                      inputtype={InputTypes.Integer}
                      disabled={DisableForm}
                    />
                  </Col>

                  <Col flex="1 1 400px" style={{ marginLeft: 0 }}>
                    <TextFieldCustom
                      inputProps={{ maxLength: 10 }}
                      style={{ width: '100%', marginBottom: 10 }}
                      label="Extensión de Oficina"
                      value={formExtOficina}
                      onChange={setformExtOficina}
                      inputtype={InputTypes.Integer}
                      disabled={DisableForm}
                    />
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row gutter={6} style={{ width: '90.5%' }}>
                  <Col flex="1 1 400px">
                    <TextFieldCustom
                      style={{ width: '100%', marginBottom: 10 }}
                      label={
                        <>
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          <label>Número Celular</label>
                        </>
                      }
                      value={formCelular}
                      onChange={setformCelular}
                      inputtype={InputTypes.Integer}
                      disabled={DisableForm}
                    />
                  </Col>

                  <Col flex="1 1 400px" style={{ marginLeft: 0 }}>
                    <TextFieldCustom
                      style={{ width: '100%', marginBottom: 10 }}
                      label={
                        <>
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          <label>CURP</label>
                        </>
                      }
                      value={formCurp}
                      onChange={setformCurp}
                      inputtype={InputTypes.Curp}
                      disabled={DisableForm}
                    />
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row gutter={6} style={{ width: '90.5%' }}>
                  <Col flex="1 1 400px">
                    <Autocomplete
                      size="small"
                      style={{ marginBottom: 10 }}
                      value={formPaisNacimiento}
                      onChange={(event, newValue) =>
                        handleChangePaisNacimiento(newValue)
                      }
                      disabled={DisableForm}
                      options={OptionsNacionalidad}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) =>
                        option.nombre === value.nombre
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <>
                              <label style={{ color: 'red', fontSize: 12 }}>
                                *
                              </label>{' '}
                              <label>País de Nacimiento</label>
                            </>
                          }
                          placeholder="País de Nacimiento"
                        />
                      )}
                      filterOption={(inputValue, option) =>
                        filterOption(inputValue, option)
                      }
                    />
                  </Col>

                  <Col flex="1 1 400px">
                    <Autocomplete
                      size="small"
                      style={{ marginBottom: 10 }}
                      value={formEstadoNacimiento}
                      onChange={(event, newValue) =>
                        setformEstadoNacimiento(newValue)
                      }
                      options={OptionsEstadosNacimiento}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) =>
                        option.nombre === value.nombre
                      }
                      disabled={DisableForm}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <>
                              <label style={{ color: 'red', fontSize: 12 }}>
                                *
                              </label>{' '}
                              <label>Estado de Nacimiento</label>
                            </>
                          }
                          placeholder="Estado de Nacimiento"
                        />
                      )}
                      filterOption={(inputValue, option) =>
                        filterOption(inputValue, option)
                      }
                    />
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row style={{ width: '90%' }}>
                  <Col span={24}>
                    <Autocomplete
                      size="small"
                      style={{ marginBottom: 10 }}
                      value={formOcupacion}
                      disabled={DisableForm}
                      onChange={(event, newValue) => setformOcupacion(newValue)}
                      options={OptionsOcupacion}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) =>
                        option.nombre === value.nombre
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <>
                              <label style={{ color: 'red', fontSize: 12 }}>
                                *
                              </label>{' '}
                              <label>Ocupación</label>
                            </>
                          }
                          placeholder="Ocupación"
                        />
                      )}
                      filterOption={(inputValue, option) =>
                        filterOption(inputValue, option)
                      }
                    />
                  </Col>
                </Row>
              </div>

              <Typography.Title
                level={4}
                style={{ marginTop: 5, marginLeft: -20 }}
              >
                Domicilio
              </Typography.Title>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  marginTop: 25,
                }}
              >
                <Row gutter={6} style={{ width: '90.5%' }}>
                  <Col flex="1 1 400px">
                    <Paper
                      component="form"
                      sx={{
                        p: '4px 4px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      style={{ height: 42 }}
                      size="small"
                      elevation={0}
                    >
                      <TextFieldCustom
                        inputProps={{ maxLength: 5 }}
                        style={{
                          width: '110%',
                          marginBottom: 10,
                          marginLeft: -3,
                        }}
                        label={
                          <>
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            <label>Código Postal</label>
                          </>
                        }
                        value={formCp}
                        onChange={setformCp}
                        inputtype={InputTypes.Integer}
                        onKeyDown={handleEnterPress}
                        disabled={DisableForm}
                      />
                      <Button
                        style={{ width: 50, height: 40, marginTop: -10 }}
                        className="buttons-empresa"
                        type="primary"
                        onClick={() => getCpData(formCp)}
                        icon={<SearchOutlined />}
                        disabled={DisableForm}
                      ></Button>
                    </Paper>
                  </Col>

                  <Col flex="1 1 400px" style={{ marginLeft: 0 }}>
                    <FormControl style={{ width: '100%', marginBottom: 10 }}>
                      <InputLabel id="select-label">
                        <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                        <label style={{ opacity: 0.5 }}>Estado</label>
                      </InputLabel>
                      <MuiSelect
                        labelId="select-label"
                        id="select"
                        value={formEstado}
                        disabled
                        style={{ opacity: 0.5, pointerEvents: 'none' }}
                        label={
                          <>
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            <label>Estado</label>
                          </>
                        }
                        onChange={(e) => setformEstado(e.target.value)}
                        size="small"
                      >
                        {OptionsEstados.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            <div style={{ textAlign: 'left' }}>
                              {option.label}
                            </div>
                          </MenuItem>
                        ))}
                      </MuiSelect>
                    </FormControl>
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row gutter={6} style={{ width: '90.5%' }}>
                  <Col flex="1 1 400px">
                    <FormControl style={{ width: '100%', marginBottom: 10 }}>
                      <InputLabel id="select-label">
                        <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                        <label style={{ opacity: 0.5 }}>
                          Municipio/Delegación
                        </label>
                      </InputLabel>
                      <MuiSelect
                        labelId="select-label"
                        id="select"
                        value={formMunicipio}
                        disabled
                        style={{ opacity: 0.5, pointerEvents: 'none' }}
                        label={
                          <>
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            <label>Municipio/Delegación</label>
                          </>
                        }
                        onChange={(e) => setformMunicipio(e.target.value)}
                        size="small"
                      >
                        {OptionsMunicipio.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            <div style={{ textAlign: 'left' }}>
                              {option.label}
                            </div>
                          </MenuItem>
                        ))}
                      </MuiSelect>
                    </FormControl>
                  </Col>

                  <Col flex="1 1 400px" style={{ marginLeft: 0 }}>
                    <Autocomplete
                      size="small"
                      style={{ marginBottom: 10 }}
                      value={formColonia}
                      disabled={disableSelectColonia}
                      onChange={(event, newValue) => setformColonia(newValue)}
                      options={OptionsColonia}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.nombre
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <>
                              <label style={{ color: 'red', fontSize: 12 }}>
                                *
                              </label>{' '}
                              <label>Colonia</label>
                            </>
                          }
                          placeholder="Colonia"
                        />
                      )}
                      filterOption={(inputValue, option) =>
                        filterOption(inputValue, option)
                      }
                    />
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row gutter={6} style={{ width: '90.5%' }}>
                  <Col flex="1 1 400px">
                    <TextField
                      style={{ width: '100%', marginBottom: 10 }}
                      label={
                        <>
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          <label>Calle</label>
                        </>
                      }
                      value={formCalle}
                      onChange={(e) => setformCalle(e.target.value)}
                      size="small"
                      variant="outlined"
                      disabled={DisableForm}
                    />
                  </Col>

                  <Col flex="1 1 400px" style={{ marginLeft: 0 }}>
                    <TextFieldCustom
                      inputProps={{ maxLength: 10 }}
                      style={{ width: '100%', marginBottom: 10 }}
                      label={
                        <div style={{ zIndex: 1 }}>
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          No. Ext.
                        </div>
                      }
                      value={formExt}
                      onChange={setformExt}
                      inputtype={InputTypes.Integer}
                      disabled={DisableForm}
                    />
                  </Col>

                  <Col flex="1 1 400px">
                    <TextFieldCustom
                      inputProps={{ maxLength: 10 }}
                      style={{ width: '100%', marginBottom: 10 }}
                      label={<div style={{ zIndex: 1 }}>No. Int.</div>}
                      value={formInt}
                      onChange={setformInt}
                      inputtype={InputTypes.Integer}
                      disabled={DisableForm}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            {/* ------------------------------------------------------------------------------------------------------------------------------  */}

            {/* -----------------------MORAL--------------------------------------------------------------------------------------------------  */}

            <div
              style={{
                display: PmisVisible ? 'block' : 'none',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row style={{ width: '90%', marginTop: 5 }}>
                  <Col span={24}>
                    <RfcTextField
                      style={{ width: '100%', marginBottom: 10 }}
                      label={
                        <>
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          <label>RFC(Con Homoclave)</label>
                        </>
                      }
                      value={valueRFC}
                      onChange={setvalueRFC}
                      tipo={RfcTypes.Moral}
                      onInput={(e) =>
                        (e.target.value = e.target.value.toUpperCase())
                      }
                      disabled={DisableForm}
                    />
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row style={{ width: '90%' }}>
                  <Col span={24}>
                    <TextField
                      style={{ width: '100%', marginBottom: 10 }}
                      label={
                        <>
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          <label>Razón Social</label>
                        </>
                      }
                      value={formRazonSocial}
                      onChange={(e) => setformRazonSocial(e.target.value)}
                      size="small"
                      variant="outlined"
                      disabled={DisableForm}
                    />
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row gutter={6} style={{ width: '90.5%' }}>
                  <Col flex="1 1 400px">
                    <Autocomplete
                      size="small"
                      style={{ marginBottom: 10 }}
                      value={formNacionalidad}
                      onChange={(event, newValue) =>
                        setformNacionalidad(newValue)
                      }
                      disabled={DisableForm}
                      options={OptionsNacionalidad}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) =>
                        option.nombre === value.nombre
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <>
                              <label style={{ color: 'red', fontSize: 12 }}>
                                *
                              </label>{' '}
                              <label>Nacionalidad</label>
                            </>
                          }
                          placeholder="Nacionalidad"
                        />
                      )}
                      filterOption={(inputValue, option) =>
                        filterOption(inputValue, option)
                      }
                    />
                  </Col>

                  <Col flex="1 1 400px" style={{ marginLeft: 0 }}>
                    <TextField
                      style={{ width: '100%', marginBottom: 10 }}
                      label={
                        <>
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          <label>Correo</label>
                        </>
                      }
                      value={formCorreo}
                      onChange={(e) => setformCorreo(e.target.value)}
                      size="small"
                      variant="outlined"
                      disabled={DisableForm}
                    />
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row gutter={6} style={{ width: '90.5%' }}>
                  <Col flex="1 1 400px">
                    <TextFieldCustom
                      inputProps={{ maxLength: 10 }}
                      style={{ width: '100%', marginBottom: 10 }}
                      label={
                        <>
                          <label>Teléfono de Oficina</label>
                        </>
                      }
                      value={formTelefonoOficina}
                      onChange={setformTelefonoOficina}
                      inputtype={InputTypes.Text}
                      disabled={DisableForm}
                    />
                  </Col>

                  <Col flex="1 1 400px" style={{ marginLeft: 0 }}>
                    <TextFieldCustom
                      inputProps={{ maxLength: 10 }}
                      style={{ width: '100%', marginBottom: 10 }}
                      label="Extensión de Oficina"
                      value={formExtOficina}
                      onChange={setformExtOficina}
                      inputtype={InputTypes.Text}
                      disabled={DisableForm}
                    />
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row gutter={6} style={{ width: '90.5%' }}>
                  <Col flex="1 1 400px">
                    <Autocomplete
                      size="small"
                      style={{ marginBottom: 10 }}
                      value={formPaisNacimiento}
                      onChange={(event, newValue) =>
                        handleChangePaisNacimiento(newValue)
                      }
                      disabled={DisableForm}
                      options={OptionsNacionalidad}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) =>
                        option.nombre === value.nombre
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <>
                              <label style={{ color: 'red', fontSize: 12 }}>
                                *
                              </label>{' '}
                              <label>País de Nacimiento</label>
                            </>
                          }
                          placeholder="País de Nacimiento"
                        />
                      )}
                      filterOption={(inputValue, option) =>
                        filterOption(inputValue, option)
                      }
                    />
                  </Col>

                  <Col flex="1 1 400px">
                    <Autocomplete
                      size="small"
                      style={{ marginBottom: 10 }}
                      value={formEstadoNacimiento}
                      onChange={(event, newValue) =>
                        setformEstadoNacimiento(newValue)
                      }
                      options={OptionsEstadosNacimiento}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) =>
                        option.nombre === value.nombre
                      }
                      disabled={DisableForm}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <>
                              <label style={{ color: 'red', fontSize: 12 }}>
                                *
                              </label>{' '}
                              <label>Estado de Nacimiento</label>
                            </>
                          }
                          placeholder="Estado de Nacimiento"
                        />
                      )}
                      filterOption={(inputValue, option) =>
                        filterOption(inputValue, option)
                      }
                    />
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row style={{ width: '90%' }}>
                  <Col span={24}>
                    <TextFieldCustom
                      inputProps={{ maxLength: 10 }}
                      style={{ width: '100%', marginBottom: 10 }}
                      label={
                        <>
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          <label>Número Celular</label>
                        </>
                      }
                      value={formCelular}
                      onChange={setformCelular}
                      inputtype={InputTypes.Integer}
                      disabled={DisableForm}
                    />
                  </Col>
                </Row>
              </div>

              <Typography.Title
                level={4}
                style={{ marginTop: 5, marginLeft: -20 }}
              >
                Representante Legal
              </Typography.Title>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row gutter={6} style={{ width: '90.5%' }}>
                  <Col flex="1 1 400px">
                    <TextFieldCustom
                      style={{ width: '100%', marginBottom: 10 }}
                      label={
                        <>
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          <label>Primer Nombre</label>
                        </>
                      }
                      value={formPrimerNombre}
                      onChange={setformPrimerNombre}
                      inputtype={InputTypes.Text}
                      disabled={DisableForm}
                    />
                  </Col>

                  <Col flex="1 1 400px" style={{ marginLeft: 0 }}>
                    <TextFieldCustom
                      style={{ width: '100%', marginBottom: 10 }}
                      label="Segundo Nombre"
                      value={formSegundoNombre}
                      onChange={setformSegundoNombre}
                      inputtype={InputTypes.Text}
                      disabled={DisableForm}
                    />
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row gutter={6} style={{ width: '90.5%' }}>
                  <Col flex="1 1 400px">
                    <TextFieldCustom
                      style={{ width: '100%', marginBottom: 10 }}
                      label={
                        <>
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          <label>Apellido Paterno</label>
                        </>
                      }
                      value={formPrimerApellido}
                      onChange={setformPrimerApellido}
                      inputtype={InputTypes.Text}
                      disabled={DisableForm}
                    />
                  </Col>

                  <Col flex="1 1 400px" style={{ marginLeft: 0 }}>
                    <TextFieldCustom
                      style={{ width: '100%', marginBottom: 10 }}
                      label="Apellido Materno"
                      value={formSegundoApellido}
                      onChange={setformSegundoApellido}
                      inputtype={InputTypes.Text}
                      disabled={DisableForm}
                    />
                  </Col>
                </Row>
              </div>

              <Typography.Title
                level={4}
                style={{ marginTop: 5, marginLeft: -20 }}
              >
                Domicilio
              </Typography.Title>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  marginTop: 25,
                }}
              >
                <Row gutter={6} style={{ width: '90.5%' }}>
                  <Col flex="1 1 400px">
                    <Paper
                      component="form"
                      sx={{
                        p: '4px 4px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      style={{ height: 42 }}
                      size="small"
                      elevation={0}
                    >
                      <TextFieldCustom
                        inputProps={{ maxLength: 5 }}
                        style={{
                          width: '110%',
                          marginBottom: 10,
                          marginLeft: -3,
                        }}
                        label={
                          <>
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            <label>Código Postal</label>
                          </>
                        }
                        value={formCp}
                        onChange={setformCp}
                        inputtype={InputTypes.Integer}
                        onKeyDown={handleEnterPress}
                        disabled={DisableForm}
                      />
                      <Button
                        style={{ width: 50, height: 40, marginTop: -10 }}
                        className="buttons-empresa"
                        type="primary"
                        onClick={() => getCpData(formCp)}
                        icon={<SearchOutlined />}
                        disabled={DisableForm}
                      ></Button>
                    </Paper>
                  </Col>

                  <Col flex="1 1 400px" style={{ marginLeft: 0 }}>
                    <FormControl style={{ width: '100%', marginBottom: 10 }}>
                      <InputLabel id="select-label">
                        <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                        <label style={{ opacity: 0.5 }}>Estado</label>
                      </InputLabel>
                      <MuiSelect
                        labelId="select-label"
                        id="select"
                        value={formEstado}
                        disabled
                        style={{ opacity: 0.5, pointerEvents: 'none' }}
                        label={
                          <>
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            <label>Estado</label>
                          </>
                        }
                        onChange={(e) => setformEstado(e.target.value)}
                        size="small"
                      >
                        {OptionsEstados.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            <div style={{ textAlign: 'left' }}>
                              {option.label}
                            </div>
                          </MenuItem>
                        ))}
                      </MuiSelect>
                    </FormControl>
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row gutter={6} style={{ width: '90.5%' }}>
                  <Col flex="1 1 400px">
                    <FormControl style={{ width: '100%', marginBottom: 10 }}>
                      <InputLabel id="select-label">
                        <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                        <label style={{ opacity: 0.5 }}>
                          Municipio/Delegación
                        </label>
                      </InputLabel>
                      <MuiSelect
                        labelId="select-label"
                        id="select"
                        value={formMunicipio}
                        disabled
                        style={{ opacity: 0.5, pointerEvents: 'none' }}
                        label={
                          <>
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            <label>Municipio/Delegación</label>
                          </>
                        }
                        onChange={(e) => setformMunicipio(e.target.value)}
                        size="small"
                      >
                        {OptionsMunicipio.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            <div style={{ textAlign: 'left' }}>
                              {option.label}
                            </div>
                          </MenuItem>
                        ))}
                      </MuiSelect>
                    </FormControl>
                  </Col>

                  <Col flex="1 1 400px" style={{ marginLeft: 0 }}>
                    <Autocomplete
                      size="small"
                      style={{ marginBottom: 10 }}
                      value={formColonia}
                      disabled={disableSelectColonia}
                      onChange={(event, newValue) => setformColonia(newValue)}
                      options={OptionsColonia}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.nombre
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <>
                              <label style={{ color: 'red', fontSize: 12 }}>
                                *
                              </label>{' '}
                              <label>Colonia</label>
                            </>
                          }
                          placeholder="Colonia"
                        />
                      )}
                      filterOption={(inputValue, option) =>
                        filterOption(inputValue, option)
                      }
                    />
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row gutter={6} style={{ width: '90.5%' }}>
                  <Col flex="1 1 400px">
                    <TextField
                      style={{ width: '100%', marginBottom: 10 }}
                      label={
                        <>
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          <label>Calle</label>
                        </>
                      }
                      value={formCalle}
                      onChange={(e) => setformCalle(e.target.value)}
                      size="small"
                      variant="outlined"
                      disabled={DisableForm}
                    />
                  </Col>

                  <Col flex="1 1 400px" style={{ marginLeft: 0 }}>
                    <TextFieldCustom
                      inputProps={{ maxLength: 10 }}
                      style={{ width: '100%', marginBottom: 10 }}
                      label={
                        <div style={{ zIndex: 1 }}>
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          No. Ext.
                        </div>
                      }
                      value={formExt}
                      onChange={setformExt}
                      inputtype={InputTypes.Integer}
                      disabled={DisableForm}
                    />
                  </Col>

                  <Col flex="1 1 400px">
                    <TextFieldCustom
                      inputProps={{ maxLength: 10 }}
                      style={{ width: '100%', marginBottom: 10 }}
                      label={<div style={{ zIndex: 1 }}>No. Int.</div>}
                      value={formInt}
                      onChange={setformInt}
                      inputtype={InputTypes.Integer}
                      disabled={DisableForm}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            {/* ------------------------------------------------------------------------------------------------------------------------------  */}

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                marginTop: 10,
                marginBottom: 20,
              }}
            >
              <Row gutter={6} style={{ width: '90%' }}>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <Button
                    className="buttons-back-forward"
                    type="primary"
                    onClick={() => handleBack()}
                    icon={<ArrowLeftOutlined />}
                  >
                    Atrás
                  </Button>
                </Col>

                <Col span={12} style={{ marginLeft: 0, textAlign: 'left' }}>
                  <Button
                    className="buttons-back-forward"
                    type="primary"
                    onClick={() => handleNext('/datos_empresa')}
                    htmlType="submit"
                  >
                    Continuar <ArrowRightOutlined />
                  </Button>
                </Col>
              </Row>
            </div>
          </Skeleton>
        </div>
      </div>
      <Modal
        title={
          <div>
            <MailOutlined /> &nbsp; &nbsp; &nbsp; Solicitud de Autorización de
            Buró Enviada
          </div>
        }
        closable={false}
        open={isModalOpen}
        onCancel={handleModalClose}
        footer={[
          <Button
            danger
            style={{ display: estadoAval == 0 ? '' : 'none' }}
            type="primary"
            onClick={() => {
              setisModalOpen(false);
              handleSkipView();
            }}
          >
            No reenviar Correo
          </Button>,
          <Button type="primary" onClick={handleModalClose}>
            {estadoAval == 0 ? 'Reenviar correo' : 'Continuar'}
          </Button>,
        ]}
      >
        <div style={{ textAlign: 'center' }}>
          Se ha enviado un correo para solicitar la autorización de consulta de
          Buró a su aval:
        </div>

        <Typography.Title
          level={5}
          style={{
            margin: 20,
            color: '#081b3e',
            marginBottom: -10,
            textAlign: 'center',
            marginBottom: 5,
          }}
        >
          {NombreCompletoAval}
        </Typography.Title>

        <div style={{ textAlign: 'center' }}> {valueRFC} </div>

        <div style={{ textAlign: 'center' }}>{formCorreo}</div>

        <div style={{ textAlign: 'center', marginTop: 20 }}>
          Una vez recibamos la autorización del aval, podrá continuar con el
          proceso de su solicitud.
        </div>
      </Modal>
    </div>
  );
};

export default DatosAval;
