import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import RfcInput from '../../componentes/InputRfc';
import '../Welcome.css';
import {
  Checkbox,
  Typography,
  Button,
  Select,
  Input,
  Form,
  Row,
  Col,
  Divider,
  Skeleton,
  InputNumber,
  Slider,
  Radio,
  Tooltip,
  Modal,
  Upload,
  Table
} from 'antd';
import {
  PlusOutlined,
  UserOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { FaSignature, FaFile } from 'react-icons/fa6';

import {
  MenuItem,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  TextField,
  Autocomplete,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import InputCustom from '../../componentes/InputCustom';
import { ComponentesContext } from '../../contextos/componentesContext';

import AuthService from '../../services/auth.service';
import FormulariosService from '../../services/formularios.Service';

import AvisoPrivacidad from '../../componentes/AvisoPrivacidad';
import TerminosCondiciones from '../../componentes/Condiciones';

import RefreshAvalTypeClass from "../../clases/refreshAvalType.class";

const PendientesAval = () => {
  const URL = process.env.REACT_APP_URL_API_EXPRESS;
  const {
    UpdateFooterText,
    ModalMessage,
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    loading,
    UpdatecurrentStep,
    InputTypes,
    UpdateFlagtimeLineForm,
    UpdatemovilSteps,
    ShowLoading,
    CloseSession,
    RfcTypes,
    UpdateFlagtimeLineFormMovil,
    UpdateFlagtimeLineFormProductos,
    pendientesAval,
    UpdateFlagtimeLineFormAval
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  



  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 190,
    heightImage: window.innerHeight - 185,
    heightScroll: (window.innerHeight * 54) / 100,
  });

  const [disabledOkButton, setdisabledOkButton] = useState(true);
  
  const [isModalOpen, setisModalOpen] = useState(true);

  const [isModalCondicionesOpen, setisModalCondicionesOpen] = useState(false);

  const [isModalConfirmacionOpen, setisModalConfirmacionOpen] = useState(false);

  const [tipoModal, settipoModal] = useState('condiciones');

  const [DataLista, setDataLista] = useState([]);
  

  

  useEffect(() => {
    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      //console.log('entro');
      AuthService.logout();

      navigate('/login', {
        state: { record: { correo: '' } },
      });
    } else {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 190,
          heightImage: window.innerHeight - 185,
          heightScroll: (window.innerHeight * 54) / 100,
        });
      };

      InicializaFormulario();
      UpdateFlagtimeLineFormMovil(false);
      UpdateFlagtimeLineForm(false);
      UpdateFlagtimeLineFormAval(false);
      UpdateFlagtimeLineFormProductos(false);
      UpdatebuttonIngresar(
        'dashboard',
        usr.primerNombre + ' ' + usr.primerApellido
      );
      UpdateuserAvatar('dashboard');
      UpdatecurrentStep(9);
      UpdatemovilSteps(7);
      UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');

      setTimeout(function () {
        form.setFieldsValue({
          inversionista: {
            codRegFiscal: 1,
          },
        });
      }, 0);

      window.addEventListener('resize', handleResize);
      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const InicializaFormulario = async () => {
    ShowLoading(true);

    let res = await RefreshAvalTypeClass.RefreshAvalType();

    let respuestaAval = await FormulariosService.getSolicitudesPendientesAval();

    if (respuestaAval.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      }
      return false;
    }

    //console.log(respuestaAval.data.length);

    if (respuestaAval.data.length == 0) {
      navigate('/dashboard');
      ShowLoading(false);
      return false;
    }

    setDataLista(respuestaAval.data)

    

    ShowLoading(false);
  };

  const handleContinuarFirmas = async (idSolicitud) => {
    
    //console.log(idSolicitud);

    let respuesta = await FormulariosService.getValidaCapturaFirma(
      idSolicitud,
      'aval'
    );

    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      }
      return false;
    }

    if (respuesta.data.estado == 1 || respuesta.data.estado == 3) {


      let respuestaBuro = await FormulariosService.getValidaCapturaFirmaBuro(
        idSolicitud,
        'aval'
      );

      if (respuestaBuro.result != true) {
        ShowLoading(false);
        let respuestaSesion = await AuthService.getValidaSesion();
  
        if (respuestaSesion.result == false) {
          CloseSession();
        } else {
          ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
        }
        return false;
      }

      if (respuestaBuro.data.estado != 0) {
        navigate('/revision_aval', {
          state: { record: { solicitudId: idSolicitud } },
        });
      }
      else {
        navigate('/firma_buro_aval', {
          state: { record: { solicitudId: idSolicitud } },
        });  
      }

      ShowLoading(false);
      return false;
    } else {
      navigate('/autorizacion_sat_aval', {
        state: { record: { solicitudId: idSolicitud } },
      });
    }

  }


  const handleSkipView = async () => {
    let respuesta = await FormulariosService.getValidaCapturaFirma(
      solicitudId,
      'cliente'
    );

    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      }
      return false;
    }

    

    //console.log("estado firma sat", respuesta.data.estado);

    if (respuesta.data.estado == 1 || respuesta.data.estado == 3) {


      let respuestaBuro = await FormulariosService.getValidaCapturaFirmaBuro(
        solicitudId,
        'cliente'
      );

      if (respuestaBuro.result != true) {
        ShowLoading(false);
        let respuestaSesion = await AuthService.getValidaSesion();
  
        if (respuestaSesion.result == false) {
          CloseSession();
        } else {
          ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
        }
        return false;
      }

      if (respuestaBuro.data.estado != 0) {
        navigate('/revision', {
          state: { record: { solicitudId: solicitudId } },
        });
      }
      else {
        navigate('/firma_buro', {
          state: { record: { solicitudId: solicitudId } },
        });  
      }

      

      ShowLoading(false);
      return false;
    } else {
      navigate('/autorizacion_sat', {
        state: { record: { solicitudId: solicitudId } },
      });
    }
  };

  
  const columns = [
    {
      title: "",
      dataIndex: "solicitudId",
      key: "solicitudId",
      align: "center",
      width: 180,
      render: (text) => (
        <div>
          <Button
            type="primary"
            onClick={ () => { handleContinuarFirmas(text) } }
          >
            Iniciar autorización
          </Button>
        </div>
      ),
    },
    {
      width: 100,
      title: "Folio",
      dataIndex: "solicitudId",
      key: "solicitudId",
      render: (text) => (
        <div>
          {text.toString().padStart(8, '0')}
        </div>
      ),
    },
    {

      title: "Nombre del Cliente",
      dataIndex: "nombrecliente",
      key: "nombrecliente",
    },
  ];

  return (
    <html>
      <div
        style={{
          backgroundColor: '#fff',
          paddingBottom: 50,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <div
          className="div-img-arrendamiento"
          style={{
            borderRadius: 25,
            boxShadow: '0px 0px 22px -10px rgba(0,0,0,0.75)',
            marginBottom: 0,
            background: '#fff',
            marginTop: 30,
            width: windowSize.width - 200,
            minHeight: windowSize.height + 20,
            minWidth: 330,
            paddingRight: 20,
            paddingLeft: 20,
          }}
        >
          <center>
            <Divider>
              <div className="title-steps-buro" style={{ color: '#4c4cbc', marginTop:50 }}>
                Autorizaciones pendientes
              </div>
            </Divider>
          </center>

          <center>
            <Table
              style={{ minWidth: 300, marginBottom:40 }}
              columns={columns}
              size="middle"
              dataSource={DataLista}
              pagination={false}
              scroll={{
                y: windowSize.height + 50,
                x: 500,
              }}
             
            />
          </center>
        </div>
      </div>

      
    </html>
  );
};

export default PendientesAval;
