import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import './Welcome.css';
import './Login.css';
import { Carousel, Typography, Button, Collapse, Input } from 'antd';
import { PlusOutlined, UserOutlined, LoginOutlined } from '@ant-design/icons';

import AuthService from '../services/auth.service';
import FormulariosService from '../services/formularios.Service';

import imagen1 from '../asset/images/doc.png';

import { ComponentesContext } from '../contextos/componentesContext';

const Login = () => {
  const {
    ModalMessage,
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    UpdateFlagtimeLineForm,
    UpdateUserSesion,
    usuarioSesion,
    UpdateFlagtimeLineFormProductos,
    UpdateFooterText,
    UpdateFlagMenuInicial,
    EmailIngresado,
    UpdatePendientesAval,
    UpdateFlagtimeLineFormAval
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const { Title } = Typography;
  const location = useLocation();

  const [formVisible, setformVisible] = useState(true);

  const [Correo, SetCorreo] = useState(
    typeof location.state.record.correo != 'undefined'
      ? location.state.record.correo != null
        ? location.state.record.correo
        : ''
      : ''
  );

  const [Contrasena, setContrasena] = useState('');

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight-100,
    heightImage: window.innerHeight - 185,
  });

  const contentStyle = {
    height: windowSize.height,
    minHeight: 400,
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
    borderRadius: 15,
  };
  
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight - 100,
        heightImage: window.innerHeight - 185,
      });
    };

    UpdatebuttonIngresar('login');
    UpdateuserAvatar('');
    UpdateFlagtimeLineForm(false);
    UpdateFlagtimeLineFormProductos(false);
    UpdateFlagtimeLineFormAval(false);
    UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');
    UpdateFlagMenuInicial(false);

    if (EmailIngresado != "") {
      SetCorreo(EmailIngresado);  
    }
    
    window.addEventListener('resize', handleResize);
    //window.addEventListener("scroll", handleScroll);
    return () => {
      //window.removeEventListener("scroll", handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleNext = (option) => {
    navigate(option);
  };

  const handleCorreo = async (e) => {
    SetCorreo(e.target.value);
  };

  const handleContrasena = async (e) => {
    setContrasena(e.target.value);
  };

  const handleLogin = async () => {
    var regEXCorreo = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;

    if (Correo == '') {
      ModalMessage('Mensaje', 'El correo no puede estar en blanco.');
      return false;
    }

    if (!regEXCorreo.test(Correo)) {
      ModalMessage(
        'Mensaje',
        'El correo no tiene un formato valido, por favor verifique.'
      );
      return false;
    }

    if (Contrasena == '') {
      ModalMessage('Mensaje', 'La contraseña no puede estar en blanco.');
      return false;
    }

    let respuesta = await AuthService.login(Correo, Contrasena);

    if (respuesta.estado == 200) {
      let usr = await AuthService.getCurrentUser();
      await UpdateUserSesion(usr);

      //console.log("usuario", usr);

      let respuestaAval = await FormulariosService.getSolicitudesPendientesAval();

      if (respuestaAval.data.length > 0) {

        UpdatePendientesAval(true);

        handleNext('/pendientes_firma');
        return false;
      }

      UpdatePendientesAval(false);
      
      let respuesta = await FormulariosService.getSolicitudes();

      if (respuesta.data.length > 0) {
        handleNext('/solicitudes_activas');
      } else {
        handleNext('/dashboard');
      }
    } else if (respuesta.estado == 300) {
      navigate('/codevalidation', {
        state: { record: { correo: Correo } },
      });
    } else {
      ModalMessage('Mensaje', 'El Usuario y/o Contraseña son incorrectos.');
    }
  };

  return formVisible ? (
    <div>
      <table
        width={'100%'}
        style={{
          borderRadius: 15,
          backgroundColor: '#ffffff',
          borderWidth: 0,
          borderStyle: 'none',
          marginTop: -25,
        }}
      >
        <tbody>
          <tr>
            <td style={{ width: 0 }}>
            <table
                className="div-panel-picture"
                cellPadding={0}
                cellSpacing={0}
                border={0}
                style={{height: windowSize.height}}
              >
                <tbody>
                  <tr>
                    <td style={{ paddingLeft: 20 }}>
                      <p>
                        <h5
                          style={{ textAlign: 'left', marginTop: -5, fontSize: 17 }}
                        >
                          Solicita tu Arrendamiento
                        </h5>
                      </p>
                      <p>
                        La precalificación es el primer paso de la solicitud
                        para una línea de arrendamiento.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: 'bottom' }}>
                      <img src={imagen1} style={{ width: 350 }}></img>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>

            <td style={{ verticalAlign: "top" }}>
              <div  style={{ backgroundColor: '#fff', marginTop: 100, marginLeft: 8 }}>
                <center>
                  <table
                    cellPadding={0}
                    cellSpacing={0}
                    style={{
                      width: '50%',
                      borderRadius: 15,
                      marginLeft: 0,
                    }}
                    border={0}
                    className='div-login-card'
                  >
                    <tbody>
                      <tr>
                        <td
                          colSpan={3}
                          style={{
                            textAlign: 'center',
                            
                            borderRadius: '15px 15px 0 0',
                            
                          }}
                        >
                          <p>
                            <Title
                              level={2}
                              style={{ marginTop: -5, color: '#4c4cbc' }}
                            >
                              Inicia sesión
                            </Title>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={3}
                          style={{
                            textAlign: 'center',
                            height: 50,
                            
                          }}
                        >
                          <Input
                            className="input-login"
                            value={Correo}
                            placeholder="Correo Electrónico"
                            onChange={handleCorreo}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td
                          colSpan={3}
                          style={{
                            textAlign: 'center',
                            height: 50,
                            
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <Input.Password
                            className="input-login"
                            placeholder="Ingrese la contraseña..."
                            onChange={handleContrasena}
                            onPressEnter={(e) => handleLogin()}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td
                          colSpan={3}
                          style={{
                            height: 50,
                            
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <center>
                            <div
                              className="input-login"
                              style={{ textAlign: 'right' }}
                            >
                              <a
                                style={{ color: '#4c4cbc' }}
                                onClick={() =>
                                  handleNext('/solicitud_cambio_contra')
                                }
                              >
                                ¿Olvidaste tu contraseña?
                              </a>
                            </div>
                          </center>
                        </td>
                      </tr>

                      <tr>
                        <td
                          colSpan={3}
                          style={{
                            textAlign: 'center',
                            height: 80,
                            
                            
                            borderRadius: '0px 0px 15px 15px',
                          }}
                        >
                          <Button
                            type="primary"
                            size={'default'}
                            style={{
                              backgroundColor: '#4c4cbc',
                              height: 40,
                              width: "90%",
                              fontWeight: 'bold',
                              boxShadow: '0px -1px 10px 0px rgba(0,0,0,0.20)',
                              marginBottom: 40
                            }}
                            onClick={() => handleLogin()}
                            className="account-welcome-btn-create"
                          >
                            {' Ingresar '}&nbsp;
                            <LoginOutlined style={{ fontSize: '18px' }} />
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                </center>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <></>
  );
};

export default Login;
