import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { ComponentesContext } from '../../../contextos/componentesContext';
import '../../Welcome.css';
import 'react-datepicker/dist/react-datepicker.css';
import './DatosClientes.css';

import {
  Typography,
  Button,
  Select,
  Form,
  Row,
  Col,
  Divider,
  Skeleton,
  Radio,
  Tooltip,
  Input,
  Segmented,
  Modal,
  ConfigProvider,
} from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { FaCircleQuestion, FaBuilding, FaUserTie } from 'react-icons/fa6';
import RfcInput from '../../../componentes/InputRfc';
import DatePickerCustom from '../../../componentes/DatePickerCustom';
import moment from 'moment';
import dayjs from 'dayjs';

import AuthService from '../../../services/auth.service';
import FormulariosService from '../../../services/formularios.Service';
import SepomexService from '../../../services/sepomex.Service';

import imagen1 from '../../../asset/images/INEVERTICAL.png';

import { getYear, getMonth, addDays } from 'date-fns';

import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

import {
  MenuItem,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  TextField,
  Autocomplete,
} from '@mui/material';

import InputCustom from '../../../componentes/InputCustom';
import RfcTextField from '../../../componentes/TextFieldRfc';
import TextFieldCustom from '../../../componentes/TextFieldCustom';
import DatePicker from 'react-datepicker';
import es from 'date-fns/locale/es';

const DatosClientes = () => {
  const inputRefMoral = useRef(null);
  const inputRefPFAE = useRef(null);

  const dateFormat = 'DD-MM-YYYY';
  const {
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    loading,
    UpdatecurrentStep,
    InputTypes,
    UpdateFlagtimeLineForm,
    UpdatemovilSteps,
    ShowLoading,
    ModalMessage,
    RfcTypes,
    CloseSession,
    UpdateFooterText,
    UpdateFlagMenuInicial,
    UpdateFlagtimeLineFormMovil,
    UpdateFlagtimeLineFormProductos,
    UpdateFlagtimeLineFormAval,
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();

  const [UserEmail, setUserEmail] = useState('');
  const [isModalOpen, setisModalOpen] = useState(false);

  const [solicitudId, SetsolicitudId] = useState(
    typeof location.state.record.solicitudId != 'undefined'
      ? location.state.record.solicitudId != null
        ? location.state.record.solicitudId
        : '0'
      : '0'
  );

  const [DisableForm, setDisableForm] = useState(false);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 190,
    heightImage: window.innerHeight - 185,
    heightScroll: (window.innerHeight * 70) / 100,
    Col1width: window.innerWidth - 600,
  });
  const [formclienteTipoRegimenFiscalId, setformclienteTipoRegimenFiscalId] =
    useState(1);
  const [PfTooltip, setPfTooltip] = useState('');
  const [PmTooltip, setPmTooltip] = useState('');

  const [PfDisabled, setPfDisabled] = useState(false);
  const [PmDisabled, setPmDisabled] = useState(false);

  const [PfaeisVisible, setPfaeisVisible] = useState(true);
  const [PmisVisible, setPmisVisible] = useState(false);

  const [listaPaises, setlistaPaises] = useState('');

  const [ingresoMensualAutoFocus, setingresoMensualAutoFocus] = useState('');

  const FormValidateMessages = {
    required: '${label} es requerido!',
    types: {
      email: '${label} no es valido!',
      number: '${label} no es un numero valido!',
    },
    number: {
      min: '${label} Debe de tener almenos ${min} caracteres!',
    },
    string: {
      min: '${label} Debe de tener almenos ${min} caracteres!',
    },
  };

  const [OptionsSexo, setOptionsSexo] = useState([
    {
      value: 1,
      label: <div className="lblOptionCombo">Femenino </div>,
    },
    {
      value: 2,
      label: <div className="lblOptionCombo">Masculino </div>,
    },
    {
      value: 3,
      label: <div className="lblOptionCombo">Indefinido </div>,
    },
  ]);

  const [OptionsNacionalidad, setOptionsNacionalidad] = useState([
    {
      value: 1,
      label: <div className="lblOptionCombo">Mexicana </div>,
    },
  ]);

  const [OptionsEstados, setOptionsEstados] = useState([
    {
      value: 'Sinaloa',
      label: <div className="lblOptionCombo">Sinaloa </div>,
    },
    {
      value: 'Sonora',
      label: <div className="lblOptionCombo">Sonora </div>,
    },
  ]);

  const [OptionsEstadosNacimiento, setOptionsEstadosNacimiento] = useState([
    {
      value: 'Sinaloa',
      label: <div className="lblOptionCombo">Sinaloa </div>,
    },
    {
      value: 'Sonora',
      label: <div className="lblOptionCombo">Sonora </div>,
    },
  ]);

  const [OptionsEstadosCivil, setOptionsEstadosCivil] = useState([
    {
      value: 1,
      label: <div className="lblOptionCombo">Soltero </div>,
    },
    {
      value: 2,
      label: <div className="lblOptionCombo">Casado </div>,
    },
    {
      value: 3,
      label: <div className="lblOptionCombo">Sin especificar </div>,
    },
  ]);

  const [isFocused, setIsFocused] = useState(false);

  const [valueRFC, setvalueRFC] = useState('');

  const [formPrimerNombre, setformPrimerNombre] = useState('');
  const [formSegundoNombre, setformSegundoNombre] = useState('');
  const [formPrimerApellido, setformPrimerApellido] = useState('');
  const [formSegundoApellido, setformSegundoApellido] = useState('');
  const [formSexo, setformSexo] = useState('');
  //const [formFechaNacimiento, setformFechaNacimiento] = useState('');
  const [formFechaNacimientoTimeZone, setformFechaNacimientoTimeZone] =
    useState(null);
  const [formFechaNacimiento, setformFechaNacimiento] = useState(null);
  const [formEstadoCivil, setformEstadoCivil] = useState('');
  const [formEstadoUbicacion, setformEstadoUbicacion] = useState('');
  const [formFolioIne, setformFolioIne] = useState('');
  const [formCorreo, setformCorreo] = useState('');
  const [formCelular, setformCelular] = useState('');
  const [formCurp, setformCurp] = useState('');
  const [formRazonSocial, setformRazonSocial] = useState('');

  const [formNacionalidad, setformNacionalidad] = useState('');
  const [autocompleteLista, setautocompleteLista] = useState([]);

  const [formPaisNacimiento, setformPaisNacimiento] = useState('');
  const [formEstadoNacimiento, setformEstadoNacimiento] = useState({
    value: null,
  });

  const [formRepresentantePrimerNombre, setformRepresentantePrimerNombre] =
    useState('');
  const [formRepresentanteSegundoNombre, setformRepresentanteSegundoNombre] =
    useState('');
  const [formRepresentantePrimerApellido, setformRepresentantePrimerApellido] =
    useState('');
  const [
    formRepresentanteSegundoApellido,
    setformRepresentanteSegundoApellido,
  ] = useState('');

  const [formIngresoMensual, setformIngresoMensual] = useState('');

  useEffect(() => {
    let usr = AuthService.getCurrentUser();

    if (window.innerWidth > 1350) {
      setWindowSize({
        Col1width: window.innerWidth - 600,
        width: window.innerWidth,
        height: window.innerHeight - 200,
        heightImage: window.innerHeight - 185,
        heightScroll: (window.innerHeight * 51) / 100,
      });
    } else if (window.innerWidth < 1038) {
      setWindowSize({
        Col1width: window.innerWidth - 330,
        width: window.innerWidth,
        height: window.innerHeight - 200,
        heightImage: window.innerHeight - 185,
        heightScroll: (window.innerHeight * 51) / 100,
      });
    } else {
      setWindowSize({
        Col1width: window.innerWidth - 350,
        width: window.innerWidth,
        height: window.innerHeight - 200,
        heightImage: window.innerHeight - 185,
        heightScroll: (window.innerHeight * 51) / 100,
      });
    }

    if (window.innerWidth <= 600) {
      setWindowSize({
        Col1width: window.innerWidth - 600,
        width: window.innerWidth + 150,
        height: window.innerHeight - 200,
        heightImage: window.innerHeight - 185,
        heightScroll: (window.innerHeight * 51) / 100,
      });
    }

    if (usr === null || usr === undefined) {
      //console.log('entro');
      AuthService.logout();

      navigate('/login', {
        state: { record: { correo: '' } },
      });
    } else {
      setUserEmail(usr.email);
      const handleResize = () => {
        //console.log(window.innerWidth);
        if (window.innerWidth > 1350) {
          setWindowSize({
            Col1width: window.innerWidth - 600,
            width: window.innerWidth,
            height: window.innerHeight - 200,
            heightImage: window.innerHeight - 185,
            heightScroll: (window.innerHeight * 51) / 100,
          });
        } else if (window.innerWidth < 1038) {
          setWindowSize({
            Col1width: window.innerWidth - 330,
            width: window.innerWidth,
            height: window.innerHeight - 200,
            heightImage: window.innerHeight - 185,
            heightScroll: (window.innerHeight * 51) / 100,
          });
        } else {
          setWindowSize({
            Col1width: window.innerWidth - 350,
            width: window.innerWidth,
            height: window.innerHeight - 200,
            heightImage: window.innerHeight - 185,
            heightScroll: (window.innerHeight * 51) / 100,
          });
        }

        if (window.innerWidth <= 600) {
          setWindowSize({
            Col1width: window.innerWidth - 600,
            width: window.innerWidth + 150,
            height: window.innerHeight - 200,
            heightImage: window.innerHeight - 185,
            heightScroll: (window.innerHeight * 51) / 100,
          });
        }
      };
      InicializaFormulario();

      UpdateFlagtimeLineFormMovil(true);
      UpdateFlagtimeLineFormProductos(true);
      UpdateFlagtimeLineFormAval(false);
      UpdatecurrentStep(1);
      UpdateFlagtimeLineForm(true);
      UpdatebuttonIngresar(
        'dashboard',
        usr.primerNombre + ' ' + usr.primerApellido
      );
      UpdateuserAvatar('dashboard');

      UpdatemovilSteps(1);
      UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');

      UpdateFlagMenuInicial(false);
      setTimeout(function () {
        form.setFieldsValue({
          inversionista: {
            codRegFiscal: 1,
          },
        });
      }, 0);

      window.addEventListener('resize', handleResize);
      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  // 01   INICIALIZA EL FORMULARIO----------------------------------------------------------------------------------------
  const InicializaFormulario = async () => {
    ShowLoading(true);

    // let respuestaEstados = await SepomexService.getEstadosSepomex();

    // const Estados = respuestaEstados.data.map(item => ({
    //   value: item.id.toString(), // Convierte el ID a una cadena
    //   label: item.nombre,
    // }));

    var respuesta = {};
    if (solicitudId == 0) {
      ShowLoading(false);
      ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      return false;
    } else {
      respuesta = await FormulariosService.getInformacionCliente(solicitudId);
    }

    let usrName = AuthService.getCurrentUser();

    //console.log(respuesta);
    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      }
      return false;
    }

    if (respuesta.data.length == 0) {
      ShowLoading(false);
      return false;
    }

    //BLOQUEA FORMULARIO
    setDisableForm(!respuesta.data.habilitado);
    if (!respuesta.data.habilitado == true) {
      setPmDisabled(true);
      setPfDisabled(true);
    }

    const optionestadoCivilList = respuesta.data.estadoCivilList.map(
      (item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: (
          <Tooltip placement="right" title={item.nombre}>
            <div className="lblOptionCombo">{item.nombre}</div>
          </Tooltip>
        ),
      })
    );
    setOptionsEstadosCivil(optionestadoCivilList);

    const optionestadoUbicacionList = respuesta.data.estadoUbicacionList.map(
      (item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.nombre}</div>,
        nombre: item.nombre,
      })
    );
    setOptionsEstados(optionestadoUbicacionList);

    const optionnacionalidadList = respuesta.data.nacionalidadList.map(
      (item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div style={{ textAlign: 'left' }}>{item.nombre}</div>,
        nombre: item.nombre,
      })
    );

    setlistaPaises(optionnacionalidadList);
    setOptionsNacionalidad(optionnacionalidadList);

    if (
      respuesta.data.clientePaisNacimientoId == 0 ||
      respuesta.data.clientePaisNacimientoId == null ||
      respuesta.data.clientePaisNacimientoId == 142
    ) {
      setOptionsEstadosNacimiento(optionestadoUbicacionList);
    } else {
      const filteredData = optionnacionalidadList.filter(
        (item) => item.value === respuesta.data.clientePaisNacimientoId
      );

      setOptionsEstadosNacimiento([
        {
          value: 999, // Convierte el ID a una cadena
          label: <div className="lblOptionCombo">{filteredData[0].nombre}</div>,
          nombre: filteredData[0].nombre,
        },
      ]);
    }

    const ListaSexoNuevoOrden = [
      {
        id: 2,
        nombre: 'Masculino',
        descripcion: '',
        status: 'Activo',
      },
      {
        id: 1,
        nombre: 'Femenino',
        descripcion: '',
        status: 'Activo',
      },
      {
        id: 3,
        nombre: 'Indefinido',
        descripcion: '',
        status: 'Activo',
      },
    ];

    const optionsexoList = ListaSexoNuevoOrden.map((item) => ({
      value: item.id, // Convierte el ID a una cadena
      label: <div className="lblOptionCombo">{item.nombre}</div>,
    }));
    setOptionsSexo(optionsexoList);

    setvalueRFC(respuesta.data.clienteRfc);

    setformPrimerNombre(
      respuesta.data.clientePrimerNombre == ''
        ? usrName.primerNombre
        : respuesta.data.clientePrimerNombre
    );
    setformSegundoNombre(
      respuesta.data.clienteSegundoNombre == ''
        ? usrName.segundoNombre
        : respuesta.data.clienteSegundoNombre
    );
    setformPrimerApellido(
      respuesta.data.clientePrimerApellido == ''
        ? usrName.primerApellido
        : respuesta.data.clientePrimerApellido
    );
    setformSegundoApellido(
      respuesta.data.clienteSegundoApellido == ''
        ? usrName.segundoApellido
        : respuesta.data.clienteSegundoApellido
    );

    setformIngresoMensual(
      respuesta.data.ingresoMensual == 0 ? '' : respuesta.data.ingresoMensual
    );

    //console.log('sexo', respuesta.data.clienteSexoId);

    setformSexo(
      respuesta.data.clienteSexoId == 0 ? '' : respuesta.data.clienteSexoId
    );

    setformFechaNacimiento(respuesta.data.clienteFechaNacimiento);

    //console.log('Fecha nacimiento', respuesta.data.clienteFechaNacimiento);

    if (respuesta.data.clienteFechaNacimiento != '') {
      const fechaStr = respuesta.data.clienteFechaNacimiento;
      const [year, month, day] = fechaStr.split('-');

      // Crear un objeto de fecha
      const fechaformato = new Date(year, month - 1, day); // El mes en JavaScript es de 0 a 11, por eso se resta 1

      // Formatear la fecha según tu preferencia
      const options = {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        timeZone: 'America/Mexico_City',
      };
      const fechaFormateada = fechaformato.toLocaleString('en-US', options);

      //console.log('fecha formateada', fechaFormateada);
      setformFechaNacimientoTimeZone(fechaFormateada);
    }

    // setformFechaNacimiento(fechaFormateada);
    // var fecha = null;
    // if (respuesta.data.clienteFechaNacimiento != '') {
    //   fecha = dayjs(
    //     respuesta.data.clienteFechaNacimiento.substring(8, 10) +
    //       '/' +
    //       moment(new Date(respuesta.data.clienteFechaNacimiento)).format('MM') +
    //       '/' +
    //       moment(new Date(respuesta.data.clienteFechaNacimiento)).format(
    //         'YYYY'
    //       ),
    //     dateFormat
    //   );
    //   setformFechaNacimiento(fecha);
    // }

    // console.log("fecha final", fecha.format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (zz)") )

    setformEstadoCivil(
      respuesta.data.clienteEstadoCivilId == 0
        ? ''
        : respuesta.data.clienteEstadoCivilId
    );

    //ESTADO UBICACION--------------------------------------------------------------------------------------

    var arrEstadoUbicacion = {};
    if (respuesta.data.clienteEstadoUbicacionId != 0) {
      const filteredData = optionestadoUbicacionList.filter(
        (item) =>
          parseInt(item.value) ===
          parseInt(respuesta.data.clienteEstadoUbicacionId)
      );
      arrEstadoUbicacion = {
        value: respuesta.data.clienteEstadoUbicacionId, // Convierte el ID a una cadena
        label: (
          <div style={{ textAlign: 'left' }}>{filteredData[0].nombre}</div>
        ),
        nombre: filteredData[0].nombre,
      };
    }

    setformEstadoUbicacion(
      respuesta.data.clienteEstadoUbicacionId == 0 ? null : arrEstadoUbicacion
    );
    //---------------------------------------------------------------------------------------------------

    setformFolioIne(respuesta.data.clienteFolioIdIne);

    setformCorreo(
      respuesta.data.clienteEmail == ''
        ? usrName.email
        : respuesta.data.clienteEmail
    );
    setformCelular(respuesta.data.clienteCelular);
    setformCurp(respuesta.data.clienteCurp);
    setformRazonSocial(respuesta.data.clienteRazonSocial);

    //NACIONALIDAD----------------------------------------------------------------------------------------
    var arrNacionalidad = {};
    if (respuesta.data.clienteNacionalidadId == 0) {
      arrNacionalidad = {
        value: 142, // Convierte el ID a una cadena
        label: <div style={{ textAlign: 'left' }}>Mexico</div>,
        nombre: 'México',
      };
    } else {
      const filteredData = optionnacionalidadList.filter(
        (item) => item.value === respuesta.data.clientePaisNacimientoId
      );
      arrNacionalidad = {
        value: respuesta.data.clienteNacionalidadId, // Convierte el ID a una cadena
        label: (
          <div style={{ textAlign: 'left' }}>{filteredData[0].nombre}</div>
        ),
        nombre: filteredData[0].nombre,
      };
    }

    setformNacionalidad(arrNacionalidad);
    //---------------------------------------------------------------------------------------------------

    //PAIS NACIMIENTO------------------------------------------------------------------------------------
    var arrPaisNacimiento = {};
    if (respuesta.data.clientePaisNacimientoId != 0) {
      const filteredData = optionnacionalidadList.filter(
        (item) =>
          parseInt(item.value) ===
          parseInt(respuesta.data.clientePaisNacimientoId)
      );
      arrPaisNacimiento = {
        value: respuesta.data.clientePaisNacimientoId, // Convierte el ID a una cadena
        label: (
          <div style={{ textAlign: 'left' }}>{filteredData[0].nombre}</div>
        ),
        nombre: filteredData[0].nombre,
      };
    }

    setformPaisNacimiento(
      respuesta.data.clientePaisNacimientoId == 0
        ? {
            value: 142, // Convierte el ID a una cadena
            label: <div style={{ textAlign: 'left' }}>Mexico</div>,
            nombre: 'México',
          }
        : arrPaisNacimiento
    );
    //---------------------------------------------------------------------------------------------------

    //PAIS NACIMIENTO------------------------------------------------------------------------------------

    //console.log(respuesta.data.clienteEstadoNacimientoId);
    var arrEstadoNacimiento = {};
    if (
      respuesta.data.clienteEstadoNacimientoId != 0 &&
      respuesta.data.clienteEstadoNacimientoId != null
    ) {
      const filteredData = optionestadoUbicacionList.filter(
        (item) =>
          parseInt(item.value) ===
          parseInt(respuesta.data.clienteEstadoNacimientoId)
      );
      arrEstadoNacimiento = {
        value: respuesta.data.clienteEstadoNacimientoId, // Convierte el ID a una cadena
        label: (
          <div style={{ textAlign: 'left' }}>{filteredData[0].nombre}</div>
        ),
        nombre: filteredData[0].nombre,
      };
    }

    setformEstadoNacimiento(
      respuesta.data.clienteEstadoNacimientoId == 0
        ? null
        : respuesta.data.clienteEstadoNacimientoId == null
        ? null
        : arrEstadoNacimiento
    );
    //---------------------------------------------------------------------------------------------------

    setformRepresentantePrimerNombre(
      respuesta.data.clientePrimerNombre == ''
        ? usrName.primerNombre
        : respuesta.data.clientePrimerNombre
    );
    setformRepresentanteSegundoNombre(
      respuesta.data.clienteSegundoNombre == ''
        ? usrName.segundoNombre
        : respuesta.data.clienteSegundoNombre
    );
    setformRepresentantePrimerApellido(
      respuesta.data.clientePrimerApellido == ''
        ? usrName.primerApellido
        : respuesta.data.clientePrimerApellido
    );
    setformRepresentanteSegundoApellido(
      respuesta.data.clienteSegundoApellido == ''
        ? usrName.segundoApellido
        : respuesta.data.clienteSegundoApellido
    );

    //console.log('sexo', respuesta.data.clienteSexoId);

    // setTimeout(function () {
    //   form.setFieldsValue({
    //     datosClientes: {
    //       PrimerNombre: respuesta.data.clientePrimerNombre,
    //       SegundoNombre: respuesta.data.clienteSegundoNombre,
    //       ApellidoPaterno: respuesta.data.clientePrimerApellido,
    //       ApellidoMaterno: respuesta.data.clienteSegundoApellido,
    //       Nacionalidad:
    //         respuesta.data.clienteNacionalidadId == 0
    //           ? null
    //           : respuesta.data.clienteNacionalidadId,
    //       Sexo:
    //         respuesta.data.clienteSexoId == 0
    //           ? null
    //           : respuesta.data.clienteSexoId,
    //       FechaNacimiento: fecha,
    //       EstadoCivil:
    //         respuesta.data.clienteEstadoCivilId == 0
    //           ? null
    //           : respuesta.data.clienteEstadoCivilId,
    //       EstadoUbicacion:
    //         respuesta.data.clienteEstadoUbicacionId == 0
    //           ? null
    //           : respuesta.data.clienteEstadoUbicacionId.toString(),
    //       FolioIne: respuesta.data.clienteFolioIdIne,
    //       Correo:
    //         respuesta.data.clienteEmail == ''
    //           ? UserEmail
    //           : respuesta.data.clienteEmail,
    //       Celular: respuesta.data.clienteCelular,
    //       Curp: respuesta.data.clienteCurp,
    //       RazonSocial: respuesta.data.clienteRazonSocial,
    //       RepresentantePrimerNombre: respuesta.data.clientePrimerNombre,
    //       RepresentanteSegundoNombre: respuesta.data.clienteSegundoNombre,
    //       RepresentantePrimerApellido: respuesta.data.clientePrimerApellido,
    //       RepresentanteSegundoApellido: respuesta.data.clienteSegundoApellido,
    //       RFC: respuesta.data.clienteRfc,
    //       codRegFiscal:
    //         respuesta.data.clienteTipoRegimenFiscalId == 0
    //           ? 1
    //           : respuesta.data.clienteTipoRegimenFiscalId,
    //       PaisNacimiento:
    //         respuesta.data.clientePaisNacimientoId == 0 ||
    //         respuesta.data.clientePaisNacimientoId == null
    //           ? 142
    //           : respuesta.data.clientePaisNacimientoId,
    //       EstadoNacimiento:
    //         respuesta.data.clientePaisNacimientoId == null
    //           ? null
    //           : respuesta.data.clienteEstadoNacimientoId == 0
    //           ? null
    //           : respuesta.data.clienteEstadoNacimientoId == null
    //           ? 999
    //           : respuesta.data.clienteEstadoNacimientoId.toString(),
    //     },
    //   });
    // }, 0);

    if (
      respuesta.data.clienteTipoRegimenFiscalId == 0 ||
      respuesta.data.clienteTipoRegimenFiscalId == 1
    ) {
      setformclienteTipoRegimenFiscalId(1);
      setPfaeisVisible(true);
      setPmisVisible(false);
    } else {
      setformclienteTipoRegimenFiscalId(
        respuesta.data.clienteTipoRegimenFiscalId
      );
      setPfaeisVisible(false);
      setPmisVisible(true);
    }

    ShowLoading(false);
  };
  // ----------------------------------------------------------------------------------------------------------------

  const handleNext = async (option) => {
    //console.log('fecha nacimiento', formFechaNacimiento);

    //console.log('es pfae', PfaeisVisible);

    var regEXRFC =
      /^[A-Za-zñÑ&]{1,2}([A-Za-zñÑ&]([A-Za-zñÑ&](\d(\d(\d(\d(\d(\d(\w(\w(\w)?)?)?)?)?)?)?)?)?)?)?$/;

    var regEXCURP =
      /^[A-Za-zñÑ&]{1,2}([A-Za-zñÑ&]([A-Za-zñÑ&](\d(\d(\d(\d(\d(\d(\w(\w(\w(\w(\w(\w(\w(\w)?)?)?)?)?)?)?)?)?)?)?)?)?)?)?)?$/;

    var regEXCorreo = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;

    setIsFocused(false);

    if (valueRFC.length == 0) {
      ModalMessage('Mensaje', 'El RFC no puede estar en blanco.');
      return false;
    }

    if (!regEXRFC.test(valueRFC)) {
      ShowLoading(false);
      ModalMessage(
        'Mensaje',
        'El RFC no tiene un formato valido, por favor verifique.'
      );
      return false;
    }

    if (PfaeisVisible == true) {
      if (valueRFC.length < 13) {
        ModalMessage(
          'Mensaje',
          'El RFC no tiene un formato valido, por favor verifique.'
        );
        return false;
      }

      if (formPrimerNombre == '') {
        ModalMessage('Mensaje', 'El Primer Nombre no puede estar en blanco.');
        return false;
      }

      // if (formSegundoNombre == "") {
      //   ModalMessage("Mensaje", "El Segundo Nombre no puede estar en blanco.");
      //   return false;
      // }

      if (formPrimerApellido == '') {
        ModalMessage('Mensaje', 'El Primer Apellido no puede estar en blanco.');
        return false;
      }

      // if (formSegundoApellido == "") {
      //   ModalMessage("Mensaje", "El Segundo Apellido no puede estar en blanco.");
      //   return false;
      // }

      if (formSexo == '') {
        ModalMessage('Mensaje', 'El Sexo no puede estar en blanco.');
        return false;
      }

      if (formFechaNacimiento == '') {
        ModalMessage(
          'Mensaje',
          'La fecha de nacimiento no puede estar en blanco'
        );
        return false;
      }

      if (formEstadoCivil == '') {
        ModalMessage('Mensaje', 'El Estado civil no puede estar en blanco.');
        return false;
      }

      if (formNacionalidad == '') {
        ModalMessage('Mensaje', 'La Nacionalidad no puede estar en blanco.');
        return false;
      }
      //console.log('nacionalidad', formEstadoUbicacion);

      if (
        formEstadoUbicacion == '' ||
        formEstadoUbicacion == 0 ||
        formEstadoUbicacion == null
      ) {
        ModalMessage('Mensaje', 'El Estado no puede estar en blanco.');
        return false;
      }

      if (formFolioIne == '') {
        ModalMessage('Mensaje', 'El Folio Ine no puede estar en blanco.');
        return false;
      }

      if (formFolioIne.length < 12) {
        ModalMessage(
          'Mensaje',
          'El Folio Ine no puede ser menor a 12 dígitos.'
        );
        return false;
      }

      if (formCorreo == '') {
        ModalMessage('Mensaje', 'El Correo no puede estar en blanco.');
        return false;
      }

      if (!regEXCorreo.test(formCorreo)) {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'El correo no tiene un formato valido, por favor verifique.'
        );
        return false;
      }

      if (formCelular == '') {
        ModalMessage('Mensaje', 'El Celular no puede estar en blanco.');
        return false;
      }

      if (formCelular.length < 10) {
        ModalMessage('Mensaje', 'El Celular no puede ser menor a 10 dígitos.');
        return false;
      }

      if (formCurp == '') {
        ModalMessage('Mensaje', 'La CURP no puede estar en blanco.');
        return false;
      }

      if (!regEXCURP.test(formCurp)) {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'La CURP no tiene un formato valido, por favor verifique.'
        );
        return false;
      }

      if (formCurp.length < 18) {
        ModalMessage(
          'Mensaje',
          'La CURP no tiene un formato valido, por favor verifique.'
        );
        return false;
      }

      if (formPaisNacimiento == null) {
        ModalMessage(
          'Mensaje',
          'El Pais de Nacimiento no puede estar en blanco.'
        );
        return false;
      }

      if (formEstadoNacimiento == null) {
        ModalMessage(
          'Mensaje',
          'El Estado de Nacimiento no puede estar en blanco.'
        );
        return false;
      }

      if (formIngresoMensual == '') {
        ModalMessage('Mensaje', 'El Ingreso Mensual no puede estar en blanco.');
        return false;
      }

      if (parseInt(formIngresoMensual) <= 0) {
        ModalMessage('Mensaje', 'El Ingreso Mensual debe ser mayor a $0.');
        return false;
      }
    } else {
      //console.log('entro moral');

      //console.log('formestado', formEstadoNacimiento);

      if (valueRFC.length < 12) {
        ModalMessage(
          'Mensaje',
          'El RFC no tiene un formato valido, por favor verifique.'
        );
        return false;
      }

      if (formRazonSocial == '') {
        ModalMessage('Mensaje', 'La Razón Social no puede estar en blanco.');
        return false;
      }

      if (formCorreo == '') {
        ModalMessage('Mensaje', 'El Correo no puede estar en blanco.');
        return false;
      }

      if (!regEXCorreo.test(formCorreo)) {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'El correo no tiene un formato valido, por favor verifique.'
        );
        return false;
      }

      if (
        formEstadoUbicacion == '' ||
        formEstadoUbicacion == 0 ||
        formEstadoUbicacion == null
      ) {
        ModalMessage('Mensaje', 'El Estado no puede estar en blanco.');
        return false;
      }

      if (formCelular == '') {
        ModalMessage('Mensaje', 'El Celular no puede estar en blanco.');
        return false;
      }

      if (formCelular.length < 10) {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'El celular de contacto no tiene un formato valido.'
        );
        return false;
      }

      if (formIngresoMensual == '') {
        ModalMessage('Mensaje', 'El Ingreso Mensual no puede estar en blanco.');
        return false;
      }

      if (parseInt(formIngresoMensual) <= 0) {
        ModalMessage('Mensaje', 'El Ingreso Mensual debe ser mayor a $0.');
        return false;
      }

      if (formNacionalidad == '') {
        ModalMessage('Mensaje', 'La Nacionalidad no puede estar en blanco.');
        return false;
      }

      // if (formPaisNacimiento == null) {
      //   ModalMessage(
      //     'Mensaje',
      //     'El Pais de Nacimiento no puede estar en blanco.'
      //   );
      //   return false;
      // }

      // if (formEstadoNacimiento == null) {
      //   ModalMessage(
      //     'Mensaje',
      //     'El Estado de Nacimiento no puede estar en blanco.'
      //   );
      //   return false;
      // }

      // if (formFechaNacimiento == "") {
      //   console.log("FECHA ENTRO",formFechaNacimiento);
      // }
      // else {
      //   console.log("FECHA NO ENTRO",formFechaNacimiento);
      // }

      if (formRepresentantePrimerNombre == '') {
        ModalMessage(
          'Mensaje',
          'El Primer Nombre del Representante Legal no puede estar en blanco.'
        );
        return false;
      }

      // if (formRepresentanteSegundoNombre == "") {
      //   ModalMessage("Mensaje", "El Segundo Nombre del Representante Legal no puede estar en blanco.");
      //   return false;
      // }

      if (formRepresentantePrimerApellido == '') {
        ModalMessage(
          'Mensaje',
          'El Primer Apellido del Representante Legal no puede estar en blanco.'
        );
        return false;
      }

      // if (formRepresentanteSegundoApellido == "") {
      //   ModalMessage("Mensaje", "El Segundo Apellido del Representante Legal no puede estar en blanco.");
      //   return false;
      // }
    }

    const json = {
      solicitudId: solicitudId,
      tipoRegimenFiscalId: PfaeisVisible ? 1 : 2,
      rfc: valueRFC,
      razonSocial: formRazonSocial,
      primerNombre: PfaeisVisible
        ? formPrimerNombre
        : formRepresentantePrimerNombre,
      segundoNombre: PfaeisVisible
        ? formSegundoNombre
        : formRepresentanteSegundoNombre,
      primerApellido: PfaeisVisible
        ? formPrimerApellido
        : formRepresentantePrimerApellido,
      segundoApellido: PfaeisVisible
        ? formSegundoApellido
        : formRepresentanteSegundoApellido,
      curp: formCurp,
      estadoUbicacionId: formEstadoUbicacion.value,
      email: formCorreo,
      celular: formCelular,
      sexoId: formSexo,
      fechaNacimiento:
        formFechaNacimiento == '' ? '1900-01-01' : formFechaNacimiento,
      estadoCivilId: formEstadoCivil,
      nacionalidadId: parseInt(formNacionalidad.value),
      folioIdIne: formFolioIne,
      paisNacimientoId: formPaisNacimiento.value,
      estadoNacimientoId:
        formEstadoNacimiento == null
          ? null
          : parseInt(formEstadoNacimiento.value) == 999
          ? null
          : parseInt(formEstadoNacimiento.value),
      ingresoMensual: parseInt(formIngresoMensual),
    };

    //console.log("JSON",json);
    ShowLoading(true);

    //console.log("disable form",DisableForm);
    if (DisableForm == false) {
      let respuesta =
        await FormulariosService.postGuardaInformacionCliente(json);

      //console.log("entro guardar");

      if (respuesta.result != true) {
        ShowLoading(false);
        ModalMessage('Error', 'Se produjo un error al guardar el formulario.');
        return false;
      }

      //console.log("Entro Guardar");
    }
    ShowLoading(false);

    navigate(option, {
      state: { record: { solicitudId: solicitudId } },
    });
  };

  const onFinish = () => {
    handleNext('/datos_empresa');
  };

  const removeAccents = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  const filterOption = (input, option) => {
    const normalizedInput = removeAccents(input).toLowerCase();
    const normalizedOption = removeAccents(option?.nombre).toLowerCase();

    return normalizedOption.includes(normalizedInput);
  };

  const handleBack = () => {
    navigate('/configurar_credito', {
      state: { record: { solicitudId: solicitudId } },
    });
  };

  const optionsDestino = [
    {
      value: 'Sinaloa',
      label: 'Sinaloa',
    },
    {
      value: 'Sonora',
      label: 'Sonora',
    },
  ];

  const handleInputCorreo = (e) => {
    //console.log(e);
  };

  const showConfirm = (e) => {
    //console.log(e.target.value);
    setvalueRFC('');

    // setTimeout(function () {
    //   form.setFieldsValue({
    //     datosClientes: {
    //       RFC: '',
    //     },
    //   });
    // }, 0);

    if (e.target.value == 1) {
      setPfaeisVisible(true);
      setPmisVisible(false);
    } else {
      setPfaeisVisible(false);
      setPmisVisible(true);
    }
  };

  const handleTipoClienteChange = (value) => {
    setvalueRFC('');

    setingresoMensualAutoFocus('');

    setformclienteTipoRegimenFiscalId(value);
    // setTimeout(function () {
    //   form.setFieldsValue({
    //     datosClientes: {
    //       RFC: '',
    //     },
    //   });
    // }, 0);

    if (value == 1) {
      setPfaeisVisible(true);
      setPmisVisible(false);
    } else {
      setPfaeisVisible(false);
      setPmisVisible(true);
    }
  };

  const handleChangePaisNacimiento = async (value) => {
    setformPaisNacimiento(value);

    let respuestaEstados = await SepomexService.getEstadosSepomex();

    const filteredData = listaPaises.filter(
      (item) => item.value === value.value
    );

    if (value.value == 142) {
      //console.log(respuestaEstados);

      const optionestadoNacimientoList = respuestaEstados.data.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.nombre}</div>,
        nombre: item.nombre,
      }));
      setOptionsEstadosNacimiento(optionestadoNacimientoList);
      setformEstadoNacimiento(null);

      // setTimeout(function () {
      //   form.setFieldsValue({
      //     datosClientes: {
      //       EstadoNacimiento: null,
      //     },
      //   });
      // }, 0);
    } else {
      setOptionsEstadosNacimiento([
        {
          value: 999, // Convierte el ID a una cadena
          label: <div className="lblOptionCombo">{filteredData[0].nombre}</div>,
          nombre: filteredData[0].nombre,
        },
      ]);

      // setTimeout(function () {
      //   form.setFieldsValue({
      //     datosClientes: {
      //       EstadoNacimiento: 999,
      //     },
      //   });
      // }, 0);

      setformEstadoNacimiento({
        value: 999, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{filteredData[0].nombre}</div>,
        nombre: filteredData[0].nombre,
      });
    }
  };

  const handleInfo = (e) => {};

  const handleNuevoInput = (e) => {
    //console.log(e.target.value);
  };

  const handleFechaNacimiento = (e) => {
    setformFechaNacimientoTimeZone(e);
    //console.log(e);
    const dateObject = new Date(e);

    // Obtiene los componentes de la fecha (año, mes, día)
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0 a 11
    const day = String(dateObject.getDate()).padStart(2, '0');

    // Construye la cadena en el formato deseado
    const formattedDate = `${year}-${month}-${day}`;

    //console.log(formattedDate);

    setformFechaNacimiento(formattedDate);
  };

  const getYear = (e) => {
    const dateObject = new Date(e);

    // Obtiene los componentes de la fecha (año, mes, día)
    const year = dateObject.getFullYear();

    return year;
  };

  const getMonth = (e) => {
    const dateObject = new Date(e);

    // Obtiene los componentes de la fecha (año, mes, día)
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');

    return month;
  };

  const [startDate, setStartDate] = useState(new Date());

  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = 1930; year <= currentYear; year++) {
    years.push(year);
  }

  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiempre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  const handleChangeTipoPersona = (e) => {
    //console.log(e);
  };

  const NumericFormatCustom = React.forwardRef(
    function NumericFormatCustom(props, ref) {
      const { onChange, ...other } = props;

      React.useImperativeHandle(ref, () => ({
        focus: () => {
          // logic to focus the rendered component from 3rd party belongs here
        },
        // hiding the value e.g. react-stripe-elements
      }));

      return (
        <NumericFormat
          {...other}
          getInputRef={ref}
          onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
          thousandSeparator
          valueIsNumericString
          prefix="$"
        />
      );
    }
  );

  NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  const handleIngresoMensual = (val) => {
    setingresoMensualAutoFocus('autoFocus');
    setformIngresoMensual(val);
  };

  const handleOnFocus = () => {
    setingresoMensualAutoFocus('');
    setIsFocused(false);
  };

  const handleOnFocusFecha = () => {
    setingresoMensualAutoFocus('');

    setIsFocused(true);
  };

  const handleBlurFecha = () => {
    setIsFocused(false);
  };

  const handleRfcBlur = (val) => {
    if (val.length == 13) {
      var strAnio = '';

      if (parseInt(val.substring(4, 6)) < 40) {
        strAnio = '20' + val.substring(4, 6);
      } else {
        strAnio = '19' + val.substring(4, 6);
      }

      //console.log(strAnio);

      //console.log(val.substring(6, 8));
      //console.log(val.substring(8, 10));

      const fechaStr =
        strAnio + '-' + val.substring(6, 8) + '-' + val.substring(8, 10);
      //console.log(fechaStr);
      const [year, month, day] = fechaStr.split('-');

      const fechaformato = new Date(year, month - 1, day); // El mes en JavaScript es de 0 a 11, por eso se resta 1

      const options = {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        timeZone: 'America/Mexico_City',
      };
      const fechaFormateada = fechaformato.toLocaleString('en-US', options);

      setformFechaNacimientoTimeZone(fechaFormateada);

      const dateObject = new Date(fechaFormateada);

      // Obtiene los componentes de la fecha (año, mes, día)
      const intyear = dateObject.getFullYear();
      const intmonth = String(dateObject.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0 a 11
      const intday = String(dateObject.getDate()).padStart(2, '0');

      // Construye la cadena en el formato deseado
      const formattedDate = `${intyear}-${intmonth}-${intday}`;

      //console.log(formattedDate);

      setformFechaNacimiento(formattedDate);
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgElevated: '#4c4cbc',
        },
      }}
    >
      <div
        style={{
          backgroundColor: '#fff',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          height: windowSize.height + 50,
          minHeight: 450,
          zIndex: 9999,
        }}
      >
        <div
          style={{
            borderRadius: 25,
            padding: 0,
            boxShadow: '0px 0px 22px -10px rgba(0,0,0,0.75)',
            background: '#fff',
            marginTop: 30,
            width: windowSize.width - 200,
            height: '100%',
            minWidth: 370,
            minHeight: 700,
          }}
        >
          <center>
            <Divider>
              <div
                className="title-steps"
                style={{ margin: 0, color: '#4c4cbc', marginTop: 0 }}
              >
                Información del Cliente
              </div>
            </Divider>
          </center>

          <Skeleton loading={loading} active={true}>
            <center>
              <Segmented
                options={[
                  {
                    label: (
                      <div className="div-segmented" style={{ fontSize: 20 }}>
                        <FaBuilding style={{ fontSize: 15 }} />
                        MORAL
                      </div>
                    ),
                    value: 2,
                  },
                  {
                    label: (
                      <div className="div-segmented" style={{ fontSize: 20 }}>
                        <FaUserTie style={{ fontSize: 15 }} />
                        PFAE
                      </div>
                    ),
                    value: 1,
                  },
                ]}
                onChange={(value) => {
                  //console.log(value); // string
                  handleTipoClienteChange(value);
                }}
                value={formclienteTipoRegimenFiscalId}
                disabled={DisableForm}
              />
            </center>

            <div
              style={{
                overflow: 'auto',
                maxHeight: 560,
                width: '99%',
                overflowX: 'hidden',
              }}
            >
              {/* ------------------------------------PFAE------------------------------------------------------------------------------------------  */}

              <div
                style={{
                  display: PfaeisVisible ? 'block' : 'none',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Row style={{ width: '90%', marginTop: 8 }}>
                    <Col span={24}>
                      <RfcTextField
                        autoFocus="autoFocus"
                        style={{ width: '100%', marginBottom: 10 }}
                        label={
                          <>
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            <label>RFC(Con Homoclave)</label>
                          </>
                        }
                        value={valueRFC}
                        onChange={setvalueRFC}
                        tipo={RfcTypes.Fisica}
                        onInput={(e) =>
                          (e.target.value = e.target.value.toUpperCase())
                        }
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                        onBlur={(e) => handleRfcBlur(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Row gutter={6} style={{ width: '90.5%' }}>
                    <Col flex="1 1 400px">
                      <TextFieldCustom
                        style={{ width: '100%', marginBottom: 10 }}
                        label={
                          <>
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            <label>Primer Nombre</label>
                          </>
                        }
                        value={formPrimerNombre}
                        onChange={setformPrimerNombre}
                        inputtype={InputTypes.Text}
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                      />
                    </Col>

                    <Col flex="1 1 400px" style={{ marginLeft: 0 }}>
                      <TextFieldCustom
                        style={{ width: '100%', marginBottom: 10 }}
                        label="Segundo Nombre"
                        value={formSegundoNombre}
                        onChange={setformSegundoNombre}
                        inputtype={InputTypes.Text}
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                      />
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Row gutter={6} style={{ width: '90.5%' }}>
                    <Col flex="1 1 400px">
                      <TextFieldCustom
                        style={{ width: '100%', marginBottom: 10 }}
                        label={
                          <>
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            <label>Apellido Paterno</label>
                          </>
                        }
                        value={formPrimerApellido}
                        onChange={setformPrimerApellido}
                        inputtype={InputTypes.Text}
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                      />
                    </Col>

                    <Col flex="1 1 400px" style={{ marginLeft: 0 }}>
                      <TextFieldCustom
                        style={{ width: '100%', marginBottom: 10 }}
                        label="Apellido Materno"
                        value={formSegundoApellido}
                        onChange={setformSegundoApellido}
                        inputtype={InputTypes.Text}
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                      />
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Row gutter={6} style={{ width: '90.5%' }}>
                    <Col flex="1 1 400px">
                      <FormControl style={{ width: '100%', marginBottom: 10 }}>
                        <InputLabel id="select-label">
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          <label>Sexo del cliente</label>
                        </InputLabel>
                        <MuiSelect
                          labelId="select-label"
                          id="select"
                          value={formSexo}
                          label={
                            <>
                              <label style={{ color: 'red', fontSize: 12 }}>
                                *
                              </label>{' '}
                              <label>Sexo del cliente</label>
                            </>
                          }
                          onChange={(e) => setformSexo(e.target.value)}
                          size="small"
                          disabled={DisableForm}
                          onFocus={handleOnFocus}
                        >
                          {OptionsSexo.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </MuiSelect>
                      </FormControl>
                    </Col>

                    <Col flex="1 1 400px">
                      <div
                        style={{
                          position: 'relative',
                          zIndex: isFocused ? 999999 : 'auto',
                        }}
                      >
                        <DatePicker
                          selected={formFechaNacimientoTimeZone}
                          onChange={(date) => handleFechaNacimiento(date)}
                          customInput={
                            <TextField
                              variant="outlined"
                              label={
                                <>
                                  <label style={{ color: 'red', fontSize: 12 }}>
                                    *
                                  </label>{' '}
                                  <label>Fecha de Nacimiento</label>
                                </>
                              }
                              size="small"
                              style={{ width: '100%', marginBottom: 10 }}
                            />
                          }
                          dateFormat="yyyy/MM/dd"
                          locale={es}
                          disabled={DisableForm}
                          onFocus={handleOnFocusFecha}
                          onBlur={handleBlurFecha}
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: 'flex',
                                justifyContent: 'center',
                                zIndex: 1,
                              }}
                            >
                              Año: &nbsp;{' '}
                              <select
                                value={getYear(date)}
                                onChange={({ target: { value } }) =>
                                  changeYear(value)
                                }
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                              &nbsp; Mes: &nbsp;{' '}
                              <select
                                value={months[getMonth(date)]}
                                onChange={({ target: { value } }) =>
                                  changeMonth(months.indexOf(value))
                                }
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                          utcOffset={new Date().getTimezoneOffset()}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Row gutter={6} style={{ width: '90.5%' }}>
                    <Col flex="1 1 400px">
                      <FormControl style={{ width: '100%', marginBottom: 10 }}>
                        <InputLabel id="select-label">
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          <label>Estado Civil</label>
                        </InputLabel>
                        <MuiSelect
                          labelId="select-label"
                          id="select"
                          value={formEstadoCivil}
                          label={
                            <>
                              <label style={{ color: 'red', fontSize: 12 }}>
                                *
                              </label>{' '}
                              <label>Estado Civil</label>
                            </>
                          }
                          disabled={DisableForm}
                          onFocus={handleOnFocus}
                          onChange={(e) => setformEstadoCivil(e.target.value)}
                          size="small"
                        >
                          {OptionsEstadosCivil.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </MuiSelect>
                      </FormControl>
                    </Col>

                    <Col flex="1 1 400px">
                      <Autocomplete
                        size="small"
                        style={{ marginBottom: 10 }}
                        value={formNacionalidad}
                        onChange={(event, newValue) =>
                          setformNacionalidad(newValue)
                        }
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                        options={OptionsNacionalidad}
                        getOptionLabel={(option) => option.nombre}
                        isOptionEqualToValue={(option, value) =>
                          option.nombre === value.nombre
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <>
                                <label style={{ color: 'red', fontSize: 12 }}>
                                  *
                                </label>{' '}
                                <label>Nacionalidad</label>
                              </>
                            }
                            placeholder="Nacionalidad"
                          />
                        )}
                        filterOption={(inputValue, option) =>
                          filterOption(inputValue, option)
                        }
                      />
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Row gutter={6} style={{ width: '90.5%' }}>
                    <Col flex="1 1 400px">
                      <Autocomplete
                        size="small"
                        style={{ marginBottom: 10 }}
                        value={formEstadoUbicacion}
                        onChange={(event, newValue) =>
                          setformEstadoUbicacion(newValue)
                        }
                        options={OptionsEstados}
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                        getOptionLabel={(option) => option.nombre}
                        isOptionEqualToValue={(option, value) =>
                          option.nombre === value.nombre
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <>
                                <label style={{ color: 'red', fontSize: 12 }}>
                                  *
                                </label>{' '}
                                <label>Estado</label>
                              </>
                            }
                            placeholder="Estado"
                          />
                        )}
                        filterOption={(inputValue, option) =>
                          filterOption(inputValue, option)
                        }
                      />
                    </Col>

                    <Col flex="1 1 400px">
                      <TextFieldCustom
                        inputProps={{ maxLength: 13 }}
                        style={{ width: '100%', marginBottom: 10 }}
                        label={
                          <>
                            <Tooltip
                              placement="up"
                              title={
                                <img className="img-ine" src={imagen1}></img>
                              }
                              overlayInnerStyle={{
                                width: 365,
                                marginTop: -100,
                              }}
                            >
                              <a
                                style={{ color: '#4c4cbc', fontSize: 15 }}
                                onClick={handleInfo}
                              >
                                <FaCircleQuestion />
                              </a>
                            </Tooltip>{' '}
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            <label>Folio ID INE</label>
                          </>
                        }
                        value={formFolioIne}
                        onChange={setformFolioIne}
                        onFocus={handleOnFocus}
                        inputtype={InputTypes.Integer}
                        disabled={DisableForm}
                      />
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Row gutter={6} style={{ width: '90.5%' }}>
                    <Col flex="1 1 400px">
                      <TextField
                        style={{ width: '100%', marginBottom: 10 }}
                        label={
                          <>
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            <label>Correo</label>
                          </>
                        }
                        value={formCorreo}
                        onChange={(e) => setformCorreo(e.target.value)}
                        size="small"
                        variant="outlined"
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                      />
                    </Col>

                    <Col flex="1 1 400px">
                      <TextFieldCustom
                        inputProps={{ maxLength: 10 }}
                        style={{ width: '100%', marginBottom: 10 }}
                        label={
                          <div style={{ zIndex: 1 }}>
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            Número Celular
                          </div>
                        }
                        value={formCelular}
                        onChange={setformCelular}
                        inputtype={InputTypes.Integer}
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                      />
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Row style={{ width: '90%' }}>
                    <Col span={24}>
                      <TextFieldCustom
                        style={{ width: '100%', marginBottom: 10 }}
                        label={
                          <>
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            <label>CURP</label>
                          </>
                        }
                        value={formCurp}
                        onChange={setformCurp}
                        inputtype={InputTypes.Curp}
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                      />
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Row gutter={6} style={{ width: '90.5%' }}>
                    <Col flex="1 1 400px">
                      <Autocomplete
                        size="small"
                        style={{ marginBottom: 10 }}
                        value={formPaisNacimiento}
                        onChange={(event, newValue) =>
                          handleChangePaisNacimiento(newValue)
                        }
                        options={OptionsNacionalidad}
                        getOptionLabel={(option) => option.nombre}
                        isOptionEqualToValue={(option, value) =>
                          option.nombre === value.nombre
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <>
                                <label style={{ color: 'red', fontSize: 12 }}>
                                  *
                                </label>{' '}
                                <label>País de Nacimiento</label>
                              </>
                            }
                            placeholder="País de Nacimiento"
                          />
                        )}
                        filterOption={(inputValue, option) =>
                          filterOption(inputValue, option)
                        }
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                      />
                    </Col>

                    <Col flex="1 1 400px">
                      <Autocomplete
                        size="small"
                        style={{ marginBottom: 10 }}
                        value={formEstadoNacimiento}
                        onChange={(event, newValue) =>
                          setformEstadoNacimiento(newValue)
                        }
                        options={OptionsEstadosNacimiento}
                        getOptionLabel={(option) => option.nombre}
                        isOptionEqualToValue={(option, value) =>
                          option.nombre === value.nombre
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <>
                                <label style={{ color: 'red', fontSize: 12 }}>
                                  *
                                </label>{' '}
                                <label>Estado de Nacimiento</label>
                              </>
                            }
                            placeholder="Estado de Nacimiento"
                          />
                        )}
                        filterOption={(inputValue, option) =>
                          filterOption(inputValue, option)
                        }
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                      />
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Row style={{ width: '90%' }}>
                    <Col span={24}>
                      <TextField
                        label={
                          <>
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            <label>Ingreso Mensual</label>
                          </>
                        }
                        value={formIngresoMensual}
                        onChange={(e) => handleIngresoMensual(e.target.value)}
                        autoFocus={ingresoMensualAutoFocus}
                        name="numberformat"
                        id="formatted-numberformat-input"
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                        }}
                        variant="outlined"
                        key="numberMonto"
                        size="small"
                        style={{ width: '100%' }}
                        disabled={DisableForm}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              {/* ------------------------------------------------------------------------------------------------------------------------------  */}

              {/* -----------------------MORAL--------------------------------------------------------------------------------------------------  */}

              <div
                style={{
                  display: PmisVisible ? 'block' : 'none',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Row style={{ width: '90%', marginTop: 8 }}>
                    <Col span={24}>
                      <RfcTextField
                        autoFocus="autoFocus"
                        style={{ width: '100%', marginBottom: 10 }}
                        label={
                          <>
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            <label>RFC(Con Homoclave)</label>
                          </>
                        }
                        value={valueRFC}
                        onChange={setvalueRFC}
                        tipo={RfcTypes.Moral}
                        onInput={(e) =>
                          (e.target.value = e.target.value.toUpperCase())
                        }
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                      />
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Row style={{ width: '90%' }}>
                    <Col span={24}>
                      <TextField
                        style={{ width: '100%', marginBottom: 10 }}
                        label={
                          <>
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            <label>Razón Social</label>
                          </>
                        }
                        value={formRazonSocial}
                        onChange={(e) => setformRazonSocial(e.target.value)}
                        size="small"
                        variant="outlined"
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                      />
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Row style={{ width: '90%' }}>
                    <Col span={24}>
                      <TextField
                        style={{ width: '100%', marginBottom: 10 }}
                        label={
                          <>
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            <label>Correo</label>
                          </>
                        }
                        value={formCorreo}
                        onChange={(e) => setformCorreo(e.target.value)}
                        size="small"
                        variant="outlined"
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                      />
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Row style={{ width: '90%' }}>
                    <Col span={24}>
                      <Autocomplete
                        size="small"
                        style={{ marginBottom: 10 }}
                        value={formNacionalidad}
                        onChange={(event, newValue) =>
                          setformNacionalidad(newValue)
                        }
                        options={OptionsNacionalidad}
                        getOptionLabel={(option) => option.nombre}
                        isOptionEqualToValue={(option, value) =>
                          option.nombre === value.nombre
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <>
                                <label style={{ color: 'red', fontSize: 12 }}>
                                  *
                                </label>{' '}
                                <label>Nacionalidad</label>
                              </>
                            }
                            placeholder="Nacionalidad"
                          />
                        )}
                        filterOption={(inputValue, option) =>
                          filterOption(inputValue, option)
                        }
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                      />
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Row gutter={6} style={{ width: '90.5%' }}>
                    <Col flex="1 1 400px">
                      <Autocomplete
                        size="small"
                        style={{ marginBottom: 10 }}
                        value={formEstadoUbicacion}
                        onChange={(event, newValue) =>
                          setformEstadoUbicacion(newValue)
                        }
                        options={OptionsEstados}
                        getOptionLabel={(option) => option.nombre}
                        isOptionEqualToValue={(option, value) =>
                          option.nombre === value.nombre
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <>
                                <label style={{ color: 'red', fontSize: 12 }}>
                                  *
                                </label>{' '}
                                <label>Estado Ubicación</label>
                              </>
                            }
                            placeholder="Estado Ubicación"
                          />
                        )}
                        filterOption={(inputValue, option) =>
                          filterOption(inputValue, option)
                        }
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                      />
                    </Col>

                    <Col flex="1 1 400px">
                      <TextFieldCustom
                        inputProps={{ maxLength: 10 }}
                        style={{ width: '100%', marginBottom: 10 }}
                        label={
                          <>
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            <label>Número Celular</label>
                          </>
                        }
                        value={formCelular}
                        onChange={setformCelular}
                        inputtype={InputTypes.Integer}
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                      />
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Row style={{ width: '90%' }}>
                    <Col span={24}>
                      <TextField
                        label={
                          <>
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            <label>Ingreso Mensual</label>
                          </>
                        }
                        value={formIngresoMensual}
                        onChange={(e) => handleIngresoMensual(e.target.value)}
                        autoFocus={ingresoMensualAutoFocus}
                        name="numberformat"
                        id="formatted-numberformat-input"
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                        }}
                        variant="outlined"
                        key="numberMonto"
                        size="small"
                        style={{ width: '100%' }}
                        disabled={DisableForm}
                      />
                    </Col>
                  </Row>
                </div>

                <Typography.Title
                  level={4}
                  style={{ marginTop: 5, marginLeft: -20 }}
                >
                  Representante Legal
                </Typography.Title>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Row gutter={6} style={{ width: '90.5%' }}>
                    <Col flex="1 1 400px">
                      <TextFieldCustom
                        style={{ width: '100%', marginBottom: 10 }}
                        label={
                          <>
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            <label>Primer Nombre</label>
                          </>
                        }
                        value={formRepresentantePrimerNombre}
                        onChange={setformRepresentantePrimerNombre}
                        inputtype={InputTypes.Text}
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                      />
                    </Col>

                    <Col flex="1 1 400px" style={{ marginLeft: 0 }}>
                      <TextFieldCustom
                        style={{ width: '100%', marginBottom: 10 }}
                        label="Segundo Nombre"
                        value={formRepresentanteSegundoNombre}
                        onChange={setformRepresentanteSegundoNombre}
                        inputtype={InputTypes.Text}
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                      />
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Row gutter={6} style={{ width: '90.5%' }}>
                    <Col flex="1 1 400px">
                      <TextFieldCustom
                        style={{ width: '100%', marginBottom: 10 }}
                        label={
                          <>
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            <label>Apellido Paterno</label>
                          </>
                        }
                        value={formRepresentantePrimerApellido}
                        onChange={setformRepresentantePrimerApellido}
                        inputtype={InputTypes.Text}
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                      />
                    </Col>

                    <Col flex="1 1 400px" style={{ marginLeft: 0 }}>
                      <TextFieldCustom
                        style={{ width: '100%', marginBottom: 10 }}
                        label="Apellido Materno"
                        value={formRepresentanteSegundoApellido}
                        onChange={setformRepresentanteSegundoApellido}
                        inputtype={InputTypes.Text}
                        disabled={DisableForm}
                        onFocus={handleOnFocus}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              {/* ------------------------------------------------------------------------------------------------------------------------------  */}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  marginTop: 10,
                }}
              >
                <Row gutter={6} style={{ width: '90%' }}>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <Button
                      className="buttons-back-forward"
                      type="primary"
                      onClick={() => handleBack()}
                      icon={<ArrowLeftOutlined />}
                    >
                      Atrás
                    </Button>
                  </Col>

                  <Col span={12} style={{ marginLeft: 0, textAlign: 'left' }}>
                    <Button
                      className="buttons-back-forward"
                      type="primary"
                      onClick={() => handleNext('/datos_empresa')}
                      htmlType="submit"
                    >
                      Continuar <ArrowRightOutlined />
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Skeleton>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default DatosClientes;
