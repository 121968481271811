import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import RfcInput from '../../../componentes/InputRfc';
import '../../Welcome.css';

import './AutorizacionBuro.css';
import {
  Checkbox,
  Typography,
  Button,
  Select,
  Input,
  Form,
  Row,
  Col,
  Divider,
  Skeleton,
  InputNumber,
  Slider,
  Radio,
  Tooltip,
  Modal,
  Upload,
} from 'antd';
import {
  PlusOutlined,
  UserOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { FaSignature, FaFile } from 'react-icons/fa6';

import {
  MenuItem,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  TextField,
  Autocomplete,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import InputCustom from '../../../componentes/InputCustom';
import { ComponentesContext } from '../../../contextos/componentesContext';

import AuthService from '../../../services/auth.service';
import FormulariosService from '../../../services/formularios.Service';
import returnValidUploadFileClass from '../../../clases/returnValidUploadFile.class';

import AvisoPrivacidad from '../../../componentes/AvisoPrivacidad';
import TerminosCondiciones from '../../../componentes/Condiciones';

const FirmaBuro = () => {
  const URL = process.env.REACT_APP_URL_API_EXPRESS;
  const {
    UpdateFooterText,
    ModalMessage,
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    loading,
    UpdatecurrentStep,
    InputTypes,
    UpdateFlagtimeLineForm,
    UpdatemovilSteps,
    ShowLoading,
    CloseSession,
    RfcTypes,
    UpdateFlagtimeLineFormMovil,
    UpdateFlagtimeLineFormProductos,
    UpdateFlagtimeLineFormAval,
    pendientesAval
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const { Title } = Typography;
  const [form] = Form.useForm();
  const dateFormat = 'DD-MM-YYYY';
  const location = useLocation();

  const formData = new FormData();
  const fileInputRef = useRef(null);
  const fileInputKeyRef = useRef(null);

  const [solicitudId, SetsolicitudId] = useState(
    typeof location.state.record.solicitudId != 'undefined'
      ? location.state.record.solicitudId != null
        ? location.state.record.solicitudId
        : '0'
      : '0'
  );

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 190,
    heightImage: window.innerHeight - 185,
    heightScroll: (window.innerHeight * 54) / 100,
  });

  const [showPassword, setShowPassword] = React.useState(false);

  const [valueRFC, setvalueRFC] = useState('');

  const [correoCliente, setcorreoCliente] = useState('');

  const [disabledOkButton, setdisabledOkButton] = useState(true);
  const [disabledContinueButton, setdisabledContinueButton] = useState(true);

  const [isModalOpen, setisModalOpen] = useState(true);

  const [isModalCondicionesOpen, setisModalCondicionesOpen] = useState(false);

  const [isModalConfirmacionOpen, setisModalConfirmacionOpen] = useState(false);

  const [tipoModal, settipoModal] = useState('condiciones');

  const [formTipoCredencial, setformTipoCredencial] = useState('1');
  const [ContrasenaActual, setContrasenaActual] = useState('');

  const [titleCertificado, settitleCertificado] = useState(
    'CARGAR: Certificado.cer'
  );
  const [titleKey, settitleKey] = useState('CARGAR: ARCHIVO_FIEL.key');

  const [fileCertificate, setfileCertificate] = useState(null);
  const [filePrivateKey, setfilePrivateKey] = useState(null);

  const [urlAnterior, seturlAnterior] = useState('/pendientes_firma');

  const contentStyle = {
    height: windowSize.height,
    minHeight: 400,
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
    borderRadius: 15,
  };

  const optionsTipoCredencial = [
    {
      value: '1',
      label: 'CIEC',
    },
    {
      value: '2',
      label: 'efirma',
    },
  ];

  useEffect(() => {
    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      //console.log('entro');
      AuthService.logout();

      navigate('/login', {
        state: { record: { correo: '' } },
      });
    } else {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 190,
          heightImage: window.innerHeight - 185,
          heightScroll: (window.innerHeight * 54) / 100,
        });
      };

      InicializaFormulario();

      UpdateFlagtimeLineFormMovil(false);
      UpdateFlagtimeLineForm(false);
      UpdateFlagtimeLineFormAval(true);
      UpdateFlagtimeLineFormProductos(false);
            
      UpdatebuttonIngresar(
        'dashboard',
        usr.primerNombre + ' ' + usr.primerApellido
      );
      UpdateuserAvatar('dashboard');
      UpdatecurrentStep(9);
      UpdatemovilSteps(7);
      UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');

      setTimeout(function () {
        form.setFieldsValue({
          inversionista: {
            codRegFiscal: 1,
          },
        });
      }, 0);

      window.addEventListener('resize', handleResize);
      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const InicializaFormulario = async () => {
    ShowLoading(true);
    var respuesta = {};
    var respuestaCliente = {};
    if (solicitudId == 0) {
      ShowLoading(false);
      ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      return false;
    } else {

      respuesta = await FormulariosService.getValidaCapturaFirmaBuro(
        solicitudId,
        'aval'
      );
      

      respuestaCliente =
        await FormulariosService.getInformacionCliente(solicitudId);
    }

    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      }
      return false;
    }


    if (respuesta.data.estado != 0) {
      //handleNext('/revision');
      ShowLoading(false);
      return false;
    }


    ShowLoading(false);

    setvalueRFC(respuestaCliente.data.clienteRfc);

    //console.log(respuestaCliente.data);
    setcorreoCliente(respuestaCliente.data.clienteEmail);
    

    seturlAnterior('/pendientes_firma');

    
  };

  const handleNext = (option) => {
    navigate(option, {
      state: { record: { solicitudId: solicitudId } },
    });
  };

  const handleGuardaFirmaBuro = async () => {
    ShowLoading(true);

    if (ContrasenaActual == '') {
      ModalMessage(
        'Mensaje',
        'Debe capturar la Contraseña para poder continuar.'
      );
      ShowLoading(false);
      return false;
    }

    let usr = AuthService.getCurrentUser();

    const JsonLogin = {
      email: usr.email,
      password: ContrasenaActual,
      productoId: '2',
    };

    //console.log(JsonLogin);

    let respuestaContra =
      await FormulariosService.postValidaContrasenaLogin(JsonLogin);

    //console.log(respuestaContra);

    if (respuestaContra.codigoError == '00001') {
      ShowLoading(false);
      ModalMessage('Error', 'La contraseña que ingreso es incorrecta.');
      return false;
    }

    const json = {
      solicitudId: solicitudId,
      cliente: false,
      ingresoNuevamenteNip: true,
      respuestaLeyendaAutorizacion: true,
    };

    let respuesta = await FormulariosService.postGuardarBuroCredito(json);

    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      }
      return false;
    }

    // console.log(json);

    handleNext('/revision_aval');
    ShowLoading(false);
  };

  const handleModalClose = () => {
    setisModalOpen(false);
  };

  const handleCheck = (val) => {
    //console.log(val.target.checked);
    setdisabledOkButton(!val.target.checked);
  };

  const handleCheckFirma = (val) => {
    //console.log(val.target.checked);
    setdisabledContinueButton(!val.target.checked);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleOpenModalCondiciones = (val) => {
    settipoModal(val);
    setisModalCondicionesOpen(true);
  };

  const handleBack = async(url) => {
    var respuesta = {};
    
    respuesta = await FormulariosService.getValidaCapturaFirma(solicitudId, 'aval');
    
    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      }
      return false;
    }

    if (respuesta.data.estado != 0) {

      handleNext('/pendientes_firma');
      
      ShowLoading(false);
      return false;
    }
    else {
      handleNext(url);  
    }
    
  }

  return (
    <html>
      <div
        style={{
          backgroundColor: '#fff',
          paddingBottom: 50,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          
        }}
      >
        <div
          className="div-img-arrendamiento"
          style={{
            borderRadius: 25,
            boxShadow: '0px 0px 22px -10px rgba(0,0,0,0.75)',
            marginBottom: 0,
            background: '#fff',
            marginTop: 30,
            width: windowSize.width - 200,
            minHeight: windowSize.height + 20,
            minWidth: 330,
            paddingRight: 20,
            paddingLeft: 20,
            
          }}
        >
          <center>
            <Divider>
              <div className="div-title-buro-aval" style={{ color: '#4c4cbc' }}>
                Autorización de Consulta de Buró
              </div>
            </Divider>
          </center>

          {/* <div
          style={{ overflow: 'auto', maxHeight: 400, width: '100%' }}
          id="mifiel-widget"
        ></div> */}

          <div style={{width:"100%"}}>
            <div
              className='div-buro-aval'
              style={{
                width: '100%',
                marginTop: 50,
                marginBottom: 30,
                textAlign: 'justify',
              }}
            >
              <Checkbox
                style={{ marginRight: 20 }}
                onChange={handleCheckFirma}
              ></Checkbox>
              Por este conducto autorizó expresamente a{' '}
              <b>ARRENDADORA FINAMO SA DE CV </b> para que por conducto de sus
              funcionarios facultados lleve a cabo investigaciones, sobre mi
              comportamiento crediticio en las sociedades de información
              crediticia que estime conveniente.
            </div>

            <div
              className='div-buro-aval'
              style={{
                width: '100%',
                marginBottom: 30,
                textAlign: 'justify',
              }}
            >
              Así mismo, declaro que conozco la naturaleza y alcance de la
              información que solicitará, del uso que{' '}
              <b>ARRENDADORA FINAMO SA DE CV </b>
              hará de tal información y de que ésta podrá realizar consultas
              periódicas de mi historial crediticio, consintiendo que esta
              autorización se encuentre vigente por un periodo de 3 años
              contando a partir de la fecha de su expedición y en todo caso
              durante el tiempo que mantengamos relación jurídica.
            </div>

            <Row gutter={6} style={{ width: '100%' }}>
              <Col flex="1 1 400px">
                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Ingrese nuevamente su contraseña de acceso
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    value={ContrasenaActual}
                    onChange={(e) => setContrasenaActual(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          //onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Ingrese nuevamente su contraseña de acceso"
                  />
                </FormControl>
              </Col>
            </Row>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              marginTop: 30,
              marginBottom: 20,
            }}
          >
            <Row gutter={6} style={{ width: '90%' }}>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Button
                  className="buttons-back-forward"
                  type="primary"
                  onClick={() => handleBack(urlAnterior)}
                  icon={<ArrowLeftOutlined />}
                >
                  Atrás
                </Button>
              </Col>

              <Col span={12} style={{ marginLeft: 0, textAlign: 'left' }}>
                <Button
                  className="buttons-back-forward"
                  type="primary"
                  onClick={() => handleGuardaFirmaBuro()}
                  htmlType="submit"
                  disabled={disabledContinueButton}
                >
                  Continuar <ArrowRightOutlined />
                </Button>
              </Col>
            </Row>
          </div>

          {/* <div
            className="div-buttons-steps-buro"
            style={{ marginTop: 45, paddingLeft: 20, marginBottom: -30 }}
          >
            <Button
              className="buttons-back-forward"
              type="primary"
              onClick={() => handleNext(urlAnterior)}
              icon={<ArrowLeftOutlined />}
              style={{ marginRight: 5 }}
            >
              Atrás
            </Button>
            <Button
              className="buttons-back-forward"
              type="primary"
              onClick={() => handleSave('/firma_buro')}
              htmlType="submit"
              style={{ marginLeft: 5 }}
            >
              Siguiente <ArrowRightOutlined />
            </Button>
          </div> */}
        </div>
      </div>

      <Modal
        title={
          <div>
            {/* <FaSignature style={{ fontSize: 20 }} /> &nbsp;&nbsp;&nbsp;&nbsp; */}
          </div>
        }
        closable={false}
        maskClosable={false}
        open={isModalOpen}
        onCancel={handleModalClose}
        footer={[
          <Button
            type="primary"
            danger
            onClick={() => setisModalConfirmacionOpen(true)}
          >
            Cancelar
          </Button>,
          <Button
            type="primary"
            onClick={handleModalClose}
            disabled={disabledOkButton}
          >
            Aceptar
          </Button>,
        ]}
        style={{minWidth:350}}
      >
        <div
          style={{
            fontSize: 18,
            width: '80%',
            marginTop: 30,
            marginBottom: 30,
            paddingRight: 20,
            display: "contents",
            justifyContent:"center"
          }}
        >
          <Checkbox
            style={{ marginRight: 20 }}
            onChange={handleCheck}
          ></Checkbox>
          Al continuar, aceptas nuestro{' '}
          <a onClick={() => handleOpenModalCondiciones('aviso')}>
            aviso de privacidad
          </a>{' '}
          y{' '}
          <a onClick={() => handleOpenModalCondiciones('condiciones')}>
            términos y condiciones
          </a>{' '}
          .
        </div>

        {/* <div>
          <Checkbox
            onChange={handleCheck}
            style={{
              textAlign: 'justify',
              width: 400,
              marginTop: 30,
              marginLeft: 40,
              marginBottom: 20,
            }}
          >
            <label style={{ backgroundColor: 'ffff' }}>
              Autorizo a Fínamo (Administradora de Soluciones, S.A. de C.V.,
              SOFOM, E.N.R./Arrendadora Fínamo, S.A. de C.V.) para consultar mi
              comportamiento en cualquier sociedad de información crediticia,
              inclusive de manera periódica. Declaro que conozco la información
              que se solicita, el uso y alcance que se le dará, por una vigencia
              de tres años o mientras dure nuestra relación.
            </label>
          </Checkbox>
        </div> */}
      </Modal>

      <Modal
        title={
          <div>
            {/* <FaSignature style={{ fontSize: 20 }} /> &nbsp;&nbsp;&nbsp;&nbsp; */}
          </div>
        }
        open={isModalCondicionesOpen}
        closable={false}
        maskClosable={false}
        onCancel={() => setisModalCondicionesOpen(false)}
        footer={[
          <Button
            type="primary"
            onClick={() => setisModalCondicionesOpen(false)}
          >
            Cerrar
          </Button>,
        ]}
        style={{ minWidth: '80%', minWidth:350 }}
      >
        {tipoModal == 'aviso' ? <AvisoPrivacidad /> : <TerminosCondiciones />}
      </Modal>

      <Modal
        title={<div>Confirmar.</div>}
        open={isModalConfirmacionOpen}
        closable={false}
        maskClosable={false}
        onCancel={() => setisModalConfirmacionOpen(false)}
        footer={[
          <Button
            type="primary"
            onClick={() => setisModalConfirmacionOpen(false)}
          >
            No
          </Button>,
          <Button
            type="primary"
            danger
            onClick={() => {
              setisModalConfirmacionOpen(false);
              navigate('/pendientes_firma');              
            }}
          >
            Si
          </Button>,
        ]}
        style={{minWidth:350}}
      >
        <div style={{ width: '90%' }}>
          Es necesario aceptar nuestro aviso de privacidad para continuar con el
          proceso: ¿Estas seguro que deseas cancelar?
        </div>
      </Modal>
    </html>
  );
};

export default FirmaBuro;
