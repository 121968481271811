import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import './Welcome.css';
import './Create.css';
import { Carousel, Typography, Button, Collapse, Input, Tooltip } from 'antd';
import {
  PlusOutlined,
  UserOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import UsuariosService from '../services/usuarios.Service';
import imagen1 from '../asset/images/doc.png';


import { ComponentesContext } from '../contextos/componentesContext';
import AuthService from '../services/auth.service';

const CreateAccount = () => {
  const {
    ModalMessage,
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    UpdateFlagtimeLineForm,
    ShowLoading,
    UpdateFooterText,
    UpdateFlagMenuInicial,
    EmailIngresado,
    UpdateFlagtimeLineFormProductos,
    UpdateFlagtimeLineFormAval
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const { Title } = Typography;

  const [primerNombre, setprimerNombre] = useState('');
  const [SegundoNombre, setSegundoNombre] = useState('');
  const [primerApellido, setprimerApellido] = useState('');
  const [segundoApellido, setsegundoApellido] = useState('');

  const [Correo, setCorreo] = useState('');
  const [Contrasena, setContrasena] = useState('');

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 100,
    heightImage: window.innerHeight - 185,
  });

  const contentStyle = {
    height: windowSize.height,
    minHeight: 400,
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
    borderRadius: 15,
  };

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    if (usr === null || usr === undefined) {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 100,
          heightImage: window.innerHeight - 185,
        });
      };

      UpdatebuttonIngresar('create');
      UpdateuserAvatar('');
      UpdateFlagtimeLineForm(false);
      UpdateFlagtimeLineFormAval(false);
      UpdateFooterText("© Fínamo 2023. Todos los derechos reservados.");
      UpdateFlagMenuInicial(false);
      UpdateFlagtimeLineFormProductos(false);

      if (EmailIngresado != "") {
        setCorreo(EmailIngresado);  
      }

      window.addEventListener('resize', handleResize);
      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    } else {
      navigate('/dashboard');
    }
  }, []);

  const handleNext = (option) => {
    navigate(option);
  };

  const handleCreate = async () => {
    var regEXCorreo = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
    var regexp_password =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#.])[A-Za-z\d$@$!%*?&#.]{8,15}/;

    if (primerNombre == '') {
      ModalMessage('Mensaje', 'El primer nombre no puede estar en blanco.');
      return false;
    }

    // if (SegundoNombre == "") {
    //   ModalMessage("Mensaje", "El segundo nombre no puede estar en blanco.");
    //   return false;
    // }

    if (primerApellido == '') {
      ModalMessage('Mensaje', 'El primer apellido no puede estar en blanco.');
      return false;
    }

    /*
    if (segundoApellido == "") {
      ModalMessage("Mensaje", "El segundo apellido no puede estar en blanco.");
      return false;
    }*/

    if (Correo == '') {
      ModalMessage('Mensaje', 'El correo no puede estar en blanco.');
      return false;
    }

    if (!regEXCorreo.test(Correo)) {
      ModalMessage(
        'Mensaje',
        'El correo no tiene un formato valido, por favor verifique.'
      );
      return false;
    }

    if (Contrasena == '') {
      ModalMessage('Mensaje', 'La contraseña no puede estar en blanco.');
      return false;
    }

    console.log("contraseña", Contrasena);
    if (!regexp_password.test(Contrasena)) {
      ModalMessage(
        'Mensaje',
        <>
          La contraseña debe tener las siguientes características:
          <div>• Mínimo 8 caracteres y Máximo 15</div>
          <div>• Al menos una letra mayúscula</div>
          <div>• Al menos una letra minúscula</div>
          <div>• Al menos un dígito</div>
          <div>• No espacios en blanco</div>
          <div>• Al menos 1 carácter especial</div>
        </>
      );
      return false;
    }

    ShowLoading(true);

    let CreaUsuario = await UsuariosService.postCrearUsuario({
      primerNombre: primerNombre,
      segundoNombre: SegundoNombre,
      primerApellido: primerApellido,
      segundoApellido: segundoApellido,
      email: Correo,
      password: Contrasena,
    });

    //console.log(CreaUsuario);

    ShowLoading(false);

    if (CreaUsuario.result) {
      navigate('/codevalidation', {
        state: { record: { correo: Correo } },
      });
    } else {
      ModalMessage('Mensaje', CreaUsuario.msg);
      return false;
    }
  };

  const handleCambioPrimerNombre = async (e) => {
    setprimerNombre(e.target.value);
  };

  const handleCambioSegundoNombre = async (e) => {
    setSegundoNombre(e.target.value);
  };

  const handleCambioPrimerApellido = async (e) => {
    setprimerApellido(e.target.value);
  };

  const handleCambioSegundoApellido = async (e) => {
    setsegundoApellido(e.target.value);
  };

  const handleCambioCorreo = async (e) => {
    setCorreo(e.target.value);
  };

  const handleCambioContrasena = async (e) => {
    setContrasena(e.target.value);
  };

  return (

    <div>
      <table
        width={'100%'}
        style={{
          borderRadius: 15,
          backgroundColor: '#ffffff',
          borderWidth: 0,
          borderStyle: 'none',
          marginTop: -25,
        }}
      >
        <tbody>
          <tr>
            <td style={{ width: 0 }}>
              <table
                className="div-panel-picture"
                cellPadding={0}
                cellSpacing={0}
                border={0}
                style={{height: windowSize.height}}
              >
                <tbody>
                  <tr>
                    <td style={{ paddingLeft: 20 }}>
                      <p>
                        <h5
                          style={{ textAlign: 'left', marginTop: -5, fontSize: 17 }}
                        >
                          Solicita tu Arrendamiento
                        </h5>
                      </p>
                      <p>
                        La precalificación es el primer paso de la solicitud
                        para una línea de arrendamiento.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: 'bottom' }}>
                      <img src={imagen1} style={{ width: 350 }}></img>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>

            <td style={{ verticalAlign: "top" }}>
              <center>
                <br></br>
            <Title level={2} style={{ textAlign: 'center', marginTop: 15 }}>
              Expertos en impulsar y transformar tu empresa
            </Title>
              <br></br>
              
              <table
                cellPadding={0}
                cellSpacing={0}
                style={{
                  borderRadius: 15,
                  marginLeft: 0,
                }}
                border={0}
                className='table-create'
              >
                <tbody>
                  <tr>
                    <td
                      colSpan={3}
                      style={{
                        textAlign: 'center',
                        //borderTop: 'solid #7272cb 1px',
                        borderRadius: '15px 15px 0 0',
                        //borderLeft: 'solid #7272cb 1px',
                        //borderRight: 'solid #7272cb 1px',
                      }}
                    >
                      <p>
                        <Title
                          level={2}
                          style={{ marginTop: -5, color: '#4c4cbc' }}
                        >
                          ¡Vamos a crear tu cuenta!
                        </Title>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: 'right',
                        height: 45,
                        //borderLeft: 'solid #7272cb 1px',
                      }}
                    >
                      <Input
                        className="input-create"
                        placeholder="Primer Nombre"
                        onChange={handleCambioPrimerNombre}
                      />
                    </td>
                    <td width={10}>&nbsp;</td>
                    <td
                      style={{
                        textAlign: 'left',
                        //borderRight: 'solid #7272cb 1px',
                      }}
                    >
                      <Input
                        className="input-create"
                        placeholder="Segundo Nombre"
                        onChange={handleCambioSegundoNombre}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: 'right',
                        height: 45,
                        //borderLeft: 'solid #7272cb 1px',
                      }}
                    >
                      <Input
                        className="input-create"
                        placeholder="Primer Apellido"
                        onChange={handleCambioPrimerApellido}
                      />
                    </td>
                    <td width={10}>&nbsp;</td>
                    <td
                      style={{
                        textAlign: 'left',
                        //borderRight: 'solid #7272cb 1px',
                      }}
                    >
                      <Input
                        className="input-create"
                        placeholder="Segundo Apellido"
                        onChange={handleCambioSegundoApellido}
                      />
                      <QuestionCircleOutlined style={{ color: '#fff' }} />
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={3}
                      style={{
                        textAlign: 'center',
                        height: 45,
                        //borderLeft: 'solid #7272cb 1px',
                        //borderRight: 'solid #7272cb 1px',
                      }}
                    >
                      <Input
                        className="input-create-one"
                          placeholder="Correo Electrónico"
                          value={Correo}
                        onChange={handleCambioCorreo}
                      />{' '}
                      <QuestionCircleOutlined style={{ color: '#fff' }} />
                    </td>
                  </tr>

                  <tr>
                    <td
                      colSpan={3}
                      style={{
                        textAlign: 'center',
                        height: 45,
                        //borderLeft: 'solid #7272cb 1px',
                        //borderRight: 'solid #7272cb 1px',
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                    >
                      
                      <Input.Password
                        placeholder="Ingrese contraseña ..."
                        className="input-create-one-password"
                        onChange={handleCambioContrasena}
                      />
                      <Tooltip
                        placement="top"
                        title={
                          <>
                            La contraseña debe tener las siguientes
                            características:
                            <div>• Mínimo 8 caracteres y Máximo 15</div>
                            <div>• Al menos una letra mayúscula</div>
                            <div>• Al menos una letra minúscula</div>
                            <div>• Al menos un dígito</div>
                            <div>• No espacios en blanco</div>
                            <div>• Al menos 1 carácter especial</div>
                          </>
                        }
                      >
                        <QuestionCircleOutlined style={{ color: '#4c4cbc' }} />
                      </Tooltip>
                    </td>
                  </tr>

                  <tr>
                    <td
                      colSpan={3}
                      style={{
                        textAlign: 'center',
                        height: 80,
                        //borderBottom: 'solid #7272cb 1px',
                        //borderLeft: 'solid #7272cb 1px',
                        //borderRight: 'solid #7272cb 1px',
                        //borderRadius: '0px 0px 15px 15px',
                      }}
                    >
                      <Button
                        type="primary"
                        size={'default'}
                        style={{
                          backgroundColor: '#4c4cbc',
                          height: 40,
                          fontWeight: 'bold',
                          boxShadow: '0px -1px 10px 0px rgba(0,0,0,0.20)',
                        }}
                        onClick={() => handleCreate()}
                        className="account-welcome-btn-create"
                      >
                        <PlusOutlined style={{ fontSize: '18px' }} />
                        {' Registrar '}
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br></br>
              <br></br>
              <br></br>
            </center>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  );
};

export default CreateAccount;
