import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import './Welcome.css';
import './Login.css';
import { Carousel, Typography, Button, Collapse, Input, Divider,Row,Col   } from 'antd';
import { PlusOutlined, UserOutlined, LoginOutlined,ArrowLeftOutlined } from '@ant-design/icons';

import AuthService from '../services/auth.service';
import AvalService from '../services/aval.Service';

import { ComponentesContext } from '../contextos/componentesContext';

const ValidarAval = () => {
  const idSolicitud = useParams();

  //console.log('solicitud', idSolicitud.parametro);

  const {
    ModalMessage,
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    UpdateFlagtimeLineForm,
    UpdateUserSesion,
    ShowLoading,
    UpdateFlagtimeLineFormProductos,
    UpdateFooterText,
    UpdateFlagMenuInicial,
    EmailIngresado,
    UpdateFlagtimeLineFormAval
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const { Title } = Typography;
  const location = useLocation();

  const [flagUserExist, setflagUserExist] = useState(false);

  const [formVisible, setformVisible] = useState(false);
  const [Correo, SetCorreo] = useState('');
  const [Contrasena1, setContrasena1] = useState('');
  const [Contrasena2, setContrasena2] = useState('');

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 100,
    heightImage: window.innerHeight - 185,
  });

  const contentStyle = {
    height: windowSize.height,
    minHeight: 400,
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
    borderRadius: 15,
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight - 100,
        heightImage: window.innerHeight - 185,
      });
    };

    InicializaFormulario();
    UpdatebuttonIngresar('');
    UpdateuserAvatar('');
    UpdateFlagtimeLineForm(false);
    UpdateFlagtimeLineFormProductos(false);
    UpdateFlagtimeLineFormAval(false);
    
    UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');
    UpdateFlagMenuInicial(false);

    if (EmailIngresado != '') {
      SetCorreo(EmailIngresado);
    }

    window.addEventListener('resize', handleResize);
    //window.addEventListener("scroll", handleScroll);
    return () => {
      //window.removeEventListener("scroll", handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const InicializaFormulario = async () => {
    ShowLoading(true);

    let respuesta = await AvalService.getAvalEmail(idSolicitud.parametro);

    //console.log('respuesta', respuesta);

    if (respuesta.result != true) {
      ShowLoading(false);
      ModalMessage('Error', respuesta.msg);
      return false;
    }

    SetCorreo(respuesta.data.avalEmail);
    setflagUserExist(respuesta.data.existeUsuario)

    setformVisible(true);

    ShowLoading(false);
  };

  const handleNext = (option) => {
    navigate(option);
  };

  const handleCorreo = async (e) => {
    SetCorreo(e.target.value);
  };

  const handleContrasena = async (e) => {
    setContrasena(e.target.value);
  };

  const handleCrearCuenta = async () => {
    var regexp_password =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#.])[A-Za-z\d$@$!%*?&#.]{8,15}/;

    if (Contrasena1 == '') {
      ModalMessage('Mensaje', 'Las contraseñas no pueden estar en blanco.');
      return false;
    }

    if (Contrasena1 != Contrasena2) {
      ModalMessage('Mensaje', 'Las contraseñas deben ser iguales.');
      return false;
    }

    if (!regexp_password.test(Contrasena1)) {
      ModalMessage(
        'Mensaje',
        <>
          La contraseña debe tener las siguientes características:
          <div>• Mínimo 8 caracteres y Máximo 15</div>
          <div>• Al menos una letra mayúscula</div>
          <div>• Al menos una letra minúscula</div>
          <div>• Al menos un dígito</div>
          <div>• No espacios en blanco</div>
          <div>• Al menos 1 carácter especial</div>
        </>
      );
      return false;
    }

    ShowLoading(true);

    const json = {
      solicitudId: idSolicitud.parametro,
      password: Contrasena1,
    };

    let respuesta = await AvalService.postUsuariosAval(json);

    if (respuesta.result != true) {
      ShowLoading(false);
      ModalMessage('Error', respuesta.msg);
      return false;
    }

    ShowLoading(false);
    navigate('/codevalidation', {
      state: { record: { correo: Correo } },
    });

  };

  return formVisible ? (
    <div>
      <table
        width={'100%'}
        style={{
          borderRadius: 15,
          backgroundColor: '#ffffff',
          borderWidth: 0,
          borderStyle: 'none',
          marginTop: -25,
          display: flagUserExist ? "none" : ""
        }}
      >
        <tbody>
          <tr>
            <td style={{ verticalAlign: 'top' }}>
              <div
                style={{
                  backgroundColor: '#fff',
                  marginTop: 100,
                  marginLeft: 8,
                }}
              >
                <center>
                  <table
                    cellPadding={0}
                    cellSpacing={0}
                    style={{
                      width: '50%',
                      borderRadius: 15,
                      marginLeft: 0,
                    }}
                    border={0}
                    className="div-login-card"
                  >
                    <tbody>
                      <tr>
                        <td
                          colSpan={3}
                          style={{
                            textAlign: 'center',

                            borderRadius: '15px 15px 0 0',
                          }}
                        >
                          <p>
                            <Title
                              level={2}
                              style={{ marginTop: -5, color: '#4c4cbc' }}
                            >
                              Inicia sesión Aval
                            </Title>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={3}
                          style={{
                            textAlign: 'center',
                            height: 50,
                          }}
                        >
                          <Input
                            className="input-login"
                            value={Correo}
                            placeholder="Correo Electrónico"
                            onChange={handleCorreo}
                            disabled
                          />
                        </td>
                      </tr>

                      <tr>
                        <td
                          colSpan={3}
                          style={{
                            textAlign: 'center',
                            height: 50,

                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <Input.Password
                            className="input-login"
                            placeholder="Ingrese la contraseña..."
                            onChange={ (e) => { setContrasena1(e.target.value) } }
                          />
                        </td>
                      </tr>

                      <tr>
                        <td
                          colSpan={3}
                          style={{
                            textAlign: 'center',
                            height: 50,

                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <Input.Password
                            className="input-login"
                            placeholder="Reingrese la contraseña..."
                            onChange={ (e) => { setContrasena2(e.target.value) } }
                            onPressEnter={(e) => handleCrearCuenta()}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td
                          colSpan={3}
                          style={{
                            height: 50,

                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <center>
                            <div
                              className="input-login"
                              style={{ textAlign: 'right' }}
                            >
                              <a
                                style={{ color: '#4c4cbc' }}
                                onClick={() =>
                                  handleNext('/solicitud_cambio_contra')
                                }
                              >
                                ¿Olvidaste tu contraseña?
                              </a>
                            </div>
                          </center>
                        </td>
                      </tr>

                      <tr>
                        <td
                          colSpan={3}
                          style={{
                            textAlign: 'center',
                            height: 80,

                            borderRadius: '0px 0px 15px 15px',
                          }}
                        >
                          <Button
                            type="primary"
                            size={'default'}
                            style={{
                              backgroundColor: '#4c4cbc',
                              height: 40,
                              width: '90%',
                              fontWeight: 'bold',
                              boxShadow: '0px -1px 10px 0px rgba(0,0,0,0.20)',
                              marginBottom: 40,
                            }}
                            onClick={() => handleCrearCuenta()}
                            className="account-welcome-btn-create"
                          >
                            {' Ingresar '}&nbsp;
                            <LoginOutlined style={{ fontSize: '18px' }} />
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  
                </center>
              </div>
            </td>
          </tr>
        </tbody>
      </table>



      <div
        style={{
          backgroundColor: '#fff',
          
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          height: windowSize.height,
          
        }}
      >
        <div
          className="div-img-arrendamiento"
          style={{
            borderRadius: 25,
            boxShadow: '0px 0px 22px -10px rgba(0,0,0,0.75)',
            marginBottom: 0,
            background: '#fff',
            marginTop: 30,
            width: windowSize.width - 200,
            display: flagUserExist ? "" : "none",
            minWidth: 330,
            paddingRight: 20,
            paddingLeft: 20,
            
          }}
        >
                  

          <div style={{width:"100%"}}>
            <div
              className='div-buro-aval'
              style={{
                width: '100%',
                marginBottom: 30,
                textAlign: 'center',
                marginTop: 50
              }}
            >
              Ya cuenta con un usuario para esta plataforma, para ingresar a su cuenta y poder autorizar las consultas de click al siguiente botón.
            </div>


          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              marginTop: 30,
              marginBottom: 20,
            }}
          >
            <Button
                  className="buttons-back-forward"
                  type="primary"
                  onClick={() => {navigate('/login', {
                    state: { record: { correo: Correo } },
                  });} }
                >
                  {' Ingresar '}&nbsp;
                            <LoginOutlined style={{ fontSize: '18px' }} />
                </Button>
          </div>

          
        </div>
      </div>

    </div>
  ) : (
    <></>
  );
};

export default ValidarAval;
