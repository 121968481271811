import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import RfcInput from '../../../componentes/InputRfc';
import '../../Welcome.css';
import './AutorizacionConsulta.css';
import {
  Checkbox,
  Typography,
  Button,
  Select,
  Input,
  Form,
  Row,
  Col,
  Divider,
  Skeleton,
  InputNumber,
  Slider,
  Radio,
  Tooltip,
  Modal,
  Upload,
  ConfigProvider,
  Segmented,
} from 'antd';
import {
  PlusOutlined,
  UserOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import {
  FaSignature,
  FaFile,
  FaBuilding,
  FaUserTie,
  FaFileSignature,
  FaCircleQuestion,
} from 'react-icons/fa6';

import InputCustom from '../../../componentes/InputCustom';
import { ComponentesContext } from '../../../contextos/componentesContext';

import AuthService from '../../../services/auth.service';
import FormulariosService from '../../../services/formularios.Service';
import returnValidUploadFileClass from '../../../clases/returnValidUploadFile.class';

import Video from '../../../asset/CiecEfirma.mp4';

import RefreshAvalTypeClass from '../../../clases/refreshAvalType.class';

const FirmaBuro = () => {
  const URL = process.env.REACT_APP_URL_API_EXPRESS;
  const {
    UpdateFooterText,
    ModalMessage,
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    loading,
    UpdatecurrentStep,
    InputTypes,
    UpdateFlagtimeLineForm,
    UpdatemovilSteps,
    ShowLoading,
    CloseSession,
    RfcTypes,
    UpdateFlagtimeLineFormMovil,
    UpdateFlagtimeLineFormProductos,
    pendientesAval,
    UpdateFlagtimeLineFormAval,
    UpdatePendientesAval,
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const { Title } = Typography;
  const [form] = Form.useForm();
  const dateFormat = 'DD-MM-YYYY';
  const location = useLocation();

  const formData = new FormData();
  const fileInputRef = useRef(null);
  const fileInputKeyRef = useRef(null);

  const [solicitudId, SetsolicitudId] = useState(
    typeof location.state.record.solicitudId != 'undefined'
      ? location.state.record.solicitudId != null
        ? location.state.record.solicitudId
        : '0'
      : '0'
  );

  const [isModalVideoOpen, setisModalVideoOpen] = useState(true);

  const [formclienteTipoRegimenFiscalId, setformclienteTipoRegimenFiscalId] =
    useState(1);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 190,
    heightImage: window.innerHeight - 185,
    heightScroll: (window.innerHeight * 54) / 100,
  });

  const [valueRFC, setvalueRFC] = useState('');

  const [valueRegimen, setvalueRegimen] = useState(1);
  const [PfTooltip, setPfTooltip] = useState('');
  const [PmTooltip, setPmTooltip] = useState('');

  const [PfDisabled, setPfDisabled] = useState(false);
  const [PmDisabled, setPmDisabled] = useState(false);

  const [PfaeisVisible, setPfaeisVisible] = useState(true);
  const [PmisVisible, setPmisVisible] = useState(false);

  const [isModalOpen, setisModalOpen] = useState(false);
  const [StatusSolicitud, setStatusSolicitud] = useState(0);

  const [formTipoCredencial, setformTipoCredencial] = useState('1');
  const [ContrasenaActual, setContrasenaActual] = useState('');

  const [visibleCiec, setvisibleCiec] = useState('');
  const [visibleEfirma, setvisibleEfirma] = useState('none');

  const [titleCertificado, settitleCertificado] = useState(
    'CARGAR: Certificado.cer'
  );
  const [titleKey, settitleKey] = useState('CARGAR: ARCHIVO_FIEL.key');

  const [fileCertificate, setfileCertificate] = useState(null);
  const [filePrivateKey, setfilePrivateKey] = useState(null);

  const [urlAnterior, seturlAnterior] = useState('/datos_aval');

  const [flagclienteTipoRegimenFiscalId, setclienteTipoRegimenFiscalId] =
    useState(0);

  const contentStyle = {
    height: windowSize.height,
    minHeight: 400,
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
    borderRadius: 15,
  };

  const optionsTipoCredencial = [
    {
      value: '1',
      label: 'CIEC',
    },
    {
      value: '2',
      label: 'efirma',
    },
  ];

  useEffect(() => {
    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      //console.log('entro');
      AuthService.logout();

      navigate('/login', {
        state: { record: { correo: '' } },
      });
    } else {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 190,
          heightImage: window.innerHeight - 185,
          heightScroll: (window.innerHeight * 54) / 100,
        });
      };

      InicializaFormulario();

      UpdateFlagtimeLineFormMovil(true);
      UpdateFlagtimeLineForm(true);
      UpdateFlagtimeLineFormAval(false);
      UpdateFlagtimeLineFormProductos(true);
      UpdatebuttonIngresar(
        'dashboard',
        usr.primerNombre + ' ' + usr.primerApellido
      );
      UpdateuserAvatar('dashboard');
      UpdatecurrentStep(8);
      UpdatemovilSteps(6);
      UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');

      setTimeout(function () {
        form.setFieldsValue({
          inversionista: {
            codRegFiscal: 1,
          },
        });
      }, 0);

      window.addEventListener('resize', handleResize);
      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const InicializaFormulario = async () => {
    ShowLoading(true);
    var respuesta = {};
    var respuestaCliente = {};

    UpdatePendientesAval(await RefreshAvalTypeClass.RefreshAvalType());

    if (solicitudId == 0) {
      ShowLoading(false);
      ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      return false;
    } else {
      respuestaCliente =
        await FormulariosService.getInformacionCliente(solicitudId);

      if (respuestaCliente.result != true) {
        ShowLoading(false);
        let respuestaSesion = await AuthService.getValidaSesion();

        if (respuestaSesion.result == false) {
          CloseSession();
        } else {
          ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
        }
        return false;
      }

      respuesta = await FormulariosService.getValidaCapturaFirma(
        solicitudId,
        'cliente'
      );

    }

    setvalueRFC(respuestaCliente.data.clienteRfc);

    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      }
      return false;
    }

    if (respuesta.data.estado == 1 || respuesta.data.estado == 3) {
      handleNext('/firma_buro');
      ShowLoading(false);
      return false;
    }

    ShowLoading(false);

    if (respuestaCliente.data.clienteTipoRegimenFiscalId == 1) {
      seturlAnterior('/datos_contacto');
    } else {
      seturlAnterior('/datos_aval');
    }

    setclienteTipoRegimenFiscalId(
      respuestaCliente.data.clienteTipoRegimenFiscalId
    );

  };

  const handleNext = (option) => {
    navigate(option, {
      state: { record: { solicitudId: solicitudId } },
    });
  };

  const handleModalClose = () => {
    setisModalOpen(false);
  };

  const handleContrasena = (e) => {
    //console.log(e.target.value);
    setContrasenaActual(e.target.value);
  };

  const handleChangeSector = (val) => {
    setformTipoCredencial(val);
    if (val == '1') {
      setvisibleCiec('');
      setvisibleEfirma('none');
    } else if (val == '2') {
      setvisibleCiec('none');
      setvisibleEfirma('');
    } else {
      setvisibleCiec('');
      setvisibleEfirma('none');
    }
  };

  const props = {
    name: 'file',
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        //console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleCertificadoChange = async (event) => {
    ShowLoading(true);
    const file = event.target.files[0];
    //console.log(file);

    //let flagArchivoValido = await returnValidUploadFileClass.returnCertificateValidUploadFile(file.type);

    const flagArchivoValido = true;

    if (flagArchivoValido == false) {
      ShowLoading(false);
      ModalMessage(
        'Error',
        'El archivo seleccionado tiene no tiene un formato ".cer" valido .'
      );
      settitleCertificado('CARGAR: Certificado.key');
      return false;
    }

    setfileCertificate(file);
    //console.log("archivo valido",flagArchivoValido);
    settitleCertificado('CARGAR: ' + file.name);
    ShowLoading(false);

    //console.log(formData);

    // Puedes agregar tu lógica adicional aquí, como hacer una solicitud a tu servidor
    // o ejecutar alguna acción específica.
  };

  const handleKeyFileChange = async (event) => {
    ShowLoading(true);
    const file = event.target.files[0];
    //console.log(file);

    //let flagArchivoValido = await returnValidUploadFileClass.returnKeyValidUploadFile(file.type);

    const flagArchivoValido = true;

    if (flagArchivoValido == false) {
      ShowLoading(false);
      ModalMessage(
        'Error',
        'El archivo seleccionado tiene no tiene un formato ".key" valido .'
      );
      settitleKey('CARGAR: Certificado.key');
      return false;
    }

    setfilePrivateKey(file);
    //console.log("archivo valido",flagArchivoValido);
    settitleKey('CARGAR: ' + file.name);

    ShowLoading(false);

    //console.log(formData);

    // Puedes agregar tu lógica adicional aquí, como hacer una solicitud a tu servidor
    // o ejecutar alguna acción específica.
  };

  const handleSave = async (strUrl) => {
    //console.log("tipo credencial",formTipoCredencial);

    ShowLoading(true);

    if (formTipoCredencial == 2) {
      if (fileCertificate == null) {
        ModalMessage(
          'Mensaje',
          'Debe seleccionar un Certificado para poder continuar.'
        );
        ShowLoading(false);
        return false;
      }

      if (filePrivateKey == null) {
        ModalMessage(
          'Mensaje',
          'Debe seleccionar una Llave Privada para poder continuar.'
        );
        ShowLoading(false);
        return false;
      }

      if (ContrasenaActual == '') {
        ModalMessage(
          'Mensaje',
          'Debe capturar la Contraseña para poder continuar.'
        );
        ShowLoading(false);
        return false;
      }

      formData.append('solicitudId', solicitudId);
      formData.append('password', ContrasenaActual);
      formData.append('certificate', fileCertificate);
      formData.append('privateKey', filePrivateKey);
      formData.append('cliente', true);

      fetch(`${URL}satws/firma-efirma`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${AuthService.getCurrentToken()}`,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          //ActualizaUpload(props.id, true, data.id);
          //setloadingModal(false);

          //console.log(data);

          if (data.codigoError == '00001') {
            ModalMessage('Mensaje', data.msg);
          } else {
            setTimeout(function () {
              handleNext(strUrl);
            }, 500);
          }

          ShowLoading(false);
        })
        .catch((error) => {
          // Maneja errores
          //setloadingModal(false);
          ShowLoading(false);
          ModalMessage('Error', 'Se produjo un error al guardar los datos.');

          return false;
          //console.log('entro mal');
        });
    } else {
      const regEXRFC =
        /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Z\d]{3}))?$/;

      if (valueRFC == '') {
        ModalMessage('Mensaje', 'El RFC no puede estar en blanco.');

        ShowLoading(false);
        return false;
      }

      //console.log(valueRFC);
      // if (!regEXRFC.test(valueRFC)) {
      //   ShowLoading(false);
      //   ModalMessage(
      //     'Mensaje',
      //     'El RFC no tiene un formato valido, por favor verifique.'
      //   );
      //   return false;
      // }
      if (ContrasenaActual == '') {
        ModalMessage(
          'Mensaje',
          'Debe capturar la Contraseña para poder continuar.'
        );
        ShowLoading(false);
        return false;
      }

      if (ContrasenaActual.length < 8) {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'La contraseña debe tener 8 caracteres o más.'
        );
        return false;
      }

      const json = {
        solicitudId: solicitudId,
        rfc: valueRFC,
        password: ContrasenaActual,
        cliente: true,
      };

      let respuesta = await FormulariosService.postGuardaFirmaAutorizacionSat(json);

      if (respuesta.result != true) {
        ShowLoading(false);
        let respuestaSesion = await AuthService.getValidaSesion();

        if (respuestaSesion.result == false) {
          CloseSession();
        } else {
          ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
        }
        return false;
      }

      handleNext(strUrl);
      ShowLoading(false);
    }
  };

  return (
    <html>
      <div
        style={{
          backgroundColor: '#fff',
          paddingBottom: 50,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <div
          className="div-img-arrendamiento"
          style={{
            borderRadius: 25,
            boxShadow: '0px 0px 22px -10px rgba(0,0,0,0.75)',
            marginBottom: 0,
            background: '#fff',
            marginTop: 30,
            width: windowSize.width - 200,

            minWidth: 500,
            paddingRight: 20,
          }}
        >
          <center>
            <Divider>
              <div className="title-steps-buro" style={{ color: '#4c4cbc' }}>
                Autorización de Consulta de SAT
              </div>
            </Divider>
          </center>

          {/* <div
          style={{ overflow: 'auto', maxHeight: 400, width: '100%' }}
          id="mifiel-widget"
        ></div> */}

          <div
            style={{
              fontSize: 18,
              width: '95%',
              marginBottom: 30,
              marginLeft: 20,
            }}
          >
            Continuando con tu registro, de manera segura y transparente
            analizaremos la información financiera de tu negocio para brindarle
            la mejor oferta, ayúdanos proporcionando la siguiente información.
          </div>

          <ConfigProvider
            theme={{
              token: {
                colorBgElevated: '#4c4cbc',
              },
            }}
          >
            <center>
              <Segmented
                options={[
                  {
                    label: (
                      <div style={{ width: 200, fontSize: 20 }}>
                        <FaFileSignature
                          style={{ fontSize: 15, marginRight: 5 }}
                        />
                        eFirma
                      </div>
                    ),
                    value: '2',
                  },
                  {
                    label: (
                      <div style={{ width: 200, fontSize: 20 }}>
                        <FaSignature style={{ fontSize: 15, marginRight: 5 }} />
                        CIEC
                      </div>
                    ),
                    value: '1',
                  },
                ]}
                onChange={(value) => {
                  //console.log(value); // string
                  handleChangeSector(value);
                }}
                value={formTipoCredencial}
              />
            </center>
          </ConfigProvider>
          <br></br>
          {/* <Row>
            <Col span={24} style={{ textAlign: 'left' }}>
              <div className="select-steps">
                {' '}
                <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                <b>Tipo de credenciales:</b>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={10} style={{ textAlign: 'left', marginBottom: 20 }}>
              <center>
                <Select
                  className="select-steps"
                  placeholder="Seleccione una opción"
                  options={optionsTipoCredencial}
                  value={formTipoCredencial}
                  onChange={handleChangeSector}
                />
              </center>
            </Col>
          </Row> */}

          <div style={{ display: visibleCiec }}>
            <Row>
              <Col span={24} style={{ textAlign: 'left' }}>
                <div className="select-steps">
                  {' '}
                  <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                  <b>RFC(Con Homoclave):</b>
                </div>
              </Col>
            </Row>

            <Row>
              <Col
                flex="1 1 200px"
                style={{ textAlign: 'left', marginLeft: 20, marginBottom: 20 }}
              >
                <center>
                  <RfcInput
                    className="input-steps"
                    value={valueRFC}
                    onChange={setvalueRFC}
                    tipo={RfcTypes.Fisica}
                    onInput={(e) =>
                      (e.target.value = e.target.value.toUpperCase())
                    }
                    disabled
                  />
                </center>
              </Col>
            </Row>

            <Row>
              <Col span={24} style={{ textAlign: 'left' }}>
                <div className="select-steps">
                  {' '}
                  <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                  <b>Contraseña:</b>
                </div>
              </Col>
            </Row>

            <Row>
              <Col
                flex="1 1 200px"
                style={{ textAlign: 'left', marginLeft: 20 }}
              >
                <center>
                  <Input.Password
                    placeholder="Ingrese contraseña ..."
                    className="input-steps"
                    value={ContrasenaActual}
                    onChange={(e) => {
                      setContrasenaActual(e.target.value);
                    }}
                  />
                </center>
              </Col>
            </Row>
          </div>

          <div
            style={{
              display: visibleEfirma,
              marginLeft: 0,
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <center>
              <div
                className="div-frame-satws"
                style={{
                  border: 'solid #4c4cbc 1px',
                  padding: 10,
                  borderRadius: 10,
                  marginBottom: 10,
                }}
              >
                <table width={'100%'}>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'left' }}>
                        <div>
                          <Typography.Title
                            level={5}
                            style={{
                              margin: 0,
                              color: '#7d7d7d',
                              marginTop: 0,
                              fontWeight: 'bold',
                            }}
                          >
                            1.Certificado(.cer) seleccionado
                          </Typography.Title>
                        </div>
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        <input
                          type="file"
                          style={{ display: 'none' }}
                          accept=".cer"
                          onChange={handleCertificadoChange}
                          ref={fileInputRef}
                        />

                        <a
                          style={{
                            color: '#4c4cbc',
                            fontSize: 14,
                            fontWeight: 'bold',
                          }}
                          onClick={() => fileInputRef.current.click()}
                        >
                          Cargar certificado
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={2}
                        style={{ paddingLeft: 10, paddingRight: 20 }}
                      >
                        <div
                          style={{
                            paddingLeft: 20,
                            backgroundColor: '#f3f3f9',
                            width: '100%',
                            borderRadius: 10,
                          }}
                        >
                          <table>
                            <tr>
                              <td rowSpan={2} style={{ width: 30, height: 70 }}>
                                <FaFile
                                  style={{ fontSize: 25, color: '#7d7d7d' }}
                                />
                              </td>
                              <td
                                className='div-frame-satws-table'
                                style={{ color: '#7d7d7d', fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                              >
                                {titleCertificado}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                className="div-frame-satws"
                style={{
                  border: 'solid #4c4cbc 1px',
                  padding: 10,
                  borderRadius: 10,
                  marginBottom: 10,
                }}
              >
                <table width={'100%'}>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'left' }}>
                        <div>
                          <Typography.Title
                            level={5}
                            style={{
                              margin: 0,
                              color: '#7d7d7d',
                              marginTop: 0,
                              fontWeight: 'bold',
                            }}
                          >
                            2.Llave privada (.key) seleccionada
                          </Typography.Title>
                        </div>
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        <input
                          type="file"
                          style={{ display: 'none' }}
                          accept=".key"
                          onChange={handleKeyFileChange}
                          ref={fileInputKeyRef}
                        />
                        <a
                          style={{
                            color: '#4c4cbc',
                            fontSize: 14,
                            fontWeight: 'bold',
                          }}
                          onClick={() => fileInputKeyRef.current.click()}
                        >
                          Cargar Llave
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={2}
                        style={{ paddingLeft: 10, paddingRight: 20 }}
                      >
                        <div
                          style={{
                            paddingLeft: 20,
                            backgroundColor: '#f3f3f9',
                            width: '100%',
                            borderRadius: 10,
                            
                          }}
                        >
                          <table >
                            <tr>
                              <td rowSpan={2} style={{ width: 30, height: 70 }}>
                                <FaFile
                                  style={{ fontSize: 25, color: '#7d7d7d' }}
                                />
                              </td>
                              <td
                                className='div-frame-satws-table'
                                style={{ color: '#7d7d7d', fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                              >
                                {titleKey}                                
                              </td>
                            </tr>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                className="div-frame-satws"
                style={{
                  border: 'solid #4c4cbc 1px',
                  padding: 10,
                  borderRadius: 10,
                }}
              >
                <table width={'100%'}>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'left' }}>
                        <div>
                          <Typography.Title
                            level={5}
                            style={{
                              margin: 0,
                              color: '#7d7d7d',
                              marginTop: 0,
                              fontWeight: 'bold',
                            }}
                          >
                            3. Ingresa la contraseña de tu e.firma
                          </Typography.Title>
                        </div>
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {/* <a
                          style={{
                            color: '#4c4cbc',
                            fontSize: 14,
                            fontWeight: 'bold',
                          }}
                        >
                          Que es?
                        </a> */}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{ textAlign: 'center' }}>
                        <Input.Password
                          placeholder="Ingrese contraseña ..."
                          style={{ width: '100%' }}
                          onChange={handleContrasena}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </center>
          </div>

          <ConfigProvider
            theme={{
              components: {
                Button: {
                  defaultBg: '#04bb73',
                },
              },
            }}
          >
            <Button
              className="buttons-back-forward"
              type="primary"
              onClick={() => setisModalVideoOpen(true)}
              htmlType="submit"
              style={{
                marginTop: 20,
                backgroundColor: '#04bb73',
                borderColor: '#04bb73',
                minWidth: 200,
              }}
            >
              Por que pedimos esto <FaCircleQuestion style={{ width: 30 }} />
            </Button>
          </ConfigProvider>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              marginTop: 30,
              marginBottom: 20,
            }}
          >
            <Row gutter={6} style={{ width: '90%' }}>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Button
                  className="buttons-back-forward"
                  type="primary"
                  onClick={() => handleNext(urlAnterior)}
                  icon={<ArrowLeftOutlined />}
                >
                  Atrás
                </Button>
              </Col>

              <Col span={12} style={{ marginLeft: 0, textAlign: 'left' }}>
                <Button
                  className="buttons-back-forward"
                  type="primary"
                  onClick={() => handleSave('/firma_buro')}
                  htmlType="submit"
                >
                  Continuar <ArrowRightOutlined />
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Modal
        title={
          <div>
            <FaSignature style={{ fontSize: 20 }} /> &nbsp;&nbsp;&nbsp;&nbsp;
            Autorización para consulta de Buró y SATWS.
          </div>
        }
        open={isModalOpen}
        onCancel={handleModalClose}
        footer={[
          <Button type="primary" onClick={handleModalClose}>
            Enviar
          </Button>,
        ]}
      >
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <label style={{ fontSize: 18, fontWeight: 'bold' }}>
            Tipo de credencial:
          </label>
          &nbsp;&nbsp;&nbsp;
          <label style={{ fontSize: 18 }}>CIEC</label>
        </div>

        <div style={{ textAlign: 'center', marginTop: 10 }}>
          <label style={{ fontSize: 18, fontWeight: 'bold' }}>CIEC:</label>
          &nbsp;&nbsp;&nbsp;
          <label style={{ fontSize: 18 }}>
            <Input.Password
              placeholder=""
              style={{ width: 250 }}
              onChange={handleContrasena}
            />
          </label>
        </div>

        {/* <div>
          <Checkbox
            onChange={handleCheck}
            style={{
              textAlign: 'justify',
              width: 400,
              marginTop: 30,
              marginLeft: 40,
              marginBottom: 20,
            }}
          >
            <label style={{ backgroundColor: 'ffff' }}>
              Autorizo a Fínamo (Administradora de Soluciones, S.A. de C.V.,
              SOFOM, E.N.R./Arrendadora Fínamo, S.A. de C.V.) para consultar mi
              comportamiento en cualquier sociedad de información crediticia,
              inclusive de manera periódica. Declaro que conozco la información
              que se solicita, el uso y alcance que se le dará, por una vigencia
              de tres años o mientras dure nuestra relación.
            </label>
          </Checkbox>
        </div> */}
      </Modal>

      <Modal
        title={
          <div>
            <FaCircleQuestion style={{ fontSize: 15 }} /> &nbsp;&nbsp;
            <label style={{ marginTop: -50 }}>Ayuda</label>
          </div>
        }
        style={{ minWidth: '55%' }}
        open={isModalVideoOpen}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        onCancel={() => setisModalVideoOpen(false)}
        footer={[
          <Button type="primary" onClick={() => setisModalVideoOpen(false)}>
            Cerrar
          </Button>,
        ]}
      >
        <div className="video-container">
          <video controls>
            <source src={Video} type="video/mp4" />
            {/* Puedes agregar más etiquetas <source> para formatos de video adicionales */}
            Your browser does not support the video tag.
          </video>
        </div>
      </Modal>
    </html>
  );
};

export default FirmaBuro;
