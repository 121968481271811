import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate, Outlet, useLocation, Link } from 'react-router-dom';

import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

import AuthService from '../services/auth.service';
import logo from '../asset/images/logo_white.png';
import logoCondusef from '../asset/images/logoCONDUSEF.png';
import logoBuro from '../asset/images/security-buro.png';
import logoBc from '../asset/images/buro_credito.png';

import logoUne from '../asset/images/UNE.jpeg';
import logoCondu from '../asset/images/Condusef.jpeg';
import logoCnbv from '../asset/images/Logo_CNBV.png';

import logoFinamo from '../asset/images/marcador.png';

import map from '../asset/images/Map.png';
import logoTalipot from '../asset/images/talipot.png';

import { ComponentesContext } from '../contextos/componentesContext';

import { Row, Col, Space, Input, Button } from 'antd';

import './Nosotros.css';

import {
  FacebookOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  YoutubeOutlined,
  InstagramOutlined,
  SendOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';




import {
  FaScaleBalanced,
  FaMoneyBillTrendUp,
  FaDesktop,
  FaBuilding,
  FaArrowUpRightFromSquare,
  FaMoneyBill,
  FaBriefcase,
  FaClipboard,
  FaComments,
  FaFileSignature,
  FaHouse,
  FaEnvelope,
  FaPhone,
  FaWhatsapp,
  FaFlag,
  FaEye,
  FaPeopleGroup,
  FaLocationDot
} from 'react-icons/fa6';

const AvisoPrivacidad = () => {
  

  const {
    UpdateFooterText,
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    loading,
    ShowLoading,
    UpdatecurrentStep,
    UpdateFlagtimeLineForm,
    UpdatemovilSteps,
    ModalMessage,
    CloseSession,
    UpdateFlagtimeLineFormMovil,
    UpdateFlagtimeLineFormProductos,
    UpdateFlagMenuInicial,
    UpdateFlagtimeLineFormAval
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth - 60,
    height: window.innerHeight - 190,
    heightScroll: window.innerHeight - 190,
  });

  const LogosStyle = {
    color: 'white',
    fontSize: 30,
  };

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    if (usr === null || usr === undefined) {
      UpdatebuttonIngresar('create');
      UpdateuserAvatar('');
      UpdateFlagtimeLineFormProductos(false);
    } else {
      UpdatebuttonIngresar(
        'dashboard',
        usr.primerNombre + ' ' + usr.primerApellido
      );
      UpdateuserAvatar('dashboard');

      UpdateFlagtimeLineFormProductos(true);
    }
    InicializaFormulario();
    const handleResize = () => {

      if (window.innerWidth < 500) {
        setWindowSize({
          width: window.innerWidth +700,
          height: window.innerHeight - 190,
          heightScroll: window.innerHeight - 190,
        });
      }
      else {
        setWindowSize({
          width: window.innerWidth - 60,
          height: window.innerHeight - 190,
          heightScroll: window.innerHeight - 190,
        });  
      }
      
    };

    UpdatebuttonIngresar('');
    UpdateuserAvatar('');
    UpdateFlagtimeLineForm(false);
    UpdateFlagtimeLineFormAval(false)
    UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');
    UpdateFlagMenuInicial(true);

    window.scrollTo({
      top: 0,
    });

    window.addEventListener('resize', handleResize);

    //window.addEventListener("scroll", handleScroll);
    return () => {
      //window.removeEventListener("scroll", handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const InicializaFormulario = () => {};

  const handleNext = (option) => {
    navigate(option);
    //window.location.reload();
  };

  const mapStyles = {
    height: '400px',
    width: '100%',
  };

  const markerIcon = {
    url: logoFinamo, // Reemplaza con la URL de tu imagen
  };

  const direccionFija = { lat: 24.799358059431732, lng: -107.43349426695617 };

  const handleLink = (link) => {
    window.location.href = link;
  };

  return (
    <div
      style={{
        maxHeight: windowSize.heightScroll,
        height: windowSize.heightScroll,
        backgroundColor: '#fff',
        marginTop: 5,
        borderRadius: 15,
      }}
    >
      <div className="contenedor-nosotros">
        <div className="fondo-nosotros">{/* Tu imagen de fondo */}</div>
        <div className="overlay-nosotros">
          <p
            className="font-title-nosotros"
            style={{
              color: 'white',
              fontWeight: 'bold',
              fontFamily: 'Red Hat Display, sans-serif',
              marginTop: 350,
            }}
          >
            Somos tu socio financiero
          </p>

          <p
            className="font-title-nosotros2"
            style={{
              color: 'white',
              fontWeight: 'bold',
              marginTop: -40,
            }}
          >
            Dedicados a impulsar y transformar empresas
          </p>
        </div>
      </div>

      <div>
        <center>
          <p
            className="div-titulo-beneficios"
            style={{
              fontWeight: 'bold',
              fontFamily: 'Red Hat Display, sans-serif',
            }}
          >
            ¿Qué define a Fínamo?
          </p>
        </center>

        <div style={{ marginBottom: 30 }}>
          <Row style={{ textAlign: 'center' }}>
            <Col
              xs={{ span: 5, offset: 2 }}
              lg={{ span: 6, offset: 2 }}
              xl={{ span: 6, offset: 2 }}
            >
              <FaFlag style={{ fontSize: 50, color: '#0c0c54' }} />
              <p
                className="div-titulo-beneficios3"
                style={{
                  fontFamily: 'Red Hat Display, sans-serif',
                }}
              >
                Misión
              </p>

              <p
                className="div-titulo-beneficios4"
                style={{
                  fontFamily: 'Red Hat Display, sans-serif',
                }}
              >
                Brindar soluciones financieras competitivas para negocios a
                través de una relación ganar - ganar
              </p>
            </Col>
            <Col xs={{ span: 5, offset: 2 }} lg={{ span: 6, offset: 1 }}>
              <FaEye style={{ fontSize: 50, color: '#0c0c54' }} />
              <p
                className="div-titulo-beneficios3"
                style={{
                  fontFamily: 'Red Hat Display, sans-serif',
                }}
              >
                Visión
              </p>

              <p
                className="div-titulo-beneficios4"
                style={{
                  fontFamily: 'Red Hat Display, sans-serif',
                }}
              >
                Ser una empresa líder a nivel nacional en el otorgamiento de
                Créditos y Arrendamientos, distinguidos por soluciones
                financieras confiables, ágiles e innovadoras.
              </p>
            </Col>
            <Col
              xs={{ span: 5, offset: 2 }}
              lg={{ span: 6, offset: 1 }}
              xl={{ span: 6, offset: 1 }}
            >
              <FaPeopleGroup style={{ fontSize: 50, color: '#0c0c54' }} />
              <p
                className="div-titulo-beneficios3"
                style={{
                  fontFamily: 'Red Hat Display, sans-serif',
                }}
              >
                Valores
              </p>

              <p
                className="div-titulo-beneficios4"
                style={{
                  fontFamily: 'Red Hat Display, sans-serif',
                }}
              >
                Nos distingue el liderazgo, la innovación, el dinamismo, la
                pasión y el trabajo en equipo
              </p>
            </Col>
          </Row>
        </div>
      </div>

      <div className="contenedor-nosotros">
        <div className="fondo-nosotros-equipo">{/* Tu imagen de fondo */}</div>
        <div className="overlay-nosotros-equipo">
          <center>
            <p
              className="font-title-nosotros-equipo"
              style={{
                color: 'white',
                fontWeight: 'bold',
                fontFamily: 'Red Hat Display, sans-serif',
                marginTop: 20,
                textAlign: 'center',
              }}
            >
              Fínamo
            </p>

            <p
              className="font-title-nosotros2-equipo"
              style={{
                color: 'white',
                fontWeight: 'bold',
                marginTop: -40,
                textAlign: 'center',
              }}
            >
              Estamos dedicados a impulsar el crecimiento de tu empresa a través
              de créditos y arrendamientos adecuados para resolver tus
              necesidades financieras.
            </p>
            <p
              className="font-title-nosotros2-equipo"
              style={{
                color: 'white',
                fontWeight: 'bold',
                marginTop: -15,
                textAlign: 'center',
              }}
            >
              Tenemos más de 10 años de experiencia como aliados del crecimiento
              de las empresas otorgando créditos a la medida de sus nececidades
              y con el respaldo de un grupo empresarial con más de 30 años en el
              mercado.
            </p>

            <img style={{ width: 90, marginTop: -5 }} src={logoTalipot}></img>

            <p
              className="font-title-nosotros2-equipo"
              style={{
                color: 'white',
                fontWeight: 'bold',
                marginTop: -5,
                textAlign: 'center',
              }}
            >
              Parte de la familia Talipot
            </p>
          </center>
        </div>
      </div>

      <div className="div-sucursales">
        <center>
          <br></br>
          <p
            className="font-title-nosotros-equipo"
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontFamily: 'Red Hat Display, sans-serif',
              marginTop: 20,
              textAlign: 'center',
            }}
          >
            Conoce nuestras sucursales
          </p>

          <Row style={{ textAlign: 'center', width: '80%' }}>
            <Col xs={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <div className="div-sucursales-card">
                <FaLocationDot className='div-sucursales-card-icon'/>
                Culiacán
              </div>
            </Col>
            <Col xs={{ span: 6 }} lg={{ span: 6 }}>
              <div className="div-sucursales-card">
                <FaLocationDot className='div-sucursales-card-icon'/>
                Mazatlán
              </div>
            </Col>
            <Col xs={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <div className="div-sucursales-card">
                <FaLocationDot className='div-sucursales-card-icon'/>
                Los Mochis
              </div>
            </Col>

            <Col xs={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <div className="div-sucursales-card">
                <FaLocationDot className='div-sucursales-card-icon'/>
                Tijuana
              </div>
            </Col>
          </Row>

          <Row style={{ textAlign: 'center', width: '80%', marginTop: 30 }}>
            <Col
              xs={{ span: 5, offset: 2 }}
              lg={{ span: 6, offset: 2 }}
              xl={{ span: 6, offset: 2 }}
            >
              <div className="div-sucursales-card">
                <FaLocationDot className='div-sucursales-card-icon'/>
                León
              </div>
            </Col>
            <Col xs={{ span: 5, offset: 2 }} lg={{ span: 6, offset: 1 }}>
              <div className="div-sucursales-card">
                <FaLocationDot className='div-sucursales-card-icon'/>
                Guadalajara
              </div>
            </Col>
            <Col
              xs={{ span: 5, offset: 2 }}
              lg={{ span: 6, offset: 1 }}
              xl={{ span: 6, offset: 1 }}
            >
              <div className="div-sucursales-card">
                <FaLocationDot className='div-sucursales-card-icon'/>
                Cancún
              </div>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </center>
      </div>

      <div>
        <center>
          <p
            className="font-title-nosotros-equipo"
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontFamily: 'Red Hat Display, sans-serif',
              marginTop: 20,
              textAlign: 'center',
            }}
          >
            Corporativo
          </p>

          <p
            className="font-title-nosotros2-equipo"
            style={{
              color: 'black',
              fontWeight: 'bold',
              marginTop: -40,
              textAlign: 'center',
            }}
          >
            Blvd. Alfonso G. Calderón Velarde, No. 2656 int. 11 Desarrollo
            Urbano Tres Ríos, C.P. 80020, Culiacán, Sin.
          </p>

          <div style={{ width: '100%', height: 500 }}>
            <LoadScript
              googleMapsApiKey="AIzaSyCigpoxrVZWNXgGAO_cuij9jPGoicBvMGk" // Reemplaza con tu clave de API de Google Maps
            >
              <GoogleMap
                mapContainerStyle={mapStyles}
                center={direccionFija}
                zoom={16}
              >
                <Marker position={{lat: 24.799358059431732, lng: -107.43349426695617}} title="Mi Marcador" />
              </GoogleMap>
            </LoadScript>
          </div>
        </center>
      </div>

      <div className="contenedor-nosotros" style={{ marginBottom: 40, marginTop:-100 }}>
        <center>
          <p
            className="div-titulo-beneficios"
            style={{
              fontWeight: 'bold',
              fontFamily: 'Red Hat Display, sans-serif',
            }}
          >
            Nos respaldan las principales entidades regulatorias del país
          </p>

          <Row style={{ textAlign: 'center', width: '80%' }}>
            <Col
              xs={{ span: 5, offset: 2 }}
              lg={{ span: 6, offset: 2 }}
              xl={{ span: 6, offset: 2 }}
            >
              <img className='div-logos-entidades' src={logoUne}></img>
            </Col>
            <Col xs={{ span: 5, offset: 2 }} lg={{ span: 6, offset: 1 }}>
              <img className='div-logos-entidades' src={logoBuro}></img>
            </Col>
            <Col
              xs={{ span: 5, offset: 2 }}
              lg={{ span: 6, offset: 1 }}
              xl={{ span: 6, offset: 1 }}
            >
              <img className='div-logos-entidades' src={logoCondu}></img>
            </Col>
          </Row>

          <Row style={{ textAlign: 'center', width: '50%', marginTop: 40 }}>
            <Col xs={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
              <img className='div-logos-entidades2' src={logoCnbv}></img>
            </Col>
            <Col xs={{ span: 12 }} lg={{ span: 12 }}>
              <img className='div-logos-entidades' style={{ marginTop: 20 }} src={logoBc}></img>
            </Col>
          </Row>
        </center>
      </div>

      <div className="contenedor-imagen" >
        <div className="fondo" >{/* Tu imagen de fondo */}</div>
        <div className="overlay-imagen" style={{overflow:"clip"}} >
          {/* Tu contenido dentro del overlay (puedes agregar más elementos aquí) */}

          <Row style={{ width: '100%', marginTop: 20 }}>
            <Col flex="1 1 600px" >
              <Row>
                <Col>
                  <img className="logo-footer" src={logo}></img>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div
                    className="div-footer-adress"
                    style={{ color: 'white', marginLeft: 20, width:"80%" }}
                  >
                    Blvd. Alfonso G. Calderón Velarde, No. 2656 int. 11
                    Desarrollo Urbano Tres Ríos, C.P. 80020, Culiacán, Sin.
                  </div>
                </Col>
              </Row>
            </Col>

            <Col flex="1 1 300px" >
              <div className='div-col-enlaces'>
                <Row align="middle">
                  <Col>
                    <div
                      className="div-footer-adress"
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: 18,
                        marginTop: 30
                      }}
                    >
                      ENLACES
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      className="div-footer-adress"
                      style={{ color: 'white', marginTop: 10 }}
                    >
                      <a
                        onClick={() => handleNext('/')}
                        className="footer-link"
                      >
                        Home
                      </a>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div
                      className="div-footer-adress"
                      style={{ color: 'white', marginTop: 10 }}
                    >
                      <a
                        onClick={() => handleNext('/nosotros')}
                        className="footer-link"
                      >
                        Nosotros
                      </a>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div
                      className="div-footer-adress"
                      style={{ color: 'white', marginTop: 10 }}
                    >
                      <a
                        onClick={() => handleNext('/terminos_condiciones')}
                        className="footer-link"
                      >
                        Términos y condiciones
                      </a>
                    </div>
                  </Col>
                </Row>

                <Row>
                <Col>
                    <div
                      className="div-footer-adress"
                      style={{ color: 'white', marginTop: 10 }}
                    >
                      <a
                        onClick={() => handleNext('/aviso_privacidad')}
                        className="footer-link"
                      >
                        Aviso de Privacidad
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col flex="1 1 300px" >
              <div className='div-col-enlaces' >
                <Row>
                  <Col>
                    <div
                      className="div-footer-adress"
                      style={{ color: 'white', marginTop: 30, fontWeight:"bold" }}
                    >
                      INFORMACIÓN DE CONTACTO
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      className="footer-link"
                      style={{ color: 'white', marginTop: 10 }}
                    >
                      <FaHouse style={{color:"#fff"}} /> Culiacán, Sinaloa, México
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div
                      className="footer-link"
                      style={{ color: 'white', marginTop: 10 }}
                    >
                     <FaEnvelope /> marketing@finamo.mx
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div
                      className="footer-link"
                      style={{ color: 'white', marginTop: 10 }}
                    >
                      <FaPhone /> Llámanos al 667 980 0438
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div
                      className="footer-link"
                      style={{ color: 'white', marginTop: 10 }}
                    >
                      <FaWhatsapp /> Whatsapp al 667 751 7699
                      
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row style={{ width: '100%', marginTop: 30 }}>
          <Col flex="1 1 300px" offset={0} style={{marginLeft:20}}>
              <FacebookOutlined
                style={LogosStyle}
                onClick={() => handleLink('https://www.facebook.com/FinamoMX/')}
              />
              {/* &nbsp;&nbsp;
              <TwitterOutlined
                style={LogosStyle}
                onClick={() => handleLink('https://twitter.com/Finamo_MX')}
              /> */}
              &nbsp;&nbsp;
              <LinkedinOutlined
                style={LogosStyle}
                onClick={() =>
                  handleLink('https://www.linkedin.com/company/finamomx/')
                }
              />
              
              &nbsp;&nbsp;
              <InstagramOutlined
                style={LogosStyle}
                onClick={() =>
                  handleLink(
                    'https://www.instagram.com/finamomx/?utm_medium=copy_link'
                  )
                }
              />
              &nbsp;&nbsp;
            </Col>
          </Row>

          <center>
            <div className="footer-link" style={{marginTop:100, overflow:"auto"}}>
              © 2023 Copyright: finamo.mx
            </div>
          </center>

          {/* <center>
            <Row justify="center" style={{ width: 350, marginTop: 40 }}>
              <Col flex="1 1 100px">
                <img src={logoBuro} style={{ width: 100 }}></img>
              </Col>
              <Col flex="1 1 100px">
                <img src={logoBc} style={{ width: 100 }}></img>
              </Col>
            </Row>
          </center> */}
        </div>
      </div>
    </div>
  );
};

export default AvisoPrivacidad;
