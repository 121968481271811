

const getAvalEmail = (valor) => {
  const URL = process.env.REACT_APP_URL_API_EXPRESS;
  const resp = fetch(`${URL}aval/email/${valor}`, {
    method: "GET",
    
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const postUsuariosAval = (json) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}usuarios/aval`, {
    method: "POST",
    headers: {
      'Content-Type':'application/json',
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const AvalService = {
  getAvalEmail,
  postUsuariosAval
};

export default AvalService;
