import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import '../../Welcome.css';
import {
  Checkbox,
  Typography,
  Button,
  Select,
  Input,
  Form,
  Row,
  Col,
  Divider,
  Skeleton,
  InputNumber,
  Slider,
  Radio,
  Tooltip,
} from 'antd';
import {
  PlusOutlined,
  UserOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { FaFile } from 'react-icons/fa6';

import imagen1 from '../../../asset/images/revision.png';

import InputCustom from '../../../componentes/InputCustom';
import { ComponentesContext } from '../../../contextos/componentesContext';

import AuthService from '../../../services/auth.service';

const Revision = () => {
  const {
    UpdateFooterText,
    ModalMessage,
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    loading,
    UpdatecurrentStep,
    InputTypes,
    UpdateFlagtimeLineForm,
    UpdatemovilSteps,
    UpdateFlagtimeLineFormMovil,
    UpdateFlagtimeLineFormProductos,
    pendientesAval,
    UpdateFlagtimeLineFormAval,
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const { Title } = Typography;
  const [form] = Form.useForm();
  const dateFormat = 'DD-MM-YYYY';
  const location = useLocation();

  const [solicitudId, SetsolicitudId] = useState(
    typeof location.state.record.solicitudId != 'undefined'
      ? location.state.record.solicitudId != null
        ? location.state.record.solicitudId
        : '0'
      : '0'
  );

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 200,
    heightImage: window.innerHeight - 185,
    heightScroll: (window.innerHeight * 54) / 100,
  });

  const [valueRegimen, setvalueRegimen] = useState(1);
  const [PfTooltip, setPfTooltip] = useState('');
  const [PmTooltip, setPmTooltip] = useState('');

  const [PfDisabled, setPfDisabled] = useState(false);
  const [PmDisabled, setPmDisabled] = useState(false);

  const [PfaeisVisible, setPfaeisVisible] = useState(true);
  const [PmisVisible, setPmisVisible] = useState(false);

  const contentStyle = {
    height: windowSize.height,
    minHeight: 400,
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
    borderRadius: 15,
  };

  const optionsEstados = [
    {
      value: 'Sinaloa',
      label: 'Sinaloa',
    },
    {
      value: 'Sonora',
      label: 'Sonora',
    },
  ];

  useEffect(() => {
    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      console.log('entro');
      AuthService.logout();

      navigate('/login', {
        state: { record: { correo: '' } },
      });
    } else {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 200,
          heightImage: window.innerHeight - 185,
          heightScroll: (window.innerHeight * 54) / 100,
        });
      };

      if (pendientesAval) {
        UpdateFlagtimeLineFormMovil(false);
        UpdateFlagtimeLineForm(false);
        UpdateFlagtimeLineFormAval(true);
        UpdateFlagtimeLineFormProductos(false);
      } else {
        UpdateFlagtimeLineForm(true);
        UpdateFlagtimeLineFormMovil(true);
        UpdateFlagtimeLineFormAval(false);
        UpdateFlagtimeLineFormProductos(true);
      }

      UpdatebuttonIngresar(
        'dashboard',
        usr.primerNombre + ' ' + usr.primerApellido
      );
      UpdateuserAvatar('dashboard');
      UpdatecurrentStep(10);
      UpdatemovilSteps(8);
      UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');

      setTimeout(function () {
        form.setFieldsValue({
          inversionista: {
            codRegFiscal: 1,
          },
        });
      }, 0);

      window.addEventListener('resize', handleResize);
      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const handleNext = (option) => {
    navigate(option, {
      state: { record: { solicitudId: solicitudId } },
    });
  };

  const onFinish = () => {
    handleNext('/datos_empresa');
  };

  const onChange = () => {};

  const handleBack = () => {
    handleNext('/domicilio_localizacion');
  };

  const optionsDestino = [
    {
      value: 'Sinaloa',
      label: 'Sinaloa',
    },
    {
      value: 'Sonora',
      label: 'Sonora',
    },
  ];

  const marks = {
    12: '12',
    24: '24',
    36: '36',
  };

  const marksMontos = {
    500000: '$500,000',
    800000: '$800,000',
    1000000: '$1000,000',
    1200000: '$1200,000',
    1500000: '$1500,000',
  };

  const showConfirm = (e) => {
    if (e.target.value == 1) {
      setPfaeisVisible(true);
      setPmisVisible(false);
    } else {
      setPfaeisVisible(false);
      setPmisVisible(true);
    }

    // if (statusRFC == "0") {
    //   confirm({
    //     title:
    //       "El inversionista ya esta registrado, esta segur@ de cambiar su régimen fiscal?",
    //     icon: <ExclamationCircleFilled />,
    //     onOk() {
    //       onChange(e);
    //     },
    //     onCancel() {
    //       var val = 1;
    //       if (e.target.value == 1) {
    //         val = 2;
    //       } else {
    //         val = 1;
    //       }
    //       form.setFieldsValue({
    //         inversionista: {
    //           codRegFiscal: val,
    //         },
    //       });
    //     },
    //   });
    // } else {
    //   onChange(e);
    // }
  };

  const handleContrasena = () => {};

  return (
    <div
      style={{
        backgroundColor: '#fff',
        paddingBottom: 50,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        height: windowSize.height,
      }}
    >
      <center>
        <Divider>
          <div className="title-steps-revision" style={{ color: '#4c4cbc' }}>
            Tu solicitud está en revisión
          </div>
        </Divider>

        <img className="img-revision" src={imagen1}></img>

        <div className="subtitle-steps-revision" style={{ color: '#4c4cbc' }}>
          Tu solicitud se ha registrado y está siendo revisada por nuestro
          equipo de especialistas, te daremos a conocer el resultado lo más
          pronto posible por correo electrónico.
        </div>
      </center>

      <div
        className="div-buttons-steps-buro"
        style={{ marginTop: 25, paddingLeft: 20, marginBottom: -30 }}
      >
        <Button
          className="buttons-back-forward"
          type="primary"
          onClick={() => {
            if (pendientesAval) {
              handleNext('/pendientes_firma');
            } else {
              handleNext('/solicitudes_activas');
            }
          }}
          htmlType="submit"
        >
          Finalizar <LogoutOutlined />
        </Button>
      </div>
    </div>
  );
};

export default Revision;
