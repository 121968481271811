import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import './SolicitudCambioContra.css';
import './Welcome.css';
import { Carousel, Typography, Button, Collapse, Input } from 'antd';
import {
  PlusOutlined,
  UserOutlined,
  LoginOutlined,
  MailOutlined,
} from '@ant-design/icons';

import imagen1 from '../asset/images/doc.png';

import { ComponentesContext } from '../contextos/componentesContext';

import UsuariosService from '../services/usuarios.Service';
import AuthService from '../services/auth.service';



const SolicitudCambioContrasena = () => {
  const {
    ModalMessage,
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    UpdateFlagtimeLineForm,
    CloseSession,
    ShowLoading,
    UpdateFooterText,
    UpdateFlagtimeLineFormAval
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const { Title } = Typography;

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 100,
    heightImage: window.innerHeight - 185,
  });

  const [Correo, setCorreo] = useState('');

  const contentStyle = {
    height: windowSize.height,
    minHeight: 400,
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
    borderRadius: 15,
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight - 100,
        heightImage: window.innerHeight - 185,
      });
    };

    UpdatebuttonIngresar('create');
    UpdateuserAvatar('');
    UpdateFlagtimeLineForm(false);
    UpdateFlagtimeLineFormAval(false);
    UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');

    window.addEventListener('resize', handleResize);
    //window.addEventListener("scroll", handleScroll);
    return () => {
      //window.removeEventListener("scroll", handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleNext = async (option) => {
    var regEXCorreo = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;

    if (Correo == '') {
      ModalMessage('Mensaje', 'El correo no puede esta en blanco.');
      return false;
    }

    if (!regEXCorreo.test(Correo)) {
      ShowLoading(false);
      ModalMessage(
        'Mensaje',
        'El correo no tiene un formato valido, por favor verifique.'
      );
      return false;
    }

    ShowLoading(true);
    let respuesta = await UsuariosService.getRestablecerPassword(Correo);
    ShowLoading(false);

    //console.log('solicitudes', respuesta);

    

    if (respuesta.result != true) {

      if (respuesta.codigoError == "00004") {
        ModalMessage('Error', 'El usuario ingresado no existe.');
        ShowLoading(false);
        return false;
      }

    

      let respuestaSesion = await AuthService.getValidaSesion();
      //console.log('respuesta dash validacion', respuestaSesion);
      if (respuestaSesion.result == false) {
        ShowLoading(false);
        CloseSession();
        return false;
      }
      ShowLoading(false);
      ModalMessage('Error', 'Se produjo un error al cargar las solicitudes.');
      return false;
    } else {
      navigate(option, {
        state: { record: { correo: Correo } },
      });
    }
  };

  return (
    <div>
      <table
        width={'100%'}
        style={{
          borderRadius: 15,
          backgroundColor: '#ffffff',
          borderWidth: 0,
          borderStyle: 'none',
          marginTop: -25,
        }}
      >
        <tbody>
          <tr>
            <td style={{ width: 0 }}>
              <table
                className="div-panel-picture"
                cellPadding={0}
                cellSpacing={0}
                border={0}
                style={{ height: windowSize.height }}
              >
                <tbody>
                  <tr>
                    <td style={{ paddingLeft: 20 }}>
                      <p>
                        <h5
                          style={{
                            textAlign: 'left',
                            marginTop: -5,
                            fontSize: 17,
                          }}
                        >
                          Solicita tu Arrendamiento
                        </h5>
                      </p>
                      <p>
                        La precalificación es el primer paso de la solicitud
                        para una línea de arrendamiento.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: 'bottom' }}>
                      <img src={imagen1} style={{ width: 350 }}></img>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>

            <td style={{ verticalAlign: 'top' }}>
              <center>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <table
                  cellPadding={0}
                  cellSpacing={0}
                  style={{
                    width: '60%',
                    borderRadius: 15,
                    marginLeft: 0,

                  }}
                  border={0}
                  className='div-card-cambio'
                >
                  <tbody>
                    <tr>
                      <td
                        colSpan={3}
                        style={{
                          textAlign: 'center',
                          //borderTop: 'solid #7272cb 1px',
                          borderRadius: '15px 15px 0 0',
                          //borderLeft: 'solid #7272cb 1px',
                          //borderRight: 'solid #7272cb 1px',
                        }}
                      >
                        <p>
                          <br></br>
                          <Title
                            level={2}
                            style={{ marginTop: -5, color: '#4c4cbc' }}
                          >
                            Solicitud de cambio de contraseña
                          </Title>
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td
                        colSpan={3}
                        style={{
                          textAlign: 'center',
                          height: 50,
                          //borderLeft: 'solid #7272cb 1px',
                          //borderRight: 'solid #7272cb 1px',
                        }}
                      >
                        <Input
                          value={Correo}
                          onChange={(e) => {
                            setCorreo(e.target.value);
                          }}
                          className="input-cambio"
                          placeholder="Correo Electronico"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td
                        colSpan={3}
                        style={{
                          textAlign: 'center',
                          //borderLeft: 'solid #7272cb 1px',
                          //borderRight: 'solid #7272cb 1px',
                        }}
                      >
                        <p>
                          <Title
                            level={5}
                            style={{ marginTop: -5, color: '#4c4cbc' }}
                          >
                            Por tu seguridad enviaremos un código al correo
                            proporcionado.
                          </Title>
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td
                        colSpan={3}
                        style={{
                          textAlign: 'center',
                          height: 100,
                          //borderBottom: 'solid #7272cb 1px',
                          //borderLeft: 'solid #7272cb 1px',
                          //borderRight: 'solid #7272cb 1px',
                          borderRadius: '0px 0px 15px 15px',
                        }}
                      >
                        <Button
                          type="primary"
                          size={'default'}
                          style={{
                            backgroundColor: '#4c4cbc',
                            height: 40,
                            fontWeight: 'bold',
                            boxShadow: '0px -1px 10px 0px rgba(0,0,0,0.20)',

                          }}
                          onClick={() => handleNext('/codevalidation_password')}
                          className="account-welcome-btn-create"
                        >
                          <MailOutlined style={{ fontSize: '18px' }} />{' '}
                          {' Enviar '}
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br></br>
                <br></br>
                <br></br>
              </center>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SolicitudCambioContrasena;
