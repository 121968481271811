import { Row, Col } from 'antd';
import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PlusOutlined, UserOutlined, ArrowLeftOutlined, ArrowRightOutlined, IdcardOutlined } from "@ant-design/icons";
import { FaCheck } from "react-icons/fa6";

import FormulariosService from "../services/formularios.Service";
import AuthService from "../services/auth.service";
import { ComponentesContext } from "../contextos/componentesContext"



export default function UploadButtonDocuments({ ...props }) {
  const navigate = useNavigate();
  const location = useLocation();

  const { tipoDocumentoSeleccionado, UpdateTipoDocumentoSeleccionado, intSolicitudId, ShowLoading } = useContext(ComponentesContext);

  const [archivoAdjunto, setArchivoAdjunto] = useState(null);

  const regresaIconoMarca = (status = 0) => {

    if (status == 0) {
      return (<PlusOutlined style={{ marginTop: 10 }} />);
    }
    else {
      return (<FaCheck style={{ marginTop: 10 }} />);
    }
  }

  const regresaColorMarca = (status = 0) => {

    if (status == 0) {
      return "#4c4cbc";
    }
    else {
      return "#04bb73";
    }
  }


  const handleFileChange = async(event, tipoDocumento) => {
    //console.log("prueba");
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append('documento', file);

    const URL = process.env.REACT_APP_URL_API_FORANEOS;

    ShowLoading(true);
    
    fetch(`${URL}solicitudes/formularios/documentos?solicitudId=${intSolicitudId}&tipoDocumento=${tipoDocumentoSeleccionado}`, {
      method: 'POST',
      headers: {
        "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
      },
      body: formData, 
    })
      .then((response) => response.json())
      .then((data) => {
        // Manejar la respuesta aquí
        //console.log('Respuesta:', data);
        ShowLoading(false);

        window.location.reload();
        /*
        navigate("/documentos", {
          state: { record: { solicitudId: intSolicitudId } },
        });
        */

      })
      .catch((error) => {
        // Manejar errores
        ShowLoading(false);
        console.error('Error al enviar la solicitud:', error);
      });

    //let respuesta = await FormulariosService.postSubirDocumento(json);
    //console.log(respuesta);

    setArchivoAdjunto(file);
    //console.log("entro");
    if (archivoAdjunto) {
      
    }
    
    // Puedes hacer algo con el archivo seleccionado aquí, como enviarlo al servidor.
    
  };

  const handleUploadClick = async (uploadStatus, tipo) => {

    //console.log("documento seleccionado",tipo);

    UpdateTipoDocumentoSeleccionado(tipo)
  
    if(uploadStatus==0){
      const fileInput = document.getElementById('fileInput');
      fileInput.click();
    }

  };

  return (
    <>
<a onClick={() =>( handleUploadClick(props.status, props.tipo) )}>
      <div style={{ width: 320, height: 150 }}>
        <div style={{ height: 100, width: 300, color: "#4c4cbc", fontWeight: "bold", backgroundColor: "#f2f2f7", fontSize: 20, borderRadius: 20, boxShadow: "3px 7px 3px -2px rgba(165,172,172,0.75)", paddingTop: 10 }} >
          <Row>
            <Col>{props.icono}</Col>
            <Col><div style={{ textAlign: "left", marginTop: 20, width: 210, fontSize: 18, marginLeft: 18 }}>{props.etiqueta}</div></Col>
          </Row>
          <div style={{ height: 40, width: 40, color: "#fff", fontWeight: "bold", backgroundColor: regresaColorMarca(props.status), fontSize: 20, borderRadius: 120, marginTop: 0, marginLeft: 265 }} >{regresaIconoMarca(props.status)}</div>
        </div>
      </div>
      
    </a>
<input
        type="file"
        id="fileInput"
        name={props.tipo}
        style={{ display: 'none' }}
        onChange={ (e, id ) =>( handleFileChange(e, props.tipo ) ) }
      />
    </>
    
  )
}