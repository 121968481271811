import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Typography,
  Button,
  Form,
  Row,
  Col,
  Divider,
  Skeleton,
  Input,
} from 'antd';

import {
  MenuItem,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  TextField,
  Autocomplete,
} from '@mui/material';

import TextFieldCustom from '../../../componentes/TextFieldCustom';

import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import InputCustom from '../../../componentes/InputCustom';
import { ComponentesContext } from '../../../contextos/componentesContext';
import '../../Welcome.css';
import './DatosContacto.css';

import AuthService from '../../../services/auth.service';
import FormulariosService from '../../../services/formularios.Service';

const DatosContacto = () => {
  const {
    UpdateFooterText,
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    loading,
    UpdatecurrentStep,
    InputTypes,
    UpdateFlagtimeLineForm,
    UpdatemovilSteps,
    CloseSession,
    ModalMessage,
    ShowLoading,
    UpdateFlagtimeLineFormMovil,
    UpdateFlagtimeLineFormProductos,
    UpdateFlagtimeLineFormAval,
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();

  const [UserEmail, setUserEmail] = useState('');

  const [solicitudId, SetsolicitudId] = useState(
    typeof location.state.record.solicitudId != 'undefined'
      ? location.state.record.solicitudId != null
        ? location.state.record.solicitudId
        : '0'
      : '0'
  );

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 190,
    heightImage: window.innerHeight - 185,
    heightScroll: (window.innerHeight * 60) / 100,
  });

  const [DisableForm, setDisableForm] = useState(false);

  const [formCorreo, setformCorreo] = useState('');
  const [formCelular, setformCelular] = useState('');
  const [formTelefono, setformTelefono] = useState('');
  const [formCorreoResponsablePago, setformCorreoResponsablePago] =
    useState('');
  const [formCorreoFactura, setformCorreoFactura] = useState('');
  const [formTelefonoOficina, setformTelefonoOficina] = useState('');
  const [formExtensionOficina, setformExtensionOficina] = useState('');

  const [flagclienteTipoRegimenFiscalId, setclienteTipoRegimenFiscalId] =
    useState(0);

  const [urlSiguiente, seturlSiguiente] = useState('/datos_aval');

  useEffect(() => {
    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      //console.log('entro');
      AuthService.logout();

      navigate('/login', {
        state: { record: { correo: '' } },
      });
    } else {
      setUserEmail(usr.email);

      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 200,
          heightImage: window.innerHeight - 185,
          heightScroll: (window.innerHeight * 60) / 100,
        });
      };

      InicializaFormulario();
      UpdateFlagtimeLineFormMovil(true);
      UpdateFlagtimeLineForm(true);
      UpdateFlagtimeLineFormProductos(true);
      UpdateFlagtimeLineFormAval(false);
      UpdatebuttonIngresar(
        'dashboard',
        usr.primerNombre + ' ' + usr.primerApellido
      );
      UpdateuserAvatar('dashboard');
      UpdatecurrentStep(4);
      UpdatemovilSteps(4);
      UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');

      setTimeout(function () {
        form.setFieldsValue({
          inversionista: {
            codRegFiscal: 1,
          },
        });
      }, 0);

      window.addEventListener('resize', handleResize);
      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const InicializaFormulario = async () => {
    ShowLoading(true);

    var respuesta = {};
    var respuestaDatosCliente = {};
    if (solicitudId == 0) {
      ShowLoading(false);
      ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      return false;
    } else {
      respuestaDatosCliente =
        await FormulariosService.getInformacionCliente(solicitudId);
      respuesta = await FormulariosService.getDatosContactos(solicitudId);
    }

    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      }
      return false;
    }

    if (respuesta.data.length == 0) {
      ShowLoading(false);
      return false;
    }

    setDisableForm(!respuesta.data.habilitado);

    //console.log('DATOS CONTACTO', respuesta);
    //console.log('DATOS CLIENTE', respuestaDatosCliente);

    setclienteTipoRegimenFiscalId(
      respuestaDatosCliente.data.clienteTipoRegimenFiscalId
    );


    //console.log("flagclienteTipoRegimenFiscalId",flagclienteTipoRegimenFiscalId);
    
    if (respuestaDatosCliente.data.clienteTipoRegimenFiscalId == 1) {
      seturlSiguiente('/autorizacion_sat');
    } else {
      seturlSiguiente('/datos_aval');
    }

    setUserEmail(respuestaDatosCliente.data.clienteEmail);
    setformCorreo(
      respuesta.data.email == ''
        ? respuestaDatosCliente.data.clienteEmail
        : respuesta.data.email
    );
    setformCelular(
      respuesta.data.celular == ''
        ? respuestaDatosCliente.data.clienteCelular
        : respuesta.data.celular
    );
    setformTelefono(respuesta.data.telefono);
    setformCorreoResponsablePago(respuesta.data.emailPagos=="" ? respuestaDatosCliente.data.clienteEmail : respuesta.data.emailPagos  );
    setformCorreoFactura(respuesta.data.emailFacturas=="" ? respuestaDatosCliente.data.clienteEmail : respuesta.data.emailFacturas);
    setformTelefonoOficina(respuesta.data.telefonoOficina);
    setformExtensionOficina(respuesta.data.extensionOficina);

    setTimeout(function () {
      form.setFieldsValue({
        datosContacto: {
          correo:
            respuesta.data.email == ''
              ? respuestaDatosCliente.data.clienteEmail
              : respuesta.data.email,
          celular:
            respuesta.data.celular == ''
              ? respuestaDatosCliente.data.clienteCelular
              : respuesta.data.celular,
          telefono: respuesta.data.telefono,
          correoResponsable: respuesta.data.emailPagos,
          correoFacturas: respuesta.data.emailFacturas,
          telefonoOficina: respuesta.data.telefonoOficina,
          extensionOficina: respuesta.data.extensionOficina,
        },
      });
    }, 0);

    ShowLoading(false);
  };

  const handleSkipView = async () => {
    let respuesta = await FormulariosService.getValidaCapturaFirma(
      solicitudId,
      'cliente'
    );

    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      }
      return false;
    }

    //console.log('estado firma sat', respuesta.data.estado);

    if (flagclienteTipoRegimenFiscalId == 1) {
      if (respuesta.data.estado == 1 || respuesta.data.estado == 3) {
        let respuestaBuro = await FormulariosService.getValidaCapturaFirmaBuro(
          solicitudId,
          'cliente'
        );

        if (respuestaBuro.result != true) {
          ShowLoading(false);
          let respuestaSesion = await AuthService.getValidaSesion();

          if (respuestaSesion.result == false) {
            CloseSession();
          } else {
            ModalMessage(
              'Error',
              'Se produjo un error al cargar el formulario.'
            );
          }
          return false;
        }

        if (respuestaBuro.data.estado != 0) {
          navigate('/revision', {
            state: { record: { solicitudId: solicitudId } },
          });
        } else {
          navigate('/firma_buro', {
            state: { record: { solicitudId: solicitudId } },
          });
        }

        ShowLoading(false);
        return false;
      } else {
        navigate('/autorizacion_sat', {
          state: { record: { solicitudId: solicitudId } },
        });
      }
    } else {
      navigate('/datos_aval', {
        state: { record: { solicitudId: solicitudId } },
      });
    }
  };

  const handleNext = async (option) => {
    ShowLoading(true);

    var reg = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;

    if (formCorreo == '') {
      ShowLoading(false);
      ModalMessage(
        'Mensaje',
        'El Correo de contacto no puede estar en blanco.'
      );
      return false;
    }

    if (!reg.test(formCorreo)) {
      ShowLoading(false);
      ModalMessage(
        'Mensaje',
        'El Correo de contacto no tiene un formato valido, por favor verifique.'
      );
      return false;
    }

    if (formCelular == '') {
      ShowLoading(false);
      ModalMessage(
        'Mensaje',
        'El celular de contacto no puede estar en blanco.'
      );
      return false;
    }

    if (formCelular.length < 10) {
      ShowLoading(false);
      ModalMessage(
        'Mensaje',
        'El celular de contacto no tiene un formato valido.'
      );
      return false;
    }

    if (formTelefono != '') {
      if (formTelefono.length < 10) {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'El teléfono de contacto no tiene un formato valido.'
        );
        return false;
      }
    }

    if (formCorreoResponsablePago == '') {
      ShowLoading(false);
      ModalMessage(
        'Mensaje',
        'El Correo del responsable de pagos no puede estar en blanco.'
      );
      return false;
    }

    if (!reg.test(formCorreoResponsablePago)) {
      ShowLoading(false);
      ModalMessage(
        'Mensaje',
        'El Correo de responsable de pago no tiene un formato valido, por favor verifique.'
      );
      return false;
    }

    if (formCorreoFactura == '') {
      ShowLoading(false);
      ModalMessage(
        'Mensaje',
        'El Correo para envío de facturas no puede estar en blanco.'
      );
      return false;
    }

    if (!reg.test(formCorreoFactura)) {
      ShowLoading(false);
      ModalMessage(
        'Mensaje',
        'El Correo para envío de facturas no tiene un formato valido, por favor verifique.'
      );
      return false;
    }

    if (formTelefonoOficina == '') {
      ShowLoading(false);
      ModalMessage(
        'Mensaje',
        'El teléfono de oficina no puede estar en blanco.'
      );
      return false;
    }

    if (formTelefonoOficina != '') {
      if (formTelefonoOficina.length < 10) {
        ShowLoading(false);
        ModalMessage(
          'Mensaje',
          'El teléfono de oficina no tiene un formato válido.'
        );
        return false;
      }
    }

    const json = {
      solicitudId: solicitudId,
      email: formCorreo,
      celular: formCelular,
      telefono: formTelefono,
      emailPagos: formCorreoResponsablePago,
      emailFacturas: formCorreoFactura,
      telefonoOficina: formTelefonoOficina,
      extensionOficina: formExtensionOficina,
    };

    if (DisableForm == false) {
      let respuesta = await FormulariosService.postGuardaDatosContactos(json);

      if (respuesta.result != true) {
        ShowLoading(false);
        let respuestaSesion = await AuthService.getValidaSesion();

        if (respuestaSesion.result == false) {
          CloseSession();
        } else {
          ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
        }
        return false;
      }
    }

    ShowLoading(false);
    //console.log(json);

    // navigate(option, {
    //   state: { record: { solicitudId: solicitudId } },
    // });z

    handleSkipView();
  };

  // const onFinish = () => {
  //   handleNext('/datos_empresa');
  // };

  const handleBack = () => {
    navigate('/domicilio_localizacion', {
      state: { record: { solicitudId: solicitudId } },
    });
    //handleNext("/domicilio_localizacion");
  };

  return (
    <div
      style={{
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: 450,
      }}
    >
      <div
        className="div-img-arrendamiento"
        style={{
          borderRadius: 25,
          padding: 0,
          boxShadow: '0px 0px 22px -10px rgba(0,0,0,0.75)',
          marginBottom: 0,
          background: '#fff',
          marginTop: 30,
          width: windowSize.width - 200,
          minHeight: windowSize.height + 20,
          minWidth: 370,
        }}
      >
        <center>
          <Divider>
            <div
              className="title-steps"
              style={{
                margin: 0,
                color: '#4c4cbc',
                marginTop: 0,
                marginBottom: 40,
              }}
            >
              Datos de contacto
            </div>
          </Divider>
        </center>

        <Skeleton loading={loading} active={true}>
          <div
            style={{
              overflow: 'auto',
              maxHeight: 590,
              width: '99%',
              overflowX: 'hidden',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Row style={{ width: '90%', marginTop: 8 }}>
                <Col span={24}>
                  <TextField
                    style={{ width: '100%', marginBottom: 10 }}
                    label={
                      <>
                        <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                        <label>Correo de contacto</label>
                      </>
                    }
                    value={formCorreo}
                    onChange={(e) => setformCorreo(e.target.value)}
                    size="small"
                    variant="outlined"
                    disabled={DisableForm}
                  />
                </Col>
              </Row>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Row gutter={6} style={{ width: '90.5%' }}>
                <Col flex="1 1 400px">
                  <TextFieldCustom
                    inputProps={{ maxLength: 10 }}
                    style={{ width: '100%', marginBottom: 10 }}
                    label={
                      <div style={{ zIndex: 1 }}>
                        <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                        Celular de contacto
                      </div>
                    }
                    value={formCelular}
                    onChange={setformCelular}
                    inputtype={InputTypes.Integer}
                    disabled={DisableForm}
                  />
                </Col>

                <Col flex="1 1 400px">
                  <TextFieldCustom
                    inputProps={{ maxLength: 10 }}
                    style={{ width: '100%', marginBottom: 10 }}
                    label={
                      <div style={{ zIndex: 1 }}>Teléfono de contacto</div>
                    }
                    value={formTelefono}
                    onChange={setformTelefono}
                    inputtype={InputTypes.Integer}
                    disabled={DisableForm}
                  />
                </Col>
              </Row>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Row gutter={6} style={{ width: '90.5%' }}>
                <Col flex="1 1 400px">
                  <TextField
                    style={{ width: '100%', marginBottom: 10 }}
                    label={
                      <>
                        <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                        <label>Correo del responsable de pagos</label>
                      </>
                    }
                    value={formCorreoResponsablePago}
                    onChange={(e) =>
                      setformCorreoResponsablePago(e.target.value)
                    }
                    size="small"
                    variant="outlined"
                    disabled={DisableForm}
                  />
                </Col>

                <Col flex="1 1 400px">
                  <TextField
                    style={{ width: '100%', marginBottom: 10 }}
                    label={
                      <>
                        <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                        <label>Correo para envío de facturas</label>
                      </>
                    }
                    value={formCorreoFactura}
                    onChange={(e) => setformCorreoFactura(e.target.value)}
                    size="small"
                    variant="outlined"
                    disabled={DisableForm}
                  />
                </Col>
              </Row>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Row gutter={6} style={{ width: '90.5%' }}>
                <Col flex="1 1 400px">
                  <TextFieldCustom
                    inputProps={{ maxLength: 10 }}
                    style={{ width: '100%', marginBottom: 10 }}
                    label={
                      <div style={{ zIndex: 1 }}>
                        <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                        Teléfono de oficina
                      </div>
                    }
                    value={formTelefonoOficina}
                    onChange={setformTelefonoOficina}
                    inputtype={InputTypes.Integer}
                    disabled={DisableForm}
                  />
                </Col>

                <Col flex="1 1 400px">
                  <TextFieldCustom
                    inputProps={{ maxLength: 10 }}
                    style={{ width: '100%', marginBottom: 10 }}
                    label={
                      <div style={{ zIndex: 1 }}>Extensión de oficina</div>
                    }
                    value={formExtensionOficina}
                    onChange={setformExtensionOficina}
                    inputtype={InputTypes.Integer}
                    disabled={DisableForm}
                  />
                </Col>
              </Row>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                marginTop: 10,
                marginBottom:20
              }}
            >
              <Row gutter={6} style={{ width: '90%' }}>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <Button
                    className="buttons-back-forward"
                    type="primary"
                    onClick={() => handleBack()}
                    icon={<ArrowLeftOutlined />}
                  >
                    Atrás
                  </Button>
                </Col>

                <Col span={12} style={{ marginLeft: 0, textAlign: 'left' }}>
                  <Button
                    className="buttons-back-forward"
                    type="primary"
                    onClick={() => handleNext(urlSiguiente)}
                    htmlType="submit"
                  >
                    Continuar <ArrowRightOutlined />
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Skeleton>

        {/* <center> */}
        {/* <Skeleton loading={loading} active={true}>
            <div
              style={{
                overflow: 'auto',
                width: '100%',
                maxHeight: windowSize.heightScroll,
              }}
            > */}
        {/* <Form
                form={form}
                className="form-steps-contacto"
                style={{
                  marginTop: -5,
                  display: 'inline-block',
                }}
                name="horizontal_login"
                layout="inline"
                onFinish={onFinish}
                disabled={DisableForm}
              > */}
        {/* <Row>
                  <Col span={24} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosContacto', 'correo']}
                      label={''}
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message:
                            'Ingresa el correo electrónico del contacto.',
                        },
                        {
                          type: 'email',
                          message: 'Ingresa un correo electrónico válido.',
                        },
                      ]}
                    >
                      <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                      <label>Correo de contacto:</label>
                      <center>
                        <Input
                          value={formCorreo}
                          onChange={(e) => {
                            setformCorreo(e.target.value);
                          }}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row> */}

        {/* <Row gutter={20}>
                  <Col span={20} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosContacto', 'correo']}
                      label="Correo de contacto"
                      style={{ paddingLeft: 0 }}
                      rules={[
                        {
                          required: true,
                          message:
                            'Ingresa el correo electrónico del contacto.',
                        },
                        {
                          type: 'email',
                          message: 'Ingresa un correo electrónico válido.',
                        },
                      ]}
                    >
                      <center>
                        <Input
                          className="input-steps"
                          value={formCorreo}
                          onChange={(e) => {
                            setformCorreo(e.target.value);
                          }}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row> */}

        {/* <Row>
                  <Col flex="1 1 200px" style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosContacto', 'celular']}
                      label={''}
                      colon={false}
                      rules={[
                        { required: true, message: 'Ingresa el Celular' },
                      ]}
                    >
                      <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                      Celular de contacto:
                      <InputCustom
                        inputtype={InputTypes.Integer}
                        key={1}
                        value={formCelular}
                        onChange={setformCelular}
                        maxLength={10}
                      />
                    </Form.Item>
                  </Col>
                  <Col flex="1 1 200px" style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosContacto', 'telefono']}
                      label={''}
                      colon={false}
                    >
                      <label>Teléfono de contacto:</label>
                      <center>
                        <InputCustom
                          inputtype={InputTypes.Integer}
                          key={1}
                          value={formTelefono}
                          onChange={setformTelefono}
                          maxLength={10}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row> */}

        {/* <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosContacto', 'celular']}
                      label="Celular de contacto"
                      rules={[
                        { required: true, message: 'Ingresa el Celular' },
                      ]}
                    >
                      <InputCustom
                        inputtype={InputTypes.Integer}
                        key={1}
                        value={formCelular}
                        onChange={setformCelular}
                        maxLength={10}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosContacto', 'telefono']}
                      label="Teléfono de contacto"
                    >
                      <center>
                        <InputCustom
                          inputtype={InputTypes.Integer}
                          key={1}
                          value={formTelefono}
                          onChange={setformTelefono}
                          maxLength={10}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row> */}

        {/* <Row>
                  <Col span={24} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosContacto', 'correoResponsable']}
                      label={''}
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message:
                            'Ingresa el correo del responsable de pagos.',
                        },
                        {
                          type: 'email',
                          message: 'Ingresa un correo electrónico válido.',
                        },
                      ]}
                    >
                      <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                      <label>Correo del responsable de pagos:</label>
                      <center>
                        <Input
                          value={formCorreoResponsablePago}
                          onChange={(e) => {
                            setformCorreoResponsablePago(e.target.value);
                          }}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row> */}

        {/* <Row gutter={20}>
                  <Col span={20} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosContacto', 'correoResponsable']}
                      label="Correo del responsable de pagos"
                      style={{ paddingLeft: 0 }}
                      rules={[
                        {
                          required: true,
                          message:
                            'Ingresa el correo del responsable de pagos.',
                        },
                        {
                          type: 'email',
                          message: 'Ingresa un correo electrónico válido.',
                        },
                      ]}
                    >
                      <center>
                        <Input
                          className="input-steps"
                          value={formCorreoResponsablePago}
                          onChange={(e) => {
                            setformCorreoResponsablePago(e.target.value);
                          }}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row> */}

        {/* <Row>
                  <Col span={24} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosContacto', 'correoFacturas']}
                      label={''}
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message:
                            'Ingresa el correo del responsable de facturas.',
                        },
                        {
                          type: 'email',
                          message: 'Ingresa un correo electrónico válido.',
                        },
                      ]}
                    >
                      <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                      <label>Correo para envío de facturas:</label>
                      <center>
                        <Input
                          value={formCorreoFactura}
                          onChange={(e) => {
                            setformCorreoFactura(e.target.value);
                          }}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row> */}

        {/* <Row gutter={20}>
                  <Col span={20} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosContacto', 'correoFacturas']}
                      label="Correo para envío de facturas"
                      style={{ paddingLeft: 0 }}
                      rules={[
                        {
                          required: true,
                          message:
                            'Ingresa el correo del responsable de facturas.',
                        },
                        {
                          type: 'email',
                          message: 'Ingresa un correo electrónico válido.',
                        },
                      ]}
                    >
                      <center>
                        <Input
                          className="input-steps"
                          value={formCorreoFactura}
                          onChange={(e) => {
                            setformCorreoFactura(e.target.value);
                          }}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row> */}

        {/* <Row>
                  <Col flex="1 1 200px" style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosContacto', 'telefonoOficina']}
                      label={''}
                      colon={false}
                    >
                      <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                      Teléfono de oficina:
                      <InputCustom
                        inputtype={InputTypes.Integer}
                        key={1}
                        value={formTelefonoOficina}
                        onChange={setformTelefonoOficina}
                        maxLength={10}
                      />
                    </Form.Item>
                  </Col>
                  <Col flex="1 1 200px" style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosContacto', 'extensionOficina']}
                      label={''}
                      colon={false}
                    >
                      <label>Extensión de oficina:</label>
                      <center>
                        <InputCustom
                          inputtype={InputTypes.Integer}
                          key={1}
                          value={formExtensionOficina}
                          onChange={setformExtensionOficina}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row> */}

        {/* <Row gutter={5} style={{ marginLeft: 0 }}>
                  <Col span={12}>
                    <Form.Item
                      name={['datosContacto', 'telefonoOficina']}
                      label="Teléfono de oficina"
                    >
                      <InputCustom
                        inputtype={InputTypes.Integer}
                        key={1}
                        value={formTelefonoOficina}
                        onChange={setformTelefonoOficina}
                        maxLength={10}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosContacto', 'extensionOficina']}
                      label="Extensión de oficina"
                    >
                      <center>
                        <InputCustom
                          inputtype={InputTypes.Integer}
                          key={1}
                          value={formExtensionOficina}
                          onChange={setformExtensionOficina}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row> */}
        {/* </Form> */}
        {/* </div> */}

        {/* <div
              style={{
                width: windowSize.width - 200,
                height: windowSize.height + 20,
                minWidth: 500,
                marginBottom: 50,
                marginTop: 50,
              }}
            >
              <Row gutter={16}>
                <Col flex="1 1 200px" style={{ textAlign: 'right' }}>
                  <Button
                    className="buttons-back-forward"
                    type="primary"
                    onClick={() => handleBack()}
                    icon={<ArrowLeftOutlined />}
                  >
                    Atrás
                  </Button>
                </Col>
                <Col flex="1 1 200px" style={{ textAlign: 'left' }}>
                  <Button
                    className="buttons-back-forward"
                    type="primary"
                    onClick={() => handleNext(urlSiguiente)}
                    htmlType="submit"
                  >
                    Continuar <ArrowRightOutlined />
                  </Button>
                </Col>
              </Row>
            </div> */}
        {/* </Skeleton> */}
        {/* </center> */}
      </div>
    </div>
  );
};

export default DatosContacto;
