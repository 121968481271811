import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import InputCustom from '../../../componentes/InputCustom';
import SearchCustom from '../../../componentes/SearchCustom';
import { ComponentesContext } from '../../../contextos/componentesContext';
import '../../Welcome.css';
import './DomicilioLocalizacion.css';
import {
  Typography,
  Button,
  Select,
  Form,
  Row,
  Col,
  Divider,
  Skeleton,
  Radio,
  Tooltip,
  Segmented,
  ConfigProvider,
} from 'antd';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import {
  FaCircleQuestion,
  FaBuilding,
  FaUserTie,
  FaCheck,
  FaXmark,
} from 'react-icons/fa6';

import {
  MenuItem,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  TextField,
  Autocomplete,
} from '@mui/material';

import Paper from '@mui/material/Paper';

import AuthService from '../../../services/auth.service';
import SepomexService from '../../../services/sepomex.Service';
import FormulariosService from '../../../services/formularios.Service';

import TextFieldCustom from '../../../componentes/TextFieldCustom';

const DomicilioLocalizacion = () => {
  const {
    UpdateFooterText,
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    loading,
    UpdatecurrentStep,
    InputTypes,
    UpdateFlagtimeLineForm,
    UpdatemovilSteps,
    CloseSession,
    ModalMessage,
    ShowLoading,
    UpdateFlagtimeLineFormMovil,
    UpdateFlagtimeLineFormProductos,
    UpdateFlagtimeLineFormAval
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();

  const [DisableForm, setDisableForm] = useState(false);

  const [solicitudId, SetsolicitudId] = useState(
    typeof location.state.record.solicitudId != 'undefined'
      ? location.state.record.solicitudId != null
        ? location.state.record.solicitudId
        : '0'
      : '0'
  );

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 190,
    heightImage: window.innerHeight - 185,
    heightScroll: (window.innerHeight * 60) / 100,
  });

  const [formCargarDomicilioValue, setformCargarDomicilioValue] = useState(1);

  const [PfTooltip, setPfTooltip] = useState('');
  const [PmTooltip, setPmTooltip] = useState('');

  const [PfDisabled, setPfDisabled] = useState(false);
  const [PmDisabled, setPmDisabled] = useState(false);

  const [PfaeisVisible, setPfaeisVisible] = useState(true);
  const [PmisVisible, setPmisVisible] = useState(false);

  const [OptionsEstados, setOptionsEstados] = useState([
    {
      value: 'Sinaloa',
      label: <div className="lblOptionCombo">Sinaloa </div>,
    },
    {
      value: 'Sonora',
      label: <div className="lblOptionCombo">Sonora </div>,
    },
  ]);

  const [OptionsMunicipio, setOptionsMunicipio] = useState([
    {
      value: 'culiacan',
      label: <div className="lblOptionCombo">Culiacan </div>,
    },
    {
      value: 'navolato',
      label: <div className="lblOptionCombo">Navolato </div>,
    },
  ]);

  const [OptionsColonia, setOptionsColonia] = useState([
    {
      value: 'centro',
      label: <div className="lblOptionCombo">Centro </div>,
    },
    {
      value: 'miguel aleman',
      label: <div className="lblOptionCombo">Miguel Aleman </div>,
    },
  ]);

  const [formCp, setformCp] = useState('');
  const [formEstado, setformEstado] = useState('');
  const [formMunicipio, setformMunicipio] = useState('');
  const [formColonia, setformColonia] = useState('');
  const [formCalle, setformCalle] = useState('');
  const [formExt, setformExt] = useState('');
  const [formInt, setformInt] = useState('');

  const [disableSelectColonia, setenableSelectColonia] = useState(true);

  useEffect(() => {
    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      //console.log('entro');
      AuthService.logout();

      navigate('/login', {
        state: { record: { correo: '' } },
      });
    } else {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 190,
          heightImage: window.innerHeight - 185,
          heightScroll: (window.innerHeight * 60) / 100,
        });
      };

      InicializaFormulario();
      UpdateFlagtimeLineFormMovil(true);
      UpdateFlagtimeLineFormProductos(true);
      UpdateFlagtimeLineForm(true);
      UpdateFlagtimeLineFormAval(false);
      UpdatebuttonIngresar(
        'dashboard',
        usr.primerNombre + ' ' + usr.primerApellido
      );
      UpdateuserAvatar('dashboard');
      UpdatecurrentStep(3);
      UpdatemovilSteps(3);
      UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');

      setTimeout(function () {
        form.setFieldsValue({
          inversionista: {
            codRegFiscal: 1,
          },
        });
      }, 0);

      window.addEventListener('resize', handleResize);
      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const InicializaFormulario = async () => {
    ShowLoading(true);

    var respuesta = {};
    if (solicitudId == 0) {
      ShowLoading(false);
      ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      return false;
    } else {
      respuesta =
        await FormulariosService.getInformacionDomicilioLocalizacion(
          solicitudId
        );
    }

    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      }
      return false;
    }

    if (respuesta.data.length == 0) {
      ShowLoading(false);
      return false;
    }

    setDisableForm(!respuesta.data.habilitado);

    //console.log(respuesta);

    setformCp(respuesta.data.solicitudCodigoPostal);

    const optionEstadosSepomex = [
      {
        value: parseInt(respuesta.data.solicitudEstado.id),
        label: respuesta.data.solicitudEstado.nombre,
      },
    ];
    setOptionsEstados(optionEstadosSepomex);
    setformEstado(parseInt(respuesta.data.solicitudEstado.id));

    const optionMunicipiosSepomex = [
      {
        value: parseInt(respuesta.data.solicitudMunicipio.id),
        label: respuesta.data.solicitudMunicipio.nombre,
      },
    ];
    setOptionsMunicipio(optionMunicipiosSepomex);
    setformMunicipio(parseInt(respuesta.data.solicitudMunicipio.id));

    const optionColoniasSepomex = respuesta.data.coloniaList.map((item) => ({
      value: parseInt(item.id), // Convierte el ID a una cadena
      label: item.nombre,
    }));
    setOptionsColonia(optionColoniasSepomex);

    var arrColonias = {};
    const filteredData = optionColoniasSepomex.filter(
      (item) =>
        parseInt(item.value) === parseInt(respuesta.data.solicitudColoniaId)
    );
    arrColonias = {
      value: respuesta.data.solicitudColoniaId, // Convierte el ID a una cadena
      label: filteredData[0].label,
    };

    setformColonia(arrColonias);

    setenableSelectColonia(false);

    setformCalle(respuesta.data.solicitudCalle);
    setformExt(respuesta.data.solicitudNumeroExterior);
    setformInt(respuesta.data.solicitudNumeroInterior);

    setTimeout(function () {
      form.setFieldsValue({
        domicilioLocalizacion: {
          calle: respuesta.data.solicitudCalle,
        },
      });
    }, 0);

    if (!respuesta.data.habilitado == true) {
      setenableSelectColonia(true);
      setPmDisabled(true);
      setPfDisabled(true);
    }

    ShowLoading(false);
  };

  const handleNext = async (option) => {
    if (formCp == '') {
      ModalMessage('Mensaje', 'El Codigo Postal no puede estar en blanco.');
      return false;
    }

    if (formColonia == null) {
      ModalMessage('Mensaje', 'La colonia no puede estar en blanco.');
      return false;
    }

    if (formCalle == '') {
      ModalMessage('Mensaje', 'La calle no puede estar en blanco.');
      return false;
    }

    if (formExt == '') {
      ModalMessage('Mensaje', 'El Número Exterior no puede estar en blanco.');
      return false;
    }

    const json = {
      solicitudId: solicitudId,
      codigoPostal: formCp,
      coloniaId: formColonia.value,
      calle: formCalle,
      numeroExterior: formExt,
      numeroInterior: formInt,
    };

    ShowLoading(true);

    if (DisableForm == false) {
      let respuesta =
        await FormulariosService.postGuardaDomicilioLocalizacion(json);
      if (respuesta.result != true) {
        ShowLoading(false);
        ModalMessage('Error', 'Se produjo un error al guardar el formulario.');
        return false;
      }

      ShowLoading(false);
    }

    ShowLoading(false);

    navigate(option, {
      state: { record: { solicitudId: solicitudId } },
    });
  };

  const onFinish = () => {
    handleNext('/datos_empresa');
  };

  const onChange = () => {};

  const handleBack = () => {
    navigate('/datos_empresa', {
      state: { record: { solicitudId: solicitudId } },
    });

    //handleNext("/datos_empresa");
  };

  const handeChangeCargarDomicilioFiscal = async (value) => {
    if (value == 1) {
      ShowLoading(true);

      var respuesta = {};
      if (solicitudId == 0) {
        ShowLoading(false);
        ModalMessage(
          'Error',
          'Se produjo un error al cargar el domicilio fiscal.'
        );
        return false;
      } else {
        respuesta = await FormulariosService.getInformacionEmpresa(solicitudId);
      }

      if (respuesta.result != true) {
        ShowLoading(false);
        let respuestaSesion = await AuthService.getValidaSesion();

        if (respuestaSesion.result == false) {
          CloseSession();
        } else {
          ModalMessage('Error', 'Se produjo un error al el domicilio fiscal.');
        }
        return false;
      }

      if (respuesta.data.length == 0) {
        ShowLoading(false);
        return false;
      }

      setformCp(respuesta.data.empresaCodigoPostal);

      const optionEstadosSepomex = [
        {
          value: parseInt(respuesta.data.empresaEstado.id),
          label: respuesta.data.empresaEstado.nombre,
        },
      ];
      setOptionsEstados(optionEstadosSepomex);
      setformEstado(parseInt(respuesta.data.empresaEstado.id));

      const optionMunicipiosSepomex = [
        {
          value: parseInt(respuesta.data.empresaMunicipio.id),
          label: respuesta.data.empresaMunicipio.nombre,
        },
      ];
      setOptionsMunicipio(optionMunicipiosSepomex);
      setformMunicipio(parseInt(respuesta.data.empresaMunicipio.id));

      const optionColoniasSepomex = respuesta.data.coloniaList.map((item) => ({
        value: parseInt(item.id), // Convierte el ID a una cadena
        label: item.nombre,
      }));
      setOptionsColonia(optionColoniasSepomex);

      var arrColonias = {};
      const filteredData = optionColoniasSepomex.filter(
        (item) =>
          parseInt(item.value) === parseInt(respuesta.data.empresaColoniaId)
      );
      arrColonias = {
        value: respuesta.data.empresaColoniaId, // Convierte el ID a una cadena
        label: filteredData[0].label,
      };

      setformColonia(arrColonias);

      setenableSelectColonia(false);

      setformCalle(respuesta.data.empresaCalle);
      setformExt(respuesta.data.empresaNumeroExterior);
      setformInt(respuesta.data.empresaNumeroInterior);

      setTimeout(function () {
        form.setFieldsValue({
          domicilioLocalizacion: {
            calle: respuesta.data.empresaCalle,
          },
        });
      }, 0);

      ShowLoading(false);
    } else {
      setformCp('');
      setformEstado('');
      setformMunicipio('');
      setenableSelectColonia(true);
      setformColonia(null);
      setformCalle('');
      setformExt('');
      setformInt('');

      setTimeout(function () {
        form.setFieldsValue({
          domicilioLocalizacion: {
            calle: '',
          },
        });
      }, 0);
    }
  };

  const getCpData = async (strCp) => {
    ShowLoading(true);

    if (strCp == '') {
      ModalMessage('Error', 'El código postal no puede estar en blanco.');
      ShowLoading(false);
      return false;
    }

    let cpData = await SepomexService.getCatalogosCodigoPostal(strCp);

    if (cpData.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar la colonia.');
      }
      return false;
    }

    const optionEstadosSepomex = [
      {
        value: parseInt(cpData.data.estado.id),
        label: cpData.data.estado.nombre,
      },
    ];
    setOptionsEstados(optionEstadosSepomex);
    setformEstado(parseInt(cpData.data.estado.id));

    const optionMunicipiosSepomex = [
      {
        value: parseInt(cpData.data.municipio.id),
        label: cpData.data.municipio.nombre,
      },
    ];
    setOptionsMunicipio(optionMunicipiosSepomex);
    setformMunicipio(parseInt(cpData.data.municipio.id));

    const optionColoniasSepomex = cpData.data.coloniaList.map((item) => ({
      value: parseInt(item.id), // Convierte el ID a una cadena
      label: item.nombre,
    }));

    setOptionsColonia(optionColoniasSepomex);
    setenableSelectColonia(false);

    setDisableForm(false);

    ShowLoading(false);
  };

  const onSearch = (value, event) => {
    event.preventDefault();
    getCpData(value);
  };

  const removeAccents = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  const filterOption = (input, option) => {
    const normalizedInput = removeAccents(input).toLowerCase();
    const normalizedOption = removeAccents(option?.label).toLowerCase();

    return normalizedOption.includes(normalizedInput);
  };

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      getCpData(formCp);
      // Ejecutar la acción aquí
      //console.log('Enter presionado');
    }
  };

  const handleCargarDomicilioChange = (value) => {
    //console.log(value);
    // setvalueRFC('');

    handeChangeCargarDomicilioFiscal(value);
    setformCargarDomicilioValue(value);
    // // setTimeout(function () {
    // //   form.setFieldsValue({
    // //     datosClientes: {
    // //       RFC: '',
    // //     },
    // //   });
    // // }, 0);

    // if (value == 1) {
    //   setPfaeisVisible(true);
    //   setPmisVisible(false);
    // } else {
    //   setPfaeisVisible(false);
    //   setPmisVisible(true);
    // }
  };

  return (
    <div
      style={{
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        height: windowSize.height + 50,
        minHeight: 450,
        zIndex: 9999,
      }}
    >
      <div
        className="div-img-arrendamiento"
        style={{
          borderRadius: 25,
          padding: 0,
          boxShadow: '0px 0px 22px -10px rgba(0,0,0,0.75)',
          background: '#fff',
          marginTop: 30,
          width: windowSize.width - 200,
          height: '100%',
          minWidth: 370,
          minHeight: 700,
        }}
      >
        <center>
          <Divider>
            <div
              className="title-steps"
              style={{ margin: 0, color: '#4c4cbc', marginTop: 0 }}
            >
              Domicilio de localización
            </div>
          </Divider>
        </center>

        <center>
          <div
            className="subtitle-localizacion"
            style={{ color: '#4c4cbc', marginTop: 40, marginBottom: 20 }}
          >
            ¿Es el domicilio de localización igual al domicilio fiscal?
          </div>
        </center>

        <ConfigProvider
          theme={{
            token: {
              colorBgElevated: '#4c4cbc',
            },
          }}
        >
          <center>
            <Segmented
              disabled={DisableForm}
              options={[
                {
                  label: (
                    <div style={{ width: 100, fontSize: 20 }}>
                      <FaXmark
                        style={{
                          fontSize: 16,
                          fontWeight: 'bold',
                          marginRight: 5,
                        }}
                      />
                      No
                    </div>
                  ),
                  value: 2,
                },
                {
                  label: (
                    <div style={{ width: 100, fontSize: 20 }}>
                      <FaCheck
                        style={{
                          fontSize: 16,
                          fontWeight: 'bold',
                          marginRight: 5,
                        }}
                      />
                      Si
                    </div>
                  ),
                  value: 1,
                },
              ]}
              
              onChange={(value) => {
                //console.log(value); // string

                if (value == 2) {
                  //setDisableForm(true);
                }
                else {
                  //setDisableForm(false);
                }
                handleCargarDomicilioChange(value);
              }}
              value={formCargarDomicilioValue}
            />
          </center>
        </ConfigProvider>

        <Skeleton loading={loading} active={true}>
          <div
            style={{
              overflow: 'auto',
              maxHeight: 590,
              width: '99%',
              overflowX: 'hidden',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                marginTop: 25,
              }}
            >
              <Row gutter={6} style={{ width: '90.5%' }}>
                <Col flex="1 1 400px">
                  <Paper
                    component="form"
                    sx={{
                      p: '4px 4px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    style={{ height: 42 }}
                    size="small"
                    elevation={0}
                  >
                    <TextFieldCustom
                      inputProps={{ maxLength: 5 }}
                      style={{
                        width: '110%',
                        marginBottom: 10,
                        marginLeft: -3,
                      }}
                      label={
                        <>
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          <label>Código Postal</label>
                        </>
                      }
                      value={formCp}
                      onChange={setformCp}
                      inputtype={InputTypes.Integer}
                      onKeyDown={handleEnterPress}
                      disabled={DisableForm}
                    />
                    <Button
                      style={{ width: 50, height: 40, marginTop: -10 }}
                      className="buttons-empresa"
                      type="primary"
                      onClick={() => getCpData(formCp)}
                      icon={<SearchOutlined />}
                      disabled={DisableForm}
                    ></Button>
                  </Paper>
                </Col>

                <Col flex="1 1 400px" style={{ marginLeft: 0 }}>
                  <FormControl style={{ width: '100%', marginBottom: 10 }}>
                    <InputLabel id="select-label">
                      <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                      <label style={{opacity: 0.5}}>Estado</label>
                    </InputLabel>
                    <MuiSelect
                      labelId="select-label"
                      id="select"
                      value={formEstado}
                      disabled
                      style={{opacity: 0.5, pointerEvents: 'none' }}
                      label={
                        <>
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          <label>Estado</label>
                        </>
                      }
                      onChange={(e) => setformEstado(e.target.value)}
                      size="small"
                    >
                      {OptionsEstados.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <div style={{ textAlign: 'left' }}>
                            {option.label}
                          </div>
                        </MenuItem>
                      ))}
                    </MuiSelect>
                  </FormControl>
                </Col>
              </Row>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Row gutter={6} style={{ width: '90.5%' }}>
                <Col flex="1 1 400px">
                  <FormControl style={{ width: '100%', marginBottom: 10 }}>
                    <InputLabel id="select-label">
                      <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                      <label style={{opacity: 0.5}}>Municipio/Delegación</label>
                    </InputLabel>
                    <MuiSelect
                      labelId="select-label"
                      id="select"
                      value={formMunicipio}
                      disabled
                      style={{opacity: 0.5, pointerEvents: 'none' }}
                      label={
                        <>
                          <label style={{ color: 'red', fontSize: 12 }}>
                            *
                          </label>{' '}
                          <label>Municipio/Delegación</label>
                        </>
                      }
                      onChange={(e) => setformMunicipio(e.target.value)}
                      size="small"
                    >
                      {OptionsMunicipio.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <div style={{ textAlign: 'left' }}>
                            {option.label}
                          </div>
                        </MenuItem>
                      ))}
                    </MuiSelect>
                  </FormControl>
                </Col>

                <Col flex="1 1 400px" style={{ marginLeft: 0 }}>
                  <Autocomplete
                    size="small"
                    style={{ marginBottom: 10 }}
                    value={formColonia}
                    disabled={DisableForm}
                    onChange={(event, newValue) => setformColonia(newValue)}
                    options={OptionsColonia}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.label === value.nombre
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <>
                            <label style={{ color: 'red', fontSize: 12 }}>
                              *
                            </label>{' '}
                            <label>Colonia</label>
                          </>
                        }
                        placeholder="Colonia"
                      />
                    )}
                    filterOption={(inputValue, option) =>
                      filterOption(inputValue, option)
                    }
                  />
                </Col>
              </Row>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Row gutter={6} style={{ width: '90.5%' }}>
                <Col flex="1 1 400px">
                  <TextField
                    style={{ width: '100%', marginBottom: 10 }}
                    label={
                      <>
                        <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                        <label>Calle</label>
                      </>
                    }
                    value={formCalle}
                    onChange={(e) => setformCalle(e.target.value)}
                    size="small"
                    variant="outlined"
                    disabled={DisableForm}
                  />
                </Col>

                <Col flex="1 1 400px" style={{ marginLeft: 0 }}>
                  <TextFieldCustom
                    inputProps={{ maxLength: 10 }}
                    style={{ width: '100%', marginBottom: 10 }}
                    label={
                      <div style={{ zIndex: 1 }}>
                        <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                        No. Ext.
                      </div>
                    }
                    value={formExt}
                    onChange={setformExt}
                    inputtype={InputTypes.Integer}
                    disabled={DisableForm}
                  />
                </Col>

                <Col flex="1 1 400px">
                  <TextFieldCustom
                    inputProps={{ maxLength: 10 }}
                    style={{ width: '100%', marginBottom: 10 }}
                    label={<div style={{ zIndex: 1 }}>No. Int.</div>}
                    value={formInt}
                    onChange={setformInt}
                    inputtype={InputTypes.Integer}
                    disabled={DisableForm}
                  />
                </Col>
              </Row>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                marginTop: 10,
              }}
            >
              <Row gutter={6} style={{ width: '90%' }}>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <Button
                    className="buttons-back-forward"
                    type="primary"
                    onClick={() => handleBack()}
                    icon={<ArrowLeftOutlined />}
                  >
                    Atrás
                  </Button>
                </Col>

                <Col span={12} style={{ marginLeft: 0, textAlign: 'left' }}>
                  <Button
                    className="buttons-back-forward"
                    type="primary"
                    onClick={() => handleNext('/datos_contacto')}
                    htmlType="submit"
                  >
                    Continuar <ArrowRightOutlined />
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Skeleton>

        {/* <center>
          <Skeleton loading={loading} active={true}>
            <div
              style={{
                overflow: 'auto',
                width: '100%',
                maxHeight: windowSize.heightScroll,
              }}
            >
              <Form
                className="form-steps-domicilio"
                form={form}
                style={{
                  marginTop: -5,
                  display: 'inline-block',
                }}
                name="horizontal_login"
                layout="inline"
                onFinish={onFinish}
                disabled={DisableForm}
              >
                <Form.Item
                  style={{ marginBottom: 30 }}
                  name={['inversionista', 'codRegFiscal']}
                >
                  <Radio.Group
                    name="codRegFiscal"
                    onChange={(e) => {
                      handeChangeCargarDomicilioFiscal(e);
                    }}
                    value={2}
                  >
                    <Tooltip placement="left" title={PfTooltip}>
                      <Radio value={2} disabled={PfDisabled}>
                        <Typography.Title
                          level={5}
                          style={{
                            margin: 0,
                            color: '#081b3e',
                            marginBottom: -10,
                          }}
                        >
                          No
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Typography.Title>
                      </Radio>
                    </Tooltip>

                    <Tooltip placement="right" title={PmTooltip}>
                      <Radio value={1} disabled={PmDisabled}>
                        <Typography.Title
                          level={5}
                          style={{
                            margin: 0,
                            color: '#081b3e',
                            marginBottom: -10,
                          }}
                        >
                          Si
                        </Typography.Title>
                      </Radio>
                    </Tooltip>
                  </Radio.Group>
                </Form.Item>

                <Row gutter={16}>
                  <Col flex="1 1 200px" style={{ textAlign: 'left' }}>
                    <label style={{ color: 'red', fontSize: 12 }}>*</label> CP:
                    <SearchCustom
                      inputtype={InputTypes.Integer}
                      value={formCp}
                      maxLength={5}
                      placeholder="Ingrese un C.P."
                      onSearch={onSearch}
                      onChange={setformCp}
                    />
                  </Col>
                  <Col flex="1 1 200px" style={{ textAlign: 'left' }}>
                    <label>Estado:</label>
                    <center>
                      <Select
                        style={{ width: '100%' }}
                        placeholder="Seleccione una opción"
                        options={OptionsEstados}
                        onChange={setformEstado}
                        value={formEstado}
                        disabled
                      />
                    </center>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col flex="1 1 200px" style={{ textAlign: 'left' }}>
                    Municipio/Delegación:
                    <Select
                      style={{ width: '100%' }}
                      placeholder="Seleccione una opción"
                      options={OptionsMunicipio}
                      onChange={setformMunicipio}
                      value={formMunicipio}
                      disabled
                    />
                  </Col>
                  <Col flex="1 1 200px" style={{ textAlign: 'left' }}>
                    <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                    Colonia:
                    <center>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        style={{ width: '100%' }}
                        placeholder="Seleccione una opción"
                        options={OptionsColonia}
                        onChange={setformColonia}
                        value={formColonia}
                        disabled={disableSelectColonia}
                        filterOption={filterOption}
                      />
                    </center>
                  </Col>
                </Row>

                <Row>
                  <Col flex="1 1 215px" style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosEmpresa', 'calle']}
                      label={''}
                      colon={false}
                    >
                      <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                      <label>Calle:</label>
                      <InputCustom
                        inputtype={InputTypes.Text}
                        key={1}
                        value={formCalle}
                        onChange={setformCalle}
                      />
                    </Form.Item>
                  </Col>
                  <Col flex="1 1 80px" style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosEmpresa', 'ext']}
                      label={''}
                      colon={false}
                    >
                      <label style={{ color: 'red', fontSize: 12 }}>*</label>{' '}
                      <label>No. Ext.:</label>
                      <center>
                        <InputCustom
                          inputtype={InputTypes.Integer}
                          key={1}
                          value={formExt}
                          onChange={setformExt}
                        />
                      </center>
                    </Form.Item>
                  </Col>

                  <Col flex="1 1 80px" style={{ textAlign: 'left' }}>
                    <Form.Item
                      name={['datosEmpresa', 'int']}
                      label={''}
                      colon={false}
                    >
                      <label>No. Int.:</label>
                      <center>
                        <InputCustom
                          inputtype={InputTypes.Integer}
                          key={1}
                          value={formInt}
                          onChange={setformInt}
                        />
                      </center>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>

            <div
              style={{
                width: windowSize.width - 200,
                marginBottom: 50,
                marginTop: 50,
                minWidth: 500,
              }}
            >
              <Row gutter={16}>
                <Col flex="1 1 200px" style={{ textAlign: 'right' }}>
                  <Button
                    className="buttons-back-forward"
                    type="primary"
                    onClick={() => handleBack()}
                    icon={<ArrowLeftOutlined />}
                  >
                    Atrás
                  </Button>
                </Col>
                <Col flex="1 1 200px" style={{ textAlign: 'left' }}>
                  <Button
                    className="buttons-back-forward"
                    type="primary"
                    onClick={() => handleNext('/datos_contacto')}
                    htmlType="submit"
                  >
                    Continuar <ArrowRightOutlined />
                  </Button>
                </Col>
              </Row>
            </div>
          </Skeleton>
        </center> */}
      </div>
    </div>
  );
};

export default DomicilioLocalizacion;
