import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate,useLocation } from "react-router-dom";

import "./Welcome.css";
import "./ConfigurarCredito.css";
import { Checkbox, Typography, Button, Select, Input, Form, Row, Col, Divider, Skeleton, InputNumber, Slider, Radio, Tooltip } from "antd";
import { PlusOutlined, UserOutlined, ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { FaFile } from "react-icons/fa6";




import InputCustom from "../componentes/InputCustom";
import { ComponentesContext } from "../contextos/componentesContext";

import AuthService from "../services/auth.service";

const ConsultaSATWS = () => {
  const { UpdateFooterText,ModalMessage, UpdatebuttonIngresar, UpdateuserAvatar, loading, UpdatecurrentStep, InputTypes, UpdateFlagtimeLineForm, UpdatemovilSteps, UpdateFlagtimeLineFormAval } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const { Title } = Typography;
  const [form] = Form.useForm();
  const dateFormat = "DD-MM-YYYY";
  const location = useLocation();


  const [solicitudId, SetsolicitudId] = useState(
    typeof location.state.record.solicitudId != "undefined"
      ? location.state.record.solicitudId != null
        ? location.state.record.solicitudId
        : "0"
      : "0"
  );

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 190,
    heightImage: window.innerHeight - 185,
    heightScroll: (window.innerHeight * 50) / 100,
  });

  const [valueRegimen, setvalueRegimen] = useState(1);
  const [PfTooltip, setPfTooltip] = useState("");
  const [PmTooltip, setPmTooltip] = useState("");

  const [PfDisabled, setPfDisabled] = useState(false);
  const [PmDisabled, setPmDisabled] = useState(false);

  const [PfaeisVisible, setPfaeisVisible] = useState(true);
  const [PmisVisible, setPmisVisible] = useState(false);



  const contentStyle = {
    height: windowSize.height,
    minHeight: 400,
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
    borderRadius: 15
  };

  const optionsEstados = [
    {
      value: 'Sinaloa',
      label: 'Sinaloa',
    },
    {
      value: 'Sonora',
      label: 'Sonora',
    },

  ]


  useEffect(() => {

    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      console.log("entro");
      AuthService.logout();

      navigate("/login", {
        state: { record: { correo: "" } },
      });


    }
    else {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 190,
          heightImage: window.innerHeight - 185,
          heightScroll: (window.innerHeight * 50) / 100,
        });
      };

      UpdateFlagtimeLineForm(true);
      UpdateFlagtimeLineFormAval(false);
      UpdatebuttonIngresar("dashboard", usr.primerNombre + " " + usr.primerApellido);
      UpdateuserAvatar("dashboard");
      UpdatecurrentStep(8);
      UpdatemovilSteps(8);
      UpdateFooterText("© Fínamo 2023. Todos los derechos reservados.");

      setTimeout(function () {
        form.setFieldsValue({
          inversionista: {
            codRegFiscal: 1,
          },
        });
      }, 0);

      window.addEventListener("resize", handleResize);
      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleResize);

      };
    }


  }, []);

  const handleNext = (option) => {
    navigate(option, {
      state: { record: { solicitudId: solicitudId } },
    });
  };


  const onFinish = () => {
    handleNext("/datos_empresa");
  }

  const onChange = () => {

  }

  const handleBack = () => {
    handleNext("/domicilio_localizacion");
  }

  const optionsDestino = [
    {
      value: 'Sinaloa',
      label: 'Sinaloa',
    },
    {
      value: 'Sonora',
      label: 'Sonora',
    },

  ]

  const marks = {
    12: "12",
    24: "24",
    36: "36",
  };

  const marksMontos = {
    500000: "$500,000",
    800000: "$800,000",
    1000000: "$1000,000",
    1200000: "$1200,000",
    1500000: "$1500,000",
  };

  const showConfirm = (e) => {
    if (e.target.value == 1) {
      setPfaeisVisible(true);
      setPmisVisible(false);
    }
    else {
      setPfaeisVisible(false);
      setPmisVisible(true);
    }

    // if (statusRFC == "0") {
    //   confirm({
    //     title:
    //       "El inversionista ya esta registrado, esta segur@ de cambiar su régimen fiscal?",
    //     icon: <ExclamationCircleFilled />,
    //     onOk() {
    //       onChange(e);
    //     },
    //     onCancel() {
    //       var val = 1;
    //       if (e.target.value == 1) {
    //         val = 2;
    //       } else {
    //         val = 1;
    //       }
    //       form.setFieldsValue({
    //         inversionista: {
    //           codRegFiscal: val,
    //         },
    //       });
    //     },
    //   });
    // } else {
    //   onChange(e);
    // }
  };

  const handleContrasena = () => {

  }


  return (

    <div style={{ backgroundColor: "#fff", paddingBottom: 50, display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", height: windowSize.height }}>
      <center>
        <Divider>
          <div className="title-steps" style={{ color: "#4c4cbc" }}>
            Consulta SATWS
          </div>
        </Divider>

      </center>
      <center>
        <Skeleton loading={loading} active={true}>

          <br></br>

          <div style={{ overflow: "auto", width: "110%", maxHeight: windowSize.heightScroll, marginLeft: -16 }}>

            <div className="div-frame-satws" style={{ border: "solid #4c4cbc 1px", padding: 10, borderRadius: 10, marginBottom: 10 }} >
              <table width={"100%"}>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "left" }}>
                      <div><Typography.Title level={5} style={{ margin: 0, color: "#7d7d7d", marginTop: 0, fontWeight: "bold" }}>
                        1.Certificado(.cer) seleccionado
                      </Typography.Title></div>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <a style={{ color: "#4c4cbc", fontSize: 14, fontWeight: "bold" }}>Cargar certificado</a>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ paddingLeft: 10, paddingRight: 20 }}>
                      <div style={{ paddingLeft: 20, backgroundColor: "#f3f3f9", width: "100%", borderRadius: 10 }}>
                        <table>
                          <tr>
                            <td rowSpan={2} style={{ width: 30, height: 70 }}>
                              <FaFile style={{ fontSize: 25, color: "#7d7d7d" }} />
                            </td>
                            <td style={{ color: "#7d7d7d", fontWeight: "bold", paddingTop: 10 }}>Seleccione un certificado valido</td>
                          </tr>
                          <tr>

                            <td style={{ color: "#b0b0b0", paddingBottom: 10 }}>RFC: XAXX010101000 Expira el: 12/12/2024 - 5:42 p.m.</td>
                          </tr>
                        </table>

                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>


            <div className="div-frame-satws" style={{ border: "solid #4c4cbc 1px", padding: 10, borderRadius: 10, marginBottom: 10 }} >
              <table width={"100%"}>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "left" }}>
                      <div><Typography.Title level={5} style={{ margin: 0, color: "#7d7d7d", marginTop: 0, fontWeight: "bold" }}>
                        2.Llave privada (.key) seleccionada
                      </Typography.Title></div>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <a style={{ color: "#4c4cbc", fontSize: 14, fontWeight: "bold" }}>Reemplazar</a>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ paddingLeft: 10, paddingRight: 20 }}>
                      <div style={{ paddingLeft: 20, backgroundColor: "#f3f3f9", width: "100%", borderRadius: 10 }}>
                        <table >
                          <tr>
                            <td rowSpan={2} style={{ width: 30, height: 70 }}>
                              <FaFile style={{ fontSize: 25, color: "#7d7d7d" }} />
                            </td>
                            <td style={{ color: "#7d7d7d", fontWeight: "bold" }}>CARGAR: ARCHIVO_FIEL.key</td>
                          </tr>
                        </table>

                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>



            <div className="div-frame-satws" style={{ border: "solid #4c4cbc 1px", padding: 10, borderRadius: 10 }} >
              <table width={"100%"}>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "left" }}>
                      <div><Typography.Title level={5} style={{ margin: 0, color: "#7d7d7d", marginTop: 0, fontWeight: "bold" }}>
                        3. Ingresa la contraseña de tu e.firma
                      </Typography.Title></div>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <a style={{ color: "#4c4cbc", fontSize: 14, fontWeight: "bold" }}>Que es?</a>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ textAlign: "center" }}>
                      <Input.Password
                        placeholder="Ingrese contraseña ..."
                        style={{ width: "100%" }}
                        onChange={handleContrasena}
                      />
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          
          <div style={{ width: "110%", marginTop: 28, paddingLeft: 0, marginBottom: -30 }}>

            <Row style={{ marginLeft: 15 }}>
              <Col span={11}>
                <Button
                  className="buttons-back-forward"
                  type="primary"
                  onClick={() => handleNext("/entrevistapld")}
                  icon={<ArrowLeftOutlined />}
                >
                  Atras
                </Button>
              </Col>
              <Col>
                <Button
                  className="buttons-back-forward"
                  type="primary"
                  onClick={() => handleNext("/firma_buro")}
                  htmlType="submit"
                >
                  Continuar <ArrowRightOutlined />
                </Button>
              </Col>
            </Row>
          </div>
        </Skeleton>
      </center>
    </div>
  );
};

export default ConsultaSATWS;
