import LocalData from "../persistencia/LocalData";
import AuthService from "./auth.service";


const getSolicitudes = () => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getConfiguraCredito = () => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/formularios/configura-credito`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    redirect: 'follow'
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      console.log(responseData.result);
      if (typeof responseData.result == 'undefined') {
        return {estado: 500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getConfiguraCreditoRegistrado = (getParameter) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/formularios/configura-credito/${getParameter}`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const postGuardaConfiguraCredito = (json) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/formularios/configura-credito`, {
    method: "POST",
    headers: {
      'Content-Type':'application/json',
      "Authorization": `Bearer ${AuthService.getCurrentToken()}`
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getInformacionCliente = (getParameter) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/formularios/informacion-cliente/${getParameter}`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const postGuardaInformacionCliente = (json) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/formularios/informacion-cliente`, {
    method: "POST",
    headers: {
      'Content-Type':'application/json',
      "Authorization": `Bearer ${AuthService.getCurrentToken()}`
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getInformacionEmpresa = (getParameter) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/formularios/informacion-empresa/${getParameter}`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postGuardaInformacionEmpresa = (json) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/formularios/informacion-empresa`, {
    method: "POST",
    headers: {
      'Content-Type':'application/json',
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const getInformacionDomicilioLocalizacion = (getParameter) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/formularios/domicilio-localizacion/${getParameter}`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const postGuardaDomicilioLocalizacion = (json) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/formularios/domicilio-localizacion`, {
    method: "POST",
    headers: {
      'Content-Type':'application/json',
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const getDatosContactos = (getParameter) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/formularios/datos-contacto/${getParameter}`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postGuardaDatosContactos = (json) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/formularios/datos-contacto`, {
    method: "POST",
    headers: {
      'Content-Type':'application/json',
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getDatosAval = (getParameter) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/formularios/informacion-aval/${getParameter}`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postGuardaDatosAval = (json) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/formularios/informacion-aval`, {
    method: "POST",
    headers: {
      'Content-Type':'application/json',
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const postSubirDocumento = (json) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/formularios/documentos`, {
    method: "POST",
    headers: {
      'Content-Type':'application/json',
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getListaDocumentos = (getParameter) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/formularios/documentos/${getParameter}`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const deleteDocumento = (getParameter) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/formularios/documentos/${getParameter}`, {
    method: "DELETE",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getInformacionEntrevistaPld = (getParameter) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/formularios/entrevista-pld/${getParameter}`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postGuardaDatosPLD = (json) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/formularios/entrevista-pld`, {
    method: "POST",
    headers: {
      'Content-Type':'application/json',
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const getInformacionMiFiel= (getParameter) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/formularios/mifiel/${getParameter}`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const getFinalizaMiFiel= (getParameter) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/formularios/mifiel/finalizar/${getParameter}`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};




const getSolicitudesActivas= () => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}dashboard/solicitudes-activas`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const getSolicitudContacto= () => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/contacto`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postSolicitudContacto = (json) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/contacto`, {
    method: "POST",
    headers: {
      'Content-Type':'application/json',
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const postGuardaFirmaAutorizacionSat = (json) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}satws/firma-ciec`, {
    method: "POST",
    headers: {
      'Content-Type':'application/json',
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const getValidaCapturaFirma= (getParameter1,getParameter2) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}satws/status-firma/${getParameter1}/${getParameter2}`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const getValidaCapturaFirmaBuro= (getParameter1,getParameter2) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}burocredito/status-firma/${getParameter1}/${getParameter2}`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const getReenviarCorreoAval= (getParameter) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}solicitudes/formularios/informacion-aval/reenviar-correo/${getParameter}`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const postGuardarBuroCredito = (json) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}burocredito`, {
    method: "POST",
    headers: {
      'Content-Type':'application/json',
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};



const postValidaContrasenaLogin = (json) => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}auth/login`, {
    method: "POST",
    headers: {
      'Content-Type':'application/json',
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getSolicitudesPendientesAval = () => {
  const URL = process.env.REACT_APP_URL_API_FORANEOS;
  const resp = fetch(`${URL}aval/pendientes`, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${AuthService.getCurrentToken()}` 
    },
    // body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.result == 'undefined') {
        return {"estado":500};
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
    return resp;
  };


const Formularios = {
  getSolicitudes,
  getConfiguraCredito,
  postGuardaConfiguraCredito,
  getConfiguraCreditoRegistrado,
  getInformacionCliente,
  postGuardaInformacionCliente,
  getInformacionEmpresa,
  postGuardaInformacionEmpresa,
  getInformacionDomicilioLocalizacion,
  postGuardaDomicilioLocalizacion,
  getDatosContactos,
  postGuardaDatosContactos,
  getDatosAval,
  postGuardaDatosAval,
  postSubirDocumento,
  getListaDocumentos,
  getInformacionEntrevistaPld,
  postGuardaDatosPLD,
  getInformacionMiFiel,
  getFinalizaMiFiel,
  getSolicitudesActivas,
  deleteDocumento,
  getSolicitudContacto,
  postSolicitudContacto,
  postGuardaFirmaAutorizacionSat,
  getValidaCapturaFirma,
  getReenviarCorreoAval,
  postGuardarBuroCredito,
  postValidaContrasenaLogin,
  getValidaCapturaFirmaBuro,
  getSolicitudesPendientesAval
};

export default Formularios;
