import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ComponentesContext } from "../../contextos/componentesContext";
import { Typography, Button, Card, Row, Col } from "antd";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./Dashboard.css";

import imagen1 from "../../asset/images/DASHBOARD.png";
import imagen2 from "../../asset/images/solutions-services.png";
import imagen3 from "../../asset/images/bg-estruct.jpg";
import imagen4 from "../../asset/images/bg-arrend.jpg";

import imagen5 from "../../asset/images/MEDICO_MUJER.png";

import AuthService from "../../services/auth.service";
import FormulariosService from "../../services/formularios.Service";

const Dashboard = () => {
  const { UpdateFooterText,UpdatebuttonIngresar, UpdateuserAvatar,UpdateFlagtimeLineForm, usuarioSesion, ModalMessage, CloseSession, UpdateFlagtimeLineFormProductos, UpdateFlagtimeLineFormAval } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const { Title } = Typography;
  const location = useLocation();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 190,
    heightScroll: (window.innerHeight * 60) / 100,
  });

  const [currentIndex, setCurrentIndex] = useState(0);
  const [ValcenterSlidePercentage, setValcenterSlidePercentage] = useState(33.80);
  const [ValcenterModeCards, setValcenterModeCards] = useState(true);

  const [etiquetaBotton, setetiquetaBotton] = useState("Solicitar");

  const [flagVisibleButton, setflagVisibleButton] = useState(true);

  const [cards, setcards] = useState([
    {
      title: 'Crédito Simple',
      image: imagen2,
      content: 'Adquiere capital de trabajo, compra inventarios, paga a proveedores o resuelve otras necesidades extraordinarias de tesorería.',
      url: '/solicitud_credito',
      visibleButton: flagVisibleButton,
    },
    {
      title: 'Crédito Estructurado',
      image: imagen3,
      content: 'Enfocado en la urbanización, construcción y desarrollo de vivienda o comercial.',
      url: '/solicitud_credito',
      visibleButton: flagVisibleButton,
    },

    {
      title: 'Arrendamiento',
      image: imagen4,
      content: 'Solución flexible, que te permitirá adquirir los activos que necesitas de forma fácil y segura.',
      url: '/solicitud_credito',
      visibleButton: flagVisibleButton,
    },

    {
      title: 'Crédito Express',
      image: imagen1,
      content: 'Consigue un préstamo de corto plazo de hasta $1.5 millones de pesos sin garantía.',
      url: '/configurar_credito',
      visibleButton: flagVisibleButton,
    },
  ]);

  useEffect( () => {

    let usr =  AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      //console.log("entro");
      AuthService.logout();
      
      navigate("/login", {
        state: { record: { correo: "" } },
      });
      
      
    }
    else{

      if(window.innerWidth>800){
        setValcenterSlidePercentage(33.80);
        setValcenterModeCards(true);

        
        setcards([
          {
            title: 'Crédito Simple',
            image: imagen2,
            content: 'Adquiere capital de trabajo, compra inventarios, paga a proveedores o resuelve otras necesidades extraordinarias de tesorería.',
            url: '/solicitud_credito',
            etiquetaBoton: 'Solicitar',
            visibleButton: flagVisibleButton,
          },
          {
            title: 'Crédito Estructurado',
            image: imagen3,
            content: 'Enfocado en la urbanización, construcción y desarrollo de vivienda o comercial.',
            url: '/solicitud_credito',
            etiquetaBoton: 'Solicitar',
            visibleButton: flagVisibleButton,
          },
      
          {
            title: 'Arrendamiento',
            image: imagen4,
            content: 'Solución flexible, que te permitirá adquirir los activos que necesitas de forma fácil y segura.',
            url: '/solicitud_credito',
            etiquetaBoton: 'Solicitar',
            visibleButton: flagVisibleButton,
          },
          {
            title: 'Arrendamiento Médico',
            image: imagen1,
            content: 'Arrendamiento para profesionales de la salud, consigue el equipo que necesitas a través de rentas accesibles con beneficios fiscales.',
            url: '/configurar_credito',
            etiquetaBoton: etiquetaBotton,
            visibleButton: flagVisibleButton,
          },
        ]);
      }
      else{
        //console.log("entro movil");

        setValcenterSlidePercentage(100);
        setValcenterModeCards(false);
        setcards([
          {
            title: 'Arrendamiento Médico',
            image: imagen1,
            content: 'Arrendamiento para profesionales de la salud, consigue el equipo que necesitas a través de rentas accesibles con beneficios fiscales.',
            url: '/configurar_credito',
            etiquetaBoton: etiquetaBotton,
            visibleButton: flagVisibleButton,
          },

          {
            title: 'Crédito Simple',
            image: imagen2,
            content: 'Adquiere capital de trabajo, compra inventarios, paga a proveedores o resuelve otras necesidades extraordinarias de tesorería.',
            url: '/solicitud_credito',
            etiquetaBoton: 'Solicitar',
            visibleButton: flagVisibleButton,
          },
          {
            title: 'Crédito Estructurado',
            image: imagen3,
            content: 'Enfocado en la urbanización, construcción y desarrollo de vivienda o comercial.',
            url: '/solicitud_credito',
            etiquetaBoton: 'Solicitar',
            visibleButton: flagVisibleButton,
          },
      
          {
            title: 'Arrendamiento',
            image: imagen4,
            content: 'Solución flexible, que te permitirá adquirir los activos que necesitas de forma fácil y segura.',
            url: '/solicitud_credito',
            etiquetaBoton: 'Solicitar',
            visibleButton: flagVisibleButton,
          },
        ]);
      }

      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 190,
          heightScroll: (window.innerHeight * 60) / 100,
        });

        if(window.innerWidth>800){
          setValcenterSlidePercentage(33.80);
          setValcenterModeCards(true);

          //console.log("entro normal");
          setcards([
            {
              title: 'Crédito Simple',
              image: imagen2,
              content: 'Adquiere capital de trabajo, compra inventarios, paga a proveedores o resuelve otras necesidades extraordinarias de tesorería.',
              url: '/solicitud_credito',
              etiquetaBoton: 'Solicitar',
              visibleButton: flagVisibleButton,
            },
            {
              title: 'Crédito Estructurado',
              image: imagen3,
              content: 'Enfocado en la urbanización, construcción y desarrollo de vivienda o comercial.',
              url: '/solicitud_credito',
              etiquetaBoton: 'Solicitar',
              visibleButton: flagVisibleButton,
            },
        
            {
              title: 'Arrendamiento',
              image: imagen4,
              content: 'Solución flexible, que te permitirá adquirir los activos que necesitas de forma fácil y segura.',
              url: '/solicitud_credito',
              etiquetaBoton: 'Solicitar',
              visibleButton: flagVisibleButton,
            },
            {
              title: 'Arrendamiento Médico',
              image: imagen1,
              content: 'Arrendamiento para profesionales de la salud, consigue el equipo que necesitas a través de rentas accesibles con beneficios fiscales.',
              url: '/configurar_credito',
              etiquetaBoton: etiquetaBotton,
              visibleButton: flagVisibleButton,
            },
          ]);
        }
        else{
          //console.log("entro movil");

          setValcenterSlidePercentage(100);
          setValcenterModeCards(false);
          setcards([
            {
              title: 'Arrendamiento Médico',
              image: imagen1,
              content: 'Arrendamiento para profesionales de la salud, consigue el equipo que necesitas a través de rentas accesibles con beneficios fiscales.',
              url: '/configurar_credito',
              etiquetaBoton: etiquetaBotton,
              visibleButton: flagVisibleButton,
            },

            {
              title: 'Crédito Simple',
              image: imagen2,
              content: 'Adquiere capital de trabajo, compra inventarios, paga a proveedores o resuelve otras necesidades extraordinarias de tesorería.',
              url: '/solicitud_credito',
              etiquetaBoton: 'Solicitar',
              visibleButton: flagVisibleButton,
            },
            {
              title: 'Crédito Estructurado',
              image: imagen3,
              content: 'Enfocado en la urbanización, construcción y desarrollo de vivienda o comercial.',
              url: '/solicitud_credito',
              etiquetaBoton: 'Solicitar',
              visibleButton: flagVisibleButton,
            },
        
            {
              title: 'Arrendamiento',
              image: imagen4,
              content: 'Solución flexible, que te permitirá adquirir los activos que necesitas de forma fácil y segura.',
              url: '/solicitud_credito',
              etiquetaBoton: 'Solicitar',
              visibleButton: flagVisibleButton,
            },
          ]);
        }
        

      };

      UpdatebuttonIngresar("dashboard", usr.primerNombre+" "+usr.primerApellido );
      UpdateuserAvatar("dashboard");
      UpdateFlagtimeLineForm(false);
      UpdateFlagtimeLineFormProductos(false);
      UpdateFlagtimeLineFormAval(false);
      UpdateFooterText("© Fínamo 2023. Todos los derechos reservados.");
      

      window.addEventListener("resize", handleResize);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleResize);

      };
    }

  }, []);


  const handleSolicitudes = async () => {
    
  }

  const handleNext = async(option) => {

    if(option=="/configurar_credito"){
      let respuesta = await FormulariosService.getSolicitudes();

      //console.log("respuesta dash",respuesta);

      //   let respuesta = {
      //     "result": true,
      //     "msg": "OK",
      //     "data": []
      // };
      
      if (respuesta.result != true) {
        let respuestaSesion = await AuthService.getValidaSesion();
        //console.log("respuesta dash validacion",respuestaSesion);
        if(respuestaSesion.result==false){
          CloseSession();
          return false;
        }

        ModalMessage("Error", "Se produjo un error al cargar las solicitudes.");

       
        return false;
      }
      var solicitudId=0;


      if(respuesta.data.length>0){
        //console.log("entro dash mayor 0");
        solicitudId=respuesta.data[0].id;
      }
      //console.log("solicitudId",solicitudId);
      
      navigate(option, {
        state: { record: { solicitudId: solicitudId } },
      });

    }
    else{
      navigate(option);
    }
  };

  return (
    <div className="div-dashboard" style={{ backgroundColor: '#fff', marginTop: 5, borderRadius: 15}}>
      <center>
        <br></br>
        <Title level={2} style={{ marginTop: -5, color: "#4c4cbc" }}>
          Productos Digitales
        </Title>

        <div className="card-carousel">
          <Carousel
            showThumbs={false}
            infiniteLoop={true}
            showStatus={false}
            centerMode={ValcenterModeCards}
            centerSlidePercentage={33.80}
            emulateTouch={true}

          >
            {cards.map((card, index) => (
              <div key={index} className="carousel-card">
                <Card
                  hoverable
                  style={{ width: 250, boxShadow: '0px -1px 10px 0px rgba(0,0,0,0.20)', borderRadius: 15, marginBottom: 10, height:420 }}
                  cover={<img alt="example" src={card.image} style={{height:160}} />}
                  title={card.title}
                >
                  <div className="card-content">{card.content}</div>
                  <center>
                    <Button
                      type="primary"
                      size="default"
                      className="carousel-button"
                      onClick={() => handleNext(card.url)}
                      style={{display: card.visibleButton ? "block" : "none"   }}
                    >
                      {card.etiquetaBoton}
                    </Button>
                  </center>
                </Card>
              </div>
            ))}
          </Carousel>
        </div>
        <br></br>
      </center>
    </div>
  );
};

export default Dashboard;