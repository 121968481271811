import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import './Inicio.css';
import {
  Carousel,
  Typography,
  Button,
  Collapse,
  Space,
  Input,
  Row,
  Col,
  FloatButton,
  Modal,
} from 'antd';
import {
  PlusOutlined,
  UserOutlined,
  SendOutlined,
  CheckOutlined,
  FacebookOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  YoutubeOutlined,
  InstagramOutlined,
  QuestionCircleOutlined,
  WhatsAppOutlined,
  CloseOutlined,
  CheckCircleTwoTone,
  HomeOutlined,
} from '@ant-design/icons';

import {
  FaScaleBalanced,
  FaMoneyBillTrendUp,
  FaDesktop,
  FaBuilding,
  FaArrowUpRightFromSquare,
  FaMoneyBill,
  FaBriefcase,
  FaClipboard,
  FaComments,
  FaFileSignature,
  FaHouse,
  FaEnvelope,
  FaPhone,
  FaWhatsapp,
} from 'react-icons/fa6';

import imagen1 from '../asset/images/img11.jpeg';
import imagen2 from '../asset/images/req_1.png';
import imagen5 from '../asset/images/img5.jpeg';

import logo from '../asset/images/logo_white.png';
import logoCondusef from '../asset/images/logoCONDUSEF.png';
import logoBuro from '../asset/images/security-buro.png';
import logoBc from '../asset/images/buro_credito.png';

import img2sinfondo from '../asset/images/img2sinfondo.png';

import AuthService from '../services/auth.service';
import FormulariosService from '../services/formularios.Service';

import { ComponentesContext } from '../contextos/componentesContext';

import RefreshAvalTypeClass from '../clases/refreshAvalType.class';

import VideoMedicos from '../asset/videoMedico.mp4';

import { FcOk } from 'react-icons/fc';

const { Panel } = Collapse;

const Welcome = () => {
  const {
    ModalMessage,
    UpdatebuttonIngresar,
    UpdateuserAvatar,
    UpdateFlagtimeLineForm,
    UpdateFooterText,
    ShowLoading,
    CloseSession,
    UpdateFlagMenuInicial,
    UpdateEmailIngresado,
    UpdateFlagtimeLineFormAval,
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const { Title } = Typography;

  const [formVisible, setformVisible] = useState(false);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [strTitulo, setstrTitulo] = useState('');
  const [strMensaje, setstrMensaje] = useState('');

  const [Email, setEmail] = useState('');

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight - 200,
    heightImage: window.innerHeight - 185,
  });

  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

  const items = [
    {
      key: '1',
      label: 'This is panel header with arrow icon',
      children: <p>hola</p>,
    },
    {
      key: '2',
      label: 'This is panel header with no arrow icon',
      children: <p>hola 2</p>,
      showArrow: false,
    },
  ];

  const contentStyle = {
    height: windowSize.height,
    minHeight: 400,
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
    borderRadius: 15,
  };

  const LogosStyle = {
    color: 'white',
    fontSize: 30,
  };

  const LinksStyle = {
    color: 'white',
    fontSize: 20,
  };

  useEffect(() => {
    ShowLoading(true);
    let usr = AuthService.getCurrentUser();
    if (usr === null || usr === undefined) {
      setformVisible(true);
      UpdatebuttonIngresar('');
      UpdateuserAvatar('');
      UpdateFlagtimeLineForm(false);
      UpdateFlagtimeLineFormAval(false);
      UpdateFooterText('© Fínamo 2023. Todos los derechos reservados.');
      UpdateFlagMenuInicial(true);

      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight - 200,
          heightImage: window.innerHeight - 185,
        });
      };
      ShowLoading(false);

      window.addEventListener('resize', handleResize);
      //window.addEventListener("scroll", handleScroll);
      return () => {
        //window.removeEventListener("scroll", handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    } else {
      handleRedirecciona();
    }
  }, []);

  const handleRedirecciona = async () => {
    //console.log("entro redirecciona");

    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      //console.log("entro directo");
      ShowLoading(false);
      navigate('/dashboard');
      return true;
    }

    let respuestaSesion = await AuthService.getValidaSesion();
    if (respuestaSesion.result == false) {
      CloseSession();
      ShowLoading(false);
      return true;
    }

    let respuesta = await FormulariosService.getSolicitudesActivas();
    //console.log("respuesta redireccion", respuesta.data.length );
    if (respuesta.result != true) {
      ShowLoading(false);
      let respuestaSesion = await AuthService.getValidaSesion();

      if (respuestaSesion.result == false) {
        CloseSession();
      } else {
        ModalMessage('Error', 'Se produjo un error al cargar el formulario.');
      }
      return false;
    }
    ShowLoading(false);

    let isAval = await RefreshAvalTypeClass.RefreshAvalType();

    if (isAval) {
      navigate('/pendientes_firma');
    } else {
      if (respuesta.data.length == 0) {
        navigate('/dashboard');
      } else {
        navigate('/solicitudes_activas');
      }
    }
  };

  const handleNext = (option) => {
    navigate(option);
    //window.location.reload();
  };

  const handleAplicar = (option) => {
    UpdateEmailIngresado(Email);
    navigate(option);
    //window.location.reload();
  };

  const onChange = (key) => {
    //console.log(key);
  };

  const handleProductos = () => {};

  const handleLink = (link) => {
    window.location.href = link;
  };

  const handleModalClose = () => {
    setisModalOpen(false);
  };

  const handleOpenModal = (titulo, mensaje) => {
    setstrTitulo(titulo);
    setstrMensaje(mensaje);
    setisModalOpen(true);
  };

  const divStyle = {
    backgroundImage: imagen5,
    backgroundSize: 'cover', // Puedes ajustar esto según tus necesidades
    width: '100%', // Ajusta el ancho según sea necesario
    height: '400px', // Ajusta la altura según sea necesario
  };

  const handleSendMessage = () => {
    // Número de teléfono al que deseas enviar el mensaje (debe incluir el código de país)
    const phoneNumber = '526677517699';

    // Mensaje que deseas enviar
    const message = 'Hola, buen día.';

    // Construye el enlace de WhatsApp con el número y el mensaje
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    // Abre el enlace en una nueva ventana o pestaña
    window.open(whatsappLink);
  };


  return formVisible ? (
    <div style={{ minWidth: 370 }}>
      <div className="contenedor-main">
        <div className="fondo-main">{/* Tu imagen de fondo */}</div>
        <div className="overlay-main">
          <p className="font-title-main" style={{ fontWeight: 'bold' }}>
            Adquiere el <span style={{ color: '#4c4cbc' }}>equipo</span>{' '}
            <span style={{ color: '#4c4cbc' }}>médico</span> o{' '}
            <span style={{ color: '#4c4cbc' }}>automóvil</span> que necesitas,
            de forma <span style={{ color: '#4c4cbc' }}>rápida.</span>{' '}
          </p>

          <p
            className="font-title-main2"
            style={{
              color: 'black',
            }}
          >
            Te ofrecemos una solución financiera diseñada especialmente para el
            sector médico
          </p>

          <p
            className="font-title-main3"
            style={{
              color: 'black',
            }}
          >
            Obtén tu equipo médico, aplica ahora.
          </p>

          <Button
            style={{ height: 50, marginLeft: 50, width: 200, fontSize: 22 }}
            type="primary"
            onClick={() => handleAplicar('/welcome')}
          >
            Precalifícate &nbsp; <FaFileSignature style={{ fontSize: 18 }} />
          </Button>

          {/* <Space.Compact className="input-main" style={{ marginLeft: 50 }}>
            <Input
              style={{ height: 50 }}
              placeholder="Ingresa tu correo electrónico"
              defaultValue=""
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              style={{ height: 50 }}
              type="primary"
              onClick={() => handleAplicar('/welcome')}
            >
              <SendOutlined />
            </Button>
          </Space.Compact> */}
        </div>
      </div>

      <br></br>
      <br></br>
      <div style={{ padding: 20 }}>
        <center>
          <p
            className="div-titulo-beneficios"
            style={{
              fontWeight: 'bold',
              fontFamily: 'Red Hat Display, sans-serif',
            }}
          >
            Conoce los beneficios del{' '}
            <span style={{ color: '#4c4cbc' }}> Arrendamiento Médico</span>{' '}
          </p>

          <p
            className="div-titulo-beneficios2"
            style={{
              fontFamily: 'Red Hat Display, sans-serif',
            }}
          >
            Contamos con los mejores planes para arrendar equipos médicos e
            impulsar tu negocio.
          </p>
        </center>
        <br></br>
        <div>
          <Row style={{ textAlign: 'center' }}>
            <Col
              xs={{ span: 5, offset: 2 }}
              lg={{ span: 6, offset: 2 }}
              xl={{ span: 6, offset: 2 }}
            >
              <FaScaleBalanced style={{ fontSize: 50, color: '#0c0c54' }} />
              <p
                className="div-titulo-beneficios3"
                style={{
                  fontFamily: 'Red Hat Display, sans-serif',
                }}
              >
                Beneficios Fiscales
              </p>

              <p
                className="div-titulo-beneficios4"
                style={{
                  fontFamily: 'Red Hat Display, sans-serif',
                }}
              >
                Consigue los activos que necesitas a través de rentas 100%
                deducibles de impuestos.
              </p>
            </Col>
            <Col xs={{ span: 5, offset: 2 }} lg={{ span: 6, offset: 1 }}>
              <FaMoneyBillTrendUp style={{ fontSize: 50, color: '#0c0c54' }} />
              <p
                className="div-titulo-beneficios3"
                style={{
                  fontFamily: 'Red Hat Display, sans-serif',
                }}
              >
                No comprometes tu liquidez
              </p>

              <p
                className="div-titulo-beneficios4"
                style={{
                  fontFamily: 'Red Hat Display, sans-serif',
                }}
              >
                No desembolses grandes cantidades de dinero, consigue el equipo
                médico con pagos mensuales accesibles.
              </p>
            </Col>
            <Col
              xs={{ span: 5, offset: 2 }}
              lg={{ span: 6, offset: 1 }}
              xl={{ span: 6, offset: 1 }}
            >
              <FaDesktop style={{ fontSize: 50, color: '#0c0c54' }} />
              <p
                className="div-titulo-beneficios3"
                style={{
                  fontFamily: 'Red Hat Display, sans-serif',
                }}
              >
                Equipo Moderno
              </p>

              <p
                className="div-titulo-beneficios4"
                style={{
                  fontFamily: 'Red Hat Display, sans-serif',
                }}
              >
                Mantén tu equipo médico a la vanguardia, consigue activos de
                última generación.
              </p>
            </Col>
          </Row>
        </div>
      </div>

      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <div
        style={{
          width: '100%',
          minHeight: 200,
          backgroundColor: '#0c0c54',
          marginTop: 0,
        }}
      >
        <div className="contenedor-main">
          <div className="fondo-main-diferenciadores">
            {/* Tu imagen de fondo */}
          </div>
          <div className="overlay-diferenciadores">
            <div className="div-diferenciadores">
              <p
                className="div-title-diferenciadores"
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  fontFamily: 'Red Hat Display, sans-serif',
                }}
              >
                Beneficios de estar con Fínamo
              </p>

              <p
                className="div-title-diferenciadores2"
                style={{
                  color: 'white',
                  marginBottom: 80,
                }}
              >
                Somos una empresa líder en el mercado con más de 15 años de
                experiencia en el otorgamiento de Créditos y Arrendamientos.
              </p>

              <div>
                <Row style={{ marginTop: -50 }}>
                  <Col>
                    {' '}
                    <div
                      className="check-icon-circle"
                      style={{
                        backgroundColor: '#25ca6d',
                        borderRadius: 30,
                        marginRight: 10,
                      }}
                    >
                      <CheckOutlined
                        className="check-icon"
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          marginLeft: 5,
                          marginTop: 5,
                        }}
                      />
                    </div>{' '}
                  </Col>
                  <Col>
                    <div
                      className="div-title-diferenciadores3"
                      style={{ fontWeight: 'bold', color: '#25ca6d' }}
                    >
                      Rapidez.
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ width: 40 }}>&nbsp;</div>
                  </Col>
                  <Col>
                    <div
                      className="div-title-diferenciadores4"
                      style={{ color: 'white', maxWidth: 550 }}
                    >
                      Reducimos al mínimo la documentación requerida, te
                      atenderemos de forma oportuna y eficaz durante todo el
                      proceso.
                    </div>
                  </Col>
                </Row>
              </div>

              <div style={{ marginTop: 80 }}>
                <Row style={{ marginTop: -50 }}>
                  <Col>
                    {' '}
                    <div
                      className="check-icon-circle"
                      style={{
                        backgroundColor: '#25ca6d',
                        borderRadius: 30,
                        marginRight: 10,
                      }}
                    >
                      <CheckOutlined
                        className="check-icon"
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          marginLeft: 5,
                          marginTop: 5,
                        }}
                      />
                    </div>{' '}
                    .
                  </Col>
                  <Col>
                    <div
                      className="div-title-diferenciadores3"
                      style={{ fontWeight: 'bold', color: '#25ca6d' }}
                    >
                      Flexibilidad.
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ width: 40 }}>&nbsp;</div>
                  </Col>
                  <Col>
                    <div
                      className="div-title-diferenciadores4"
                      style={{ color: 'white', maxWidth: 550 }}
                    >
                      Reconocemos que cada cliente es único, por lo que
                      ofrecemos contratos flexibles y personalizados, nos
                      ajustamos a las necesidades específicas de cada situación.
                    </div>
                  </Col>
                </Row>
              </div>

              <div style={{ marginTop: 80, marginBottom: 20 }}>
                <Row style={{ marginTop: -50 }}>
                  <Col>
                    {' '}
                    <div
                      className="check-icon-circle"
                      style={{
                        backgroundColor: '#25ca6d',
                        borderRadius: 30,

                        marginRight: 10,
                      }}
                    >
                      <CheckOutlined
                        className="check-icon"
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                          marginLeft: 5,
                          marginTop: 5,
                        }}
                      />
                    </div>{' '}
                  </Col>
                  <Col>
                    <div
                      className="div-title-diferenciadores3"
                      style={{ fontWeight: 'bold', color: '#25ca6d' }}
                    >
                      Digital.
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ width: 40 }}>&nbsp;</div>
                  </Col>
                  <Col>
                    <div
                      className="div-title-diferenciadores4"
                      style={{
                        color: 'white',
                        maxWidth: 550,
                        marginBottom: 20,
                      }}
                    >
                      Nuestro proceso de contratación está diseñado para la era
                      digital. Desde cualquier dispositivo móvil o de
                      escritorio, podrás llevar a cabo el proceso sin
                      complicaciones y con total comodidad.
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: '#f4f6f5', marginTop: -38 }}>
        <center>
          <div>
            <Row style={{ marginTop: 0 }}>
              <Col
                flex="auto"
                className="div-cards-candidato"
                
              >
                <p
                  className="div-titulo-candidato"
                  style={{
                    fontWeight: 'bold',
                    fontFamily: 'Red Hat Display, sans-serif',
                    
                  }}
                >
                  <br></br>
                  Soy candidato para un producto Fínamo?
                </p>

                <p
                  className="div-titulo-candidato2"
                  style={{
                    fontFamily: 'Red Hat Display, sans-serif',
                    marginBottom: 50,
                  }}
                >
                  Conoce los requisitos para un Arrendamiento con nosotros.
                </p>
                <div className="div-global-cards" >
                <Row
                    gutter={16}
                    style={{ maxWidth: 600, marginTop: 30, display:"flex", justifyContent:"center" }}
                  >
                    <Col span={10}>
                      <div className="div-card-requisitos">
                        <FaBuilding style={{ fontSize: 25 }} />

                        <p
                          className="div-card-requisitos-text"
                          style={{
                            fontFamily: 'Red Hat Display, sans-serif',
                          }}
                        >
                          Ser PFAE / MORAL
                        </p>

                        <Button
                          type="primary"
                          onClick={() =>
                            handleOpenModal(
                              'PFAE / MORAL',
                              'Requieres ser dueño(a) de un negocio bajo el régimen de persona física con actividad empresarial(PFAE) o persona Moral.'
                            )
                          }
                          style={{
                            height: 30,
                            fontSize: 12,
                            marginTop: -20,
                            marginBottom: 5,
                          }}
                        >
                          Ver mas <FaArrowUpRightFromSquare />
                        </Button>
                      </div>
                    </Col>
                    <Col span={10}>
                      <div className="div-card-requisitos">
                        <FaMoneyBillTrendUp style={{ fontSize: 25 }} />

                        <p
                          className="div-card-requisitos-text"
                          style={{
                            fontFamily: 'Red Hat Display, sans-serif',
                          }}
                        >
                          Facturación mayor a 2mdp
                        </p>

                        <Button
                          type="primary"
                          onClick={() =>
                            handleOpenModal(
                              'Facturación mayor a 2mdp',
                              'Su negocio debe facturar más de 2 millones de pesos anuales, no atendemos a micronegocios.'
                            )
                          }
                          style={{
                            height: 30,
                            fontSize: 12,
                            marginTop: -20,
                            marginBottom: 5,
                          }}
                        >
                          Ver mas <FaArrowUpRightFromSquare />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    gutter={16}
                    style={{ maxWidth: 600, marginTop: 30, marginBottom: 180, display:"flex", justifyContent:"center" }}
                  >
                    <Col span={10}>
                      <div className="div-card-requisitos">
                        <FaClipboard style={{ fontSize: 25 }} />

                        <p
                          className="div-card-requisitos-text"
                          style={{
                            fontFamily: 'Red Hat Display, sans-serif',
                          }}
                        >
                          Registro SAT mayor a 2 años
                        </p>

                        <Button
                          type="primary"
                          onClick={() =>
                            handleOpenModal(
                              'Registro SAT mayor a 2 años',
                              'Su negocio debe estar registrado ante el SAT con una antigüedad mayor a 2 años. (Se puede considerar historial previo en otras razones sociales)'
                            )
                          }
                          style={{
                            height: 30,
                            fontSize: 12,
                            marginTop: -20,
                            marginBottom: 5,
                          }}
                        >
                          Ver mas <FaArrowUpRightFromSquare />
                        </Button>
                      </div>
                    </Col>
                    <Col span={10}>
                      <div className="div-card-requisitos">
                        <FaBriefcase style={{ fontSize: 25 }} />

                        <p
                          className="div-card-requisitos-text"
                          style={{
                            fontFamily: 'Red Hat Display, sans-serif',
                          }}
                        >
                          2 años de operación
                        </p>

                        <Button
                          type="primary"
                          onClick={() =>
                            handleOpenModal(
                              '2 años de operación',
                              'Su negocio debe tener al menos 2 años de operación. (Se puede considerar historial previo en otras razones sociales)'
                            )
                          }
                          style={{
                            height: 30,
                            fontSize: 12,
                            marginTop: -20,
                            marginBottom: 5,
                          }}
                        >
                          Ver mas <FaArrowUpRightFromSquare />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>

                {/* <Row style={{ maxWidth: 600, marginTop: 30, marginBottom: 50 }}>
                  <Col span={12}>
                    <div className="div-card-requisitos">
                      <FaMoneyBill style={{ fontSize: 25 }} />

                      <p
                        className="div-card-requisitos-text"
                        style={{
                          fontFamily: 'Red Hat Display, sans-serif',
                        }}
                      >
                        Comprobante de ingresos
                      </p>

                      <Button
                        type="primary"
                        onClick={() =>
                          handleOpenModal('Comprobante de ingresos','Su negocio debe registrar y comprobar ingresos: estado de resultados y balance general.')}
                        style={{
                          height: 30,
                          fontSize: 12,
                          marginTop: -20,
                          marginBottom: 5,
                        }}
                      >
                        Ver mas <FaArrowUpRightFromSquare />
                      </Button>
                    </div>
                  </Col>
                </Row> */}
              </Col>
              <Col
                style={{
                  display: 'flex',
                  justifyContent: "flex-start",
                  alignItems: 'flex-end',
                  width: "40%",
                  // borderStyle:"dashed"
                }}
              >
                <img
                  className="div-card-requisitos-imagen"
                  src={imagen2}
                  style={{ maxWidth: 450 }}
                ></img>
              </Col>
            </Row>
          </div>
        </center>
      </div>

      <div style={{ backgroundColor: '#fff', padding:20 }}>
        <center>
          <div>
            <Row align="middle">
              <Col flex="1 1 600px">
                <p
                  className="div-titulo-candidato"
                  style={{
                    fontWeight: 'bold',
                    fontFamily: 'Red Hat Display, sans-serif',
                    marginTop: -10,
                  }}
                >
                  <br></br>
                  Plataforma Digital
                </p>

                <p
                  className="div-titulo-candidato2"
                  style={{
                    fontFamily: 'Red Hat Display, sans-serif',
                    marginBottom: 50,
                  }}
                >
                  Crea tu solicitud de forma 100% digital a través de nuestro
                  portal de autoservicio y obtén tu línea de Arrendamiento
                  autorizada en menos de una semana.
                </p>
              </Col>
              <Col flex="1 1 600px">
                <div>
                  <video autoPlay loop muted playsInline style={{width:"100%", marginTop:50, marginBottom:50}}>
                    <source src={VideoMedicos} type="video/mp4" />
                    Tu navegador no admite el elemento de video.
                  </video>
                </div>
              </Col>
            </Row>
          </div>
        </center>
      </div>

      <div className="contenedor-automovil" style={{ marginTop: 0 }}>
        <div className="fondo-automovil">{/* Tu imagen de fondo */}</div>
        <div className="overlay-automovil">
          <p
            className="font-title-automovil"
            style={{
              color: 'white',
              fontWeight: 'bold',
              fontFamily: 'Red Hat Display, sans-serif',
              marginTop: 100,
            }}
          >
            Movilidad para Profesionales de la Salud
          </p>

          <p
            className="font-title-automovil2"
            style={{
              color: 'white',
              marginTop: -30,
              marginBottom: 40,
            }}
          >
            Valoramos profundamente la dedicación de los profesionales de la
            salud y reconocemos la importancia de su movilidad en el ámbito
            clínico y personal, es por ello que extendemos nuestros servicios
            para incluir la opción de arrendar automóviles de alta calidad.
          </p>

          {/* <p
            className="font-title-automovil3"
            style={{
              color: 'white',
              fontWeight: 'bold',
              marginTop: -5,
            }}
          >
            <CheckCircleTwoTone twoToneColor="#52c41a" /> &nbsp;Tarifas
            Competitivas
          </p> */}
          <p
            className="font-title-automovil3"
            style={{
              color: 'white',
              marginTop: -15,
            }}
          >
            <div style={{ display: 'flex', alignContent: 'center' }}>
              <FcOk style={{ fontSize: 27 }} /> &nbsp;&nbsp;Asistencia
              Personalizada
            </div>
          </p>

          <p
            className="font-title-automovil3"
            style={{
              color: 'white',
              marginTop: -10,
            }}
          >
            <div style={{ display: 'flex', alignContent: 'center' }}>
              <FcOk style={{ fontSize: 27 }} /> &nbsp;&nbsp;Mantenimiento y
              Servicios Incluidos
            </div>
          </p>

          <p
            className="font-title-automovil3"
            style={{
              color: 'white',
              marginTop: -10,
            }}
          >
            <div style={{ display: 'flex', alignContent: 'center' }}>
              <FcOk style={{ fontSize: 27 }} /> &nbsp;&nbsp;Flexibilidad en
              Términos de Contrato
            </div>
          </p>

          <p
            className="font-title-automovil3"
            style={{
              color: 'white',
              marginTop: -10,
            }}
          >
            <div style={{ display: 'flex', alignContent: 'center' }}>
              <FcOk style={{ fontSize: 27 }} /> &nbsp;&nbsp;Deducible de
              Impuestos
            </div>
          </p>
        </div>
      </div>

      <div className="contenedor-automovil" style={{ marginTop: 0 }}>
        <div className="fondo-impulsar">{/* Tu imagen de fondo */}</div>
        <div className="overlay-impulsar">
          <center>
            <p className="font-title-submain" style={{ marginTop: 180 }}>
              ¿Estás listo para{' '}
              <span style={{ color: '#4c4cbc' }}>impulsar</span> tu negocio con{' '}
              <span style={{ color: '#4c4cbc' }}>Fínamo</span> ?
            </p>

            <p
              className="font-title-submain2"
              style={{
                color: 'black',
                width: '90%',
                fontFamily: 'Red Hat Display, sans-serif',
                marginTop: -20,
              }}
            >
              Comienza tu solicitud ahora y consigue el equipo médico que
              necesitas.
            </p>

            <Button
              className="div-img-arrendamiento-button"
              style={{ height: 50, width: 250, fontSize: 22 }}
              type="primary"
              onClick={() => handleAplicar('/welcome')}
            >
              Iniciar Solicitud &nbsp;{' '}
              <FaFileSignature style={{ fontSize: 22 }} />
            </Button>

            {/* <div
            className="div-img-arrendamiento-equipo"
            style={{
              borderRadius: 25,
              padding: 0,
              boxShadow: '0px 0px 22px -7px rgba(0,0,0,0.75)',
              marginBottom: 100,
              background: '#e4ecf4',
              minWidth: 370,
            }}
          >
            <Row>
              <Col span={8}>
                <img
                  className="img-arrendamiento"
                  src={img2sinfondo}
                  style={{ borderRadius: 25 }}
                ></img>
              </Col>
              <Col span={8} offset={5}>
                <div
                  className="div-img-arrendamiento-font"
                  style={{ fontWeight: 'bold' }}
                >
                  Arrendamiento de{' '}
                  <span style={{ color: '#4c4cbc' }}>Equipo Médico</span>{' '}
                </div>
                <Button
                  className="div-img-arrendamiento-button"
                  type="primary"
                  onClick={() => handleNext('/welcome')}
                  style={{ marginTop: 20 }}
                >
                  Solicitar ahora
                </Button>
              </Col>
            </Row>
          </div> */}
          </center>
        </div>
      </div>

      {/* <div>
        <center>
          <p
            className="div-title-beneficios"
            style={{
              fontWeight: 'bold',
              fontFamily: 'Red Hat Display, sans-serif',
            }}
          >
            Documentación requerida
          </p>

        </center>
        
      </div> */}

      <div>
        <center>
          {/* <table
            className='table-title-1'
                cellPadding="0"
                cellSpacing="0"
                border="0"
                style={{ borderRadius: 15 }}
              >
                <tbody>
                  
                  <tr
                  
                    style={{
                      backgroundColor: '#132a55',
                      color: 'white',
                  fontWeight: "bold",
                      height:50
                    }}
                  >
                    <td style={{backgroundColor:"white" }}  width="30">&nbsp;</td>
                    <td style={{ backgroundColor: "white", color: "black" }} colSpan={1} width="200" >&nbsp;</td>
                    <td className='diagonalCross2' style={{ backgroundColor: "white", color: "black" }} width="70" colSpan={1} >&nbsp;</td>
                    
                    <td  colSpan={1} style={{textAlign:"center"}} ></td>
                    <td  style={{ textAlign: 'center' }} ><Row  className='table-title-1' style={{marginLeft:40}} >VIGENCIA</Row ></td>
                    <td style={{ textAlign: 'center' }} colSpan={1}><Row className='table-title-1' style={{marginLeft:20}} >TIPO</Row ></td>
                    <td style={{ textAlign: 'center' }} colSpan={2}> 
                    <Row >
                      <Col  className='table-title-1'>
                        BENEFICIARIO
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        PF
                      </Col>
                      <Col span={12}>
                        PM
                      </Col>
                    </Row>
                    </td>
                    
                <td style={{ textAlign: 'center' }} colSpan={2} width="80"> 
                    <Row >
                      <Col className='table-title-1' style={{marginLeft:25}}>
                        AVAL
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        PF
                      </Col>
                      <Col span={12}>
                        PM
                      </Col>
                    </Row>
                </td>
                  </tr>
                  <tr
                    style={{
                      backgroundColor: '#acb4c4',
                      
                      height: 30,
                      color: '#041534',
                    }}
                  >
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderLeftWidth: 1,
                        borderLeftStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        fontWeight:"bold",
                        borderTopStyle: "solid",
                        borderTopWidth: 1
                      }}
                    >
                      1
                    </td>
                <td
                  colSpan={3}
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        borderTopStyle: "solid",
                        borderTopWidth: 1
                        
                  }}
                  
                    >
                      Solicitud de Crédito
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        borderTop:  '1px solid #132a55',
                      }}
                    >
                      90 días
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        borderTop:  '1px solid #132a55',
                      }}
                    >
                      Original
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        borderTop:  '1px solid #132a55',
                      }}
                    >
                      <CheckOutlined />
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        borderTop:  '1px solid #132a55',
                      }}
                    >
                      |
                    </td>
                  </tr>
                  <tr style={{  backgroundColor: '#fff', height: 30,
                      color: '#041534', }}>
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:
                          'rgb(76, 76, 188) rgb(76, 76, 188) rgb(114, 114, 203)',
                        borderLeftWidth: 1,
                        borderLeftStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        fontWeight:"bold"
                      }}
                    >
                      2
                    </td>
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        
                  }}
                  colSpan={3}
                    >
                      Identificación Oficial
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      90 días
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      Copia
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      <CheckOutlined />
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      <CheckOutlined />
                    </td>
                  </tr>

                  <tr
                    style={{
                      backgroundColor: '#acb4c4',
                  
                  height: 30,
                  color: '#041534',
                    }}
                  >
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderLeftWidth: 1,
                        borderLeftStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        fontWeight:"bold"
                      }}
                    >
                      3
                    </td>
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        
                  }}
                  colSpan={3}
                    >
                      Comprobante de domicilio
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      **90 días
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      Copia
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      <CheckOutlined />
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      <CheckOutlined />
                    </td>
                  </tr>

                  <tr style={{  height: 30,
                      color: '#041534', }}>
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderLeftWidth: 1,
                        borderLeftStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        fontWeight:"bold"
                      }}
                    >
                      4
                    </td>
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        
                  }}
                  colSpan={3}
                    >
                      Visita Ocular
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      90 días
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      Digital
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      <CheckOutlined />
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    ></td>
                  </tr>

                  <tr
                    style={{
                      backgroundColor: '#acb4c4',
                  
                  height: 30,
                  color: '#041534',
                    }}
                  >
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderLeftWidth: 1,
                        borderLeftStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        fontWeight:"bold"
                      }}
                    >
                      5
                    </td>
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        
                  }}
                  colSpan={3}
                    >
                      Reporte de Buró de Crédito
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      30 días
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      Copia
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      <CheckOutlined />
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      <CheckOutlined />
                    </td>
                  </tr>

                  <tr style={{  height: 30,
                      color: '#041534', }}>
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderLeftWidth: 1,
                        borderLeftStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        fontWeight:"bold"
                      }}
                    >
                      6
                    </td>
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        
                  }}
                  colSpan={3}
                    >
                      Firma en SAT WS o Declaración de Impuestos
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      90 días
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      Digital
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      <CheckOutlined />
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      S
                    </td>
                  </tr>

                  <tr
                    style={{
                      backgroundColor: '#acb4c4',
                      
                      height: 30,
                      color: '#041534',
                    }}
                  >
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderLeftWidth: 1,
                        borderLeftStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        fontWeight:"bold"
                      }}
                    >
                      7
                    </td>
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        
                  }}
                  colSpan={3}
                    >
                      Información Financiera
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      90 días
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      Original
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      <CheckOutlined />
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    ></td>
                  </tr>

                  <tr style={{  height: 30,
                      color: '#041534', }}>
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderLeftWidth: 1,
                        borderLeftStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        fontWeight:"bold"
                      }}
                    >
                      8
                    </td>
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        
                  }}
                  colSpan={3}
                    >
                      Carátula de Estado de Cuenta para dispersión
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      *90 días
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      Digital
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      <CheckOutlined />
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      S
                    </td>
                  </tr>

                  <tr
                    style={{
                      backgroundColor: '#acb4c4',
                      
                      height: 30,
                      color: '#041534',
                    }}
                  >
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderLeftWidth: 1,
                        borderLeftStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        fontWeight:"bold"
                      }}
                    >
                      9
                    </td>
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        
                  }}
                  colSpan={3}
                    >
                      Estados de Cuenta Bancarios
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      90 días
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      Original
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      <CheckOutlined />
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    ></td>
                  </tr>

                  <tr style={{  height: 30,
                      color: '#041534', }}>
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderLeftWidth: 1,
                        borderLeftStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        fontWeight:"bold"
                      }}
                    >
                      10
                    </td>
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        
                  }}
                  colSpan={3}
                    >
                      Información Corporativa
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      N/A
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      Copia
                    </td>
                    <td
                      
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >&nbsp;</td>
                <td
                  
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      <CheckOutlined />
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    ></td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      <CheckOutlined />
                    </td>
                  </tr>

                  <tr
                    style={{
                      backgroundColor: '#acb4c4',
                      
                      height: 30,
                      color: '#041534',
                    }}
                  >
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderLeftWidth: 1,
                        borderLeftStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        fontWeight:"bold"
                      }}
                    >
                      11
                    </td>
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        
                  }}
                  colSpan={3}
                    >
                      Relación Patrimonial
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      90 días
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      Original
                    </td>
                <td
                  width="50"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      <CheckOutlined />
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >&nbsp;</td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      <CheckOutlined />
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    ></td>
                  </tr>

                  <tr style={{  height: 30,
                      color: '#041534', }}>
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderLeftWidth: 1,
                        borderLeftStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        fontWeight:"bold"
                      }}
                    >
                      12
                    </td>
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        
                  }}
                  colSpan={3}
                    >
                      Ficha Tecnica de los Bienes y Cotización del Proveedor
                      &nbsp;&nbsp;
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      &nbsp;Acorde al documento &nbsp;
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      Digital
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    >
                      <CheckOutlined />
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        
                      }}
                    ></td>
                  </tr>

                  <tr
                    style={{
                      backgroundColor: '#acb4c4',
                      
                      height: 30,
                      color: '#041534',
                    }}
                  >
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderLeftWidth: 1,
                        borderLeftStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        borderRadius: '0px 0px 0px 8px',
                        fontWeight:"bold"
                      }}
                    >
                      13
                    </td>
                    <td
                      style={{
                        paddingLeft: 10,
                        borderColor:'#132a55',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderRightWidth: 1,
                        borderRightStyle: 'solid',
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                  }}
                  colSpan={3}
                    >
                      Listado de vienes a arrendar
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        borderBottom: '1px solid #132a55',
                      }}
                    >
                      N/A
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        borderBottom: '1px solid #132a55',
                      }}
                    >
                      Excel editable
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        borderBottom: '1px solid #132a55',
                      }}
                    >
                      <CheckOutlined />
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: 'center',
                        borderRight: '1px solid #132a55',
                        borderBottom: '1px solid #132a55',
                        borderRadius: '0px 0px 8px 0px'
                  }}
                    ></td>
                  </tr>
                </tbody>
          </table>
          <br></br><br></br><br></br><br></br><br></br> */}
          {/* <p
            className="div-title-conoce"
            style={{
              color: 'black',
              fontWeight: 'bold',
              width: '90%',
              fontFamily: 'Red Hat Display, sans-serif',
            }}
          >
            Preguntas frecuentes
          </p> */}

          {/* <Collapse
            accordion
            className="collapse-conoce"
            onChange={onChange}
            style={{ marginBottom: 80 }}
          >
            <Panel
              header={
                <div className="div-subtitle-conoce">
                  ¿Cual es el proceso para tramitar un Arrendamiento de equipo
                  médico?
                </div>
              }
              key="1"
            >
              <div className="div-collapse-preguntas">
                <p style={{ textAlign: 'left' }}>
                  • Permite destinar tus flujos de efectivo a inversiones que
                  están relacionadas con el giro de su empresa.
                </p>
                <p style={{ textAlign: 'left' }}>
                  • Permite contar con equipo médico nuevo y de última
                  generación.
                </p>
                <p style={{ textAlign: 'left' }}>
                  • Otorga beneficios a las empresas como ventajas fiscales,
                  contables y operativas.
                </p>
              </div>
            </Panel>
            <Panel
              header={
                <div className="div-subtitle-conoce">
                  ¿Que documentación necesito?
                </div>
              }
              key="2"
            >
              
            </Panel>
            <Panel
              header={
                <div className="div-subtitle-conoce">
                  ¿Cuáles son los requisitos?
                </div>
              }
              key="3"
            >
              <p className="div-collapse-preguntas">
                • <label style={{fontWeight:"bold"}}>Ser PFAE / MORAL:</label> Requieres ser dueño(a) de un negocio bajo el regimen de persona fisica con actividad empresarial(PFAE) o persona Moral.
              </p>
              <p className="div-collapse-preguntas">
                • <label style={{fontWeight:"bold"}}>Facturación mayor a 2mdp:</label> Su negocio debe facturar mas de 2 millones de pesos anuales, no atendemos a micronegocios.
              </p>

              <p className="div-collapse-preguntas">
                • <label style={{fontWeight:"bold"}}>Registro SAT mayor a 2 años:</label> Su negocio debe estar registrado ante el SAT con una antiguedad mayor a 2 años. (Se puede considerar historial previo en otras razones sociales).
              </p>

              <p className="div-collapse-preguntas">
                • <label style={{fontWeight:"bold"}}>2 años de operación:</label> Su negocio debe tener al menos 2 años de operación. (Se puede considerar historial previo en otras razones sociales).
              </p>

              <p className="div-collapse-preguntas">
                • <label style={{fontWeight:"bold"}}>Comprobante de ingresos:</label> Su negocio debe registrar y comprobar ingresos: estado de resultados y balance general..
              </p>
            </Panel>
          </Collapse> */}
        </center>
      </div>

      <div className="contenedor-imagen">
        <div className="fondo">{/* Tu imagen de fondo */}</div>
        <div className="overlay-imagen" style={{ overflow: 'clip' }}>
          {/* Tu contenido dentro del overlay (puedes agregar más elementos aquí) */}

          <Row style={{ width: '100%', marginTop: 20 }}>
            <Col flex="1 1 600px">
              <Row>
                <Col>
                  <img className="logo-footer" src={logo}></img>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div
                    className="div-footer-adress"
                    style={{ color: 'white', marginLeft: 20, width: '80%' }}
                  >
                    Blvd. Alfonso G. Calderón Velarde, No. 2656 int. 11
                    Desarrollo Urbano Tres Ríos, C.P. 80020, Culiacán, Sin.
                  </div>
                </Col>
              </Row>
            </Col>

            <Col flex="1 1 300px">
              <div className="div-col-enlaces">
                <Row align="middle">
                  <Col>
                    <div
                      className="div-footer-adress"
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: 18,
                        marginTop: 30,
                      }}
                    >
                      ENLACES
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      className="div-footer-adress"
                      style={{ color: 'white', marginTop: 10 }}
                    >
                      <a
                        onClick={() => {
                          window.location.href = 'https://www.finamo.mx/';
                        }}
                        className="footer-link"
                      >
                        Home
                      </a>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div
                      className="div-footer-adress"
                      style={{ color: 'white', marginTop: 10 }}
                    >
                      <a
                        onClick={() => handleNext('/nosotros')}
                        className="footer-link"
                      >
                        Nosotros
                      </a>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div
                      className="div-footer-adress"
                      style={{ color: 'white', marginTop: 10 }}
                    >
                      <a
                        onClick={() => handleNext('/terminos_condiciones')}
                        className="footer-link"
                      >
                        Términos y condiciones
                      </a>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div
                      className="div-footer-adress"
                      style={{ color: 'white', marginTop: 10 }}
                    >
                      <a
                        onClick={() => handleNext('/aviso_privacidad')}
                        className="footer-link"
                      >
                        Aviso de Privacidad
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col flex="1 1 300px">
              <div className="div-col-enlaces">
                <Row>
                  <Col>
                    <div
                      className="div-footer-adress"
                      style={{
                        color: 'white',
                        marginTop: 30,
                        fontWeight: 'bold',
                      }}
                    >
                      INFORMACIÓN DE CONTACTO
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      className="footer-link"
                      style={{ color: 'white', marginTop: 10 }}
                    >
                      <FaHouse style={{ color: '#fff' }} /> Culiacán, Sinaloa,
                      México
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div
                      className="footer-link"
                      style={{ color: 'white', marginTop: 10 }}
                    >
                      <FaEnvelope /> marketing@finamo.mx
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div
                      className="footer-link"
                      style={{ color: 'white', marginTop: 10 }}
                    >
                      <FaPhone /> Llámanos al 667 980 0438
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div
                      className="footer-link"
                      style={{ color: 'white', marginTop: 10 }}
                    >
                      <FaWhatsapp /> Whatsapp al 667 751 7699
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row style={{ width: '100%', marginTop: 30 }}>
            <Col flex="1 1 300px" offset={0} style={{ marginLeft: 20 }}>
              <FacebookOutlined
                style={LogosStyle}
                onClick={() => handleLink('https://www.facebook.com/FinamoMX/')}
              />
              {/* &nbsp;&nbsp;
              <TwitterOutlined
                style={LogosStyle}
                onClick={() => handleLink('https://twitter.com/Finamo_MX')}
              /> */}
              &nbsp;&nbsp;
              <LinkedinOutlined
                style={LogosStyle}
                onClick={() =>
                  handleLink('https://www.linkedin.com/company/finamomx/')
                }
              />
              &nbsp;&nbsp;
              <InstagramOutlined
                style={LogosStyle}
                onClick={() =>
                  handleLink(
                    'https://www.instagram.com/finamomx/?utm_medium=copy_link'
                  )
                }
              />
              &nbsp;&nbsp;
            </Col>
          </Row>

          <center>
            <div
              className="footer-link"
              style={{ marginTop: 100, overflow: 'auto' }}
            >
              © 2024 Copyright: finamo.mx
            </div>
          </center>

          {/* <center>
            <Row justify="center" style={{ width: 350, marginTop: 40 }}>
              <Col flex="1 1 100px">
                <img src={logoBuro} style={{ width: 100 }}></img>
              </Col>
              <Col flex="1 1 100px">
                <img src={logoBc} style={{ width: 100 }}></img>
              </Col>
            </Row>
          </center> */}
        </div>
      </div>

      <Modal
        title={
          <div>
            <Typography.Title
              level={3}
              style={{ margin: 0, color: '#081b3e', marginBottom: 10 }}
            >
              <FaComments style={{ color: '#4c4cbc' }} /> &nbsp;{' '}
              <label style={{ color: '#4c4cbc' }}>{strTitulo}.</label>
            </Typography.Title>
          </div>
        }
        /*
        closable={false}
        maskClosable={false}
        */
        open={isModalOpen}
        onCancel={handleModalClose}
        footer={[
          <div>
            <Button
              type="primary"
              onClick={handleModalClose}
              icon={<CloseOutlined />}
            >
              Continuar
            </Button>
          </div>,
        ]}
        className="modal-pld"
      >
        {strMensaje}
      </Modal>

      <FloatButton
        icon={<WhatsAppOutlined style={{fontSize:27, marginLeft:-5}} />}
        type="primary"
        style={{ right: 15, backgroundColor: '#25ca6d', width: 60, height: 60 }}
        onClick={handleSendMessage}
      />
    </div>
  ) : (
    <></>
  );
};

export default Welcome;
